import React, { useContext, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useParams } from "react-router";

import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
// import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";

export default function ViewClientAssesment() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [measurementAvaliable, setMeasurementAvaliable] = useState(false);

  const [heartCond, setheartCond] = useState("");
  const [cheastPain, setcheastPain] = useState("");
  const [lightHeated, setlightHeated] = useState("");
  const [injuries, setinjuries] = useState("");
  const [medicalSupervision, setmedicalSupervision] = useState("");
  const [pregnant, setpregnant] = useState("");
  const [takingMedicine, settakingMedicine] = useState("");
  const [smokingHabit, setsmokingHabit] = useState("");
  const [healthClubMember, sethealthClubMember] = useState("");
  const [havePersonalTrainer, sethavePersonalTrainer] = useState("");
  const [pulseRate, setpulseRate] = useState("");
  const [recoveryRate, setrecoveryRate] = useState("");
  const [exerciseDays, setexerciseDays] = useState("");

  const [bmi, setbmi] = useState("");
  const [fat, setfat] = useState("");
  const [fitness_goals, setfitness_goals] = useState([]);
  const [gluts, setgluts] = useState("");
  const [height, setheight] = useState("");
  const [waist, setwaist] = useState("");
  const [weight, setweight] = useState("");
  const [measurement, setMeasurement] = useState([]);

  const [clientName, setclientName] = useState("...");

  let goalsList = [];

  const getUsers = async () => {
    try {
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setclientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {}
  };
     /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers();

    const getAssessmentDetails = async () => {
      try {
        setLoading(true);
        const responce = await UserServices.getAssessmentDetailsById(id);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;

          res.map((value) => {
            setheartCond(value.heart_disease);
            setcheastPain(value.chest_pain);
            setlightHeated(value.light_headed);
            setinjuries(value.injuries);
            setmedicalSupervision(value.medical_supervision);
            setpregnant(value.pregnant);
            settakingMedicine(value.taking_medicine);
            setsmokingHabit(value.smoking_habit);
            sethealthClubMember(value.health_club_member);
            sethavePersonalTrainer(value.have_personal_trainer);
            setpulseRate(value.pulse_rate);
            setrecoveryRate(value.recovery_rate);
            setexerciseDays(value.exercise_days);
            setbmi(value.bmi);
            setfat(value.fat);
            setgluts(value.gluts);
            setwaist(value.waist);
            setweight(value.weight);
            setheight(value.height);
            goalsList = value.fitness_goals.split(",");
            return null;
          });
          
          setfitness_goals([...goalsList]);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getAssessmentDetails(id);

    const getMeasurements = async () => {
      try {
        setLoading(true);
        const responce = await UserServices.getMeasurementsById(id);
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              abs: value.abs,
              arm: value.arm,
              blood_pressure: value.blood_pressure,
              body_mass_index: value.body_mass_index,
              calf: value.calf,
              chest: value.chest,
              diastolic: value.diastolic,
              fat: value.fat,
              gluts: value.gluts,
              maximum_heart_rate: value.maximum_heart_rate,
              neck: value.neck,
              resting_heart_rate: value.resting_heart_rate,
              shoulder: value.shoulder,
              systolic: value.systolic,
              thigh: value.thigh,
              waist: value.waist,
              weight: value.weight,
              wrist: value.wrist,
              v_fat: value.v_fat,
              fitness_goals: value.fitness_goals,
            });
            return null;
          });

          setMeasurement([...results]);
          setMeasurementAvaliable(true);
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getMeasurements(id);
  }, []);

  // const initialValues = {
  //   Height: "",
  //   Weight: "",
  //   Waist: "",
  //   Glutes: "",
  //   Fat: "",
  //   BMI: "",
  // };

  //validation........................................................................................

  const List = ({ data }) => {
    return (
      <>
        {data.length ? (
          data.map((el, index) => (
            <div className="col-md-2 mb-3" key={index}>
              <div className="fitness-goals">
                <i className="bi bi-check"></i> {el}
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-2 mb-3">
            <div className="fitness-goals">N/A</div>
          </div>
        )}
      </>
    );
  };

  const Measurements = ({ data }) => {
    return (
      <>
        {data.length ? (
          <div className="card">
            {data.map((el, index) => (
              <>
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Post Assessment</h5>
                </div>
                <div className="card-body">
                  <div className="row" key={index}>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Weight :</div>
                        <span>{el.weight}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Neck :</div>
                        <span>{el.neck}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Shoulder :</div>
                        <span>{el.shoulder}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Chest :</div>
                        <span>{el.chest}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Wrist :</div>
                        <span>{el.wrist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Abs :</div>
                        <span>{el.abs}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Arm :</div>
                        <span>{el.arm}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Waist :</div>
                        <span>{el.waist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Glutes :</div>
                        <span>{el.gluts}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Thigh :</div>
                        <span>{el.thigh}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Calf :</div>
                        <span>{el.calf}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BMI :</div>
                        <span>{el.body_mass_index}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">FAT% :</div>
                        <span>{el.fat}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">RHR :</div>
                        <span>{el.resting_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">MHR :</div>
                        <span>{el.maximum_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">V-FAT :</div>
                        <span>{el.v_fat}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BP :</div>
                        <span>{el.blood_pressure}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Systolic :</div>
                        <span>{el.systolic}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Diastolic :</div>
                        <span>{el.diastolic}</span>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Client: {clientName}
                <Link
                  to={"/invoice/assessment/" + id}
                  className="ms-md-2 btn btn-primary d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-printer" /> Quick Slip
                </Link>
                {measurementAvaliable ? null : (
                  <Link
                    to={"/postAssessment/" + id}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    <i className="bi bi-rulers" /> Add Measurements
                  </Link>
                )}
                <div className="clearfix" />
              </h5>
            </div>
            <div className="card-body">
              <div className="col-12">
                <h5 className="mb-3 font-20">
                  The Results Client Like To Achieve With Your Fitness Training
                  Are
                </h5>
              </div>
              <div className="row">
                <List data={fitness_goals} />

                <hr />

                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="mb-3 mt-3 mt-lg-0">
                        Fitness Appointment Details
                      </h5>
                      <div className="row">
                        <div className="col-12 col-lg-4 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Height </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Height"
                                name="Height"
                                value={height}
                                readOnly={true}
                              />
                              <span className="input-group-text">Cm</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Weight </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Weight"
                                name="Weight"
                                value={weight}
                                readOnly={true}
                              />
                              <span className="input-group-text">Kg</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Waist </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Waist"
                                name="Waist"
                                value={waist}
                                readOnly={true}
                              />
                              <span className="input-group-text">Cm</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Glutes </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Glutes"
                                name="Glutes"
                                value={gluts}
                                readOnly={true}
                              />
                              <span className="input-group-text">Cm</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Body Fat </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Body Fat"
                                name="Fat"
                                value={fat}
                                readOnly={true}
                              />
                              <span className="input-group-text">
                                Body Fat %
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4  mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">BMI </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="BMI"
                                name="BMI"
                                value={bmi}
                                readOnly={true}
                              />
                              <span className="input-group-text">kg/m2.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="mb-3">Physical Activity Readiness</h5>
                      <table className="table table-bordered ">
                        <thead>
                          <tr>
                            <th>Statement</th>
                            <th>Yes</th>
                            <th>No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Have you ever been diagnosed with a heart
                              condition?{" "}
                            </td>
                            <td align="center">
                              {heartCond === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td align="center">
                              {heartCond === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do you have any chest pain brought on by physical
                              activity?{" "}
                            </td>
                            <td>
                              {cheastPain === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {cheastPain === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do you feel light headed or dizzy when
                              excercising?{" "}
                            </td>
                            <td>
                              {lightHeated === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {lightHeated === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do you have any bone or joint problems / injuries?{" "}
                            </td>
                            <td>
                              {injuries === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {injuries === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Are you aware of any reason against excercise
                              without medical supervision?{" "}
                            </td>
                            <td>
                              {medicalSupervision === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {medicalSupervision === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Women only: Are you pregnant or nursing?</td>
                            <td>
                              {pregnant === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {pregnant === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Are you taking any prescribed medications that
                              could effect you during excercise (Women; excluded
                              birth control)?{" "}
                            </td>
                            <td>
                              {takingMedicine === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {takingMedicine === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="mb-3 mt-3 mt-lg-0">Healthy Habbits</h5>
                      <table className="table table-bordered mb-2">
                        <thead>
                          <tr>
                            <th>Statement</th>
                            <th>Yes</th>
                            <th>No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Do you smoke or use tobacco? ? </td>
                            <td>
                              {smokingHabit === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {smokingHabit === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Are you a member of a health club?</td>
                            <td>
                              {healthClubMember === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {healthClubMember === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Do you use a personal trainer?</td>
                            <td>
                              {havePersonalTrainer === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {havePersonalTrainer === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              How many times do you excercise in a typical week?{" "}
                            </td>
                            <td>
                              {exerciseDays === "yes" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                            <td>
                              {exerciseDays === "no" ? (
                                <i className="bi bi-check inside-table"></i>
                              ) : (
                                "__"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="col-12 col-lg-12 mb-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Resting Pulse </label>
                          <div className="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              value={pulseRate}
                              readOnly={true}
                            />
                            <span className="input-group-text">
                              1.5 sec. count
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-12 mb-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Recovery Rate </label>
                          <div className="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              value={recoveryRate}
                              readOnly={true}
                            />
                            <span className="input-group-text">
                              1 minute count after
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Measurements data={measurement} />
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
