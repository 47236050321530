import * as Yup from "yup";

export const login = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
});

export const updateUser = Yup.object({
  name: Yup.string().min(2).max(25).required("First Name is required"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  email: Yup.string().email().required("Please enter your email"),
});

export const clienthome = Yup.object({
  // email: Yup.string().email(),
  name: Yup.string().min(2).max(25).required("First Name is required"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  // cnic: Yup.string()
  //   .notRequired()
  //   .when("uniques_entry_by", {
  //     is: (val) => val === "Cnic",
  //     then: Yup.string()
  //       .required("CNIC is required")
  //       .min(15, "Please enter complete CNIC number")
  //       .max(15, "Please enter complete CNIC number"),
  //     otherwise: Yup.string().notRequired(),
  //   }),

  license: Yup.string()
    .notRequired()
    .when("uniques_entry_by", {
      is: (val) => val === "Driving License Number",
      then: Yup.string()
        .required("Driving License Number is required")
        .min(10, "Please enter complete Driving License Number")
        .max(10, "Please enter complete Driving License Number"),
      otherwise: Yup.string().notRequired(),
    }),

  passport: Yup.string()
    .notRequired()
    .when("uniques_entry_by", {
      is: (val) => val === "Passport Number",
      then: Yup.string().required("Passport Number is required"),
      otherwise: Yup.string().notRequired(),
    }),
  phone: Yup.string()
    // .min(17, "Please enter complete cell number")
    // .max(17, "Please enter complete cell number")
    .required("Phone is required"),
});

export const addclient = Yup.object({
  name: Yup.string().min(2).max(25).required("First Name is required"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  select_gender: Yup.string().required("Gender is required"),

  // cnic: Yup.string()
  //   .notRequired()
  //   .when("uniques_entry_by", {
  //     is: (val) => val === "Cnic",
  //     then: Yup.string()
  //       .required("CNIC is required")
  //       .min(15, "CNIC Number should contain 15 characters")
  //       .max(15, "CNIC Number should contain 15 characters"),
  //     otherwise: Yup.string().notRequired(),
  //   }),

  license: Yup.string()
    .notRequired()
    .when("uniques_entry_by", {
      is: (val) => val === "Driving License Number",
      then: Yup.string()
        .required("Driving License Number is required")
        .min(10, "Driving License Number should contain 10 characters")
        .max(10, "Driving License Number should contain 10 characters"),
      otherwise: Yup.string().notRequired(),
    }),

  passport: Yup.string()
    .notRequired()
    .when("uniques_entry_by", {
      is: (val) => val === "Passport Number",
      then: Yup.string()
        .required("Passport Number is required")
        .min(9, "Passport Number should contain 9 characters")
        .max(9, "Passport Number should contain 9 characters"),
      otherwise: Yup.string().notRequired(),
    }),

  // cnic: Yup.string()
  //   .min(15, "Please enter complete CNIC number")
  //   .max(15, "Please enter complete CNIC number")
  //   .required("CNIC is required"),
  phone: Yup.string()
    .min(17, "Please enter complete cell number")
    .max(17, "Please enter complete cell number")
    .required("Cell Number is required"),
  email: Yup.string().email(),
  // select_category: Yup.string().required("Please select category"),
  // branch_id: Yup.string().required("Please select the branch"),
  // country: Yup.string().required("Please select the country"),
  // city: Yup.string().required("City is required"),
  // address: Yup.string().required("Address is required"),
  // birthday: Yup.string().required("Date is required"),
  // card: Yup.string().required("Card is required"),
});

export const addstaff = Yup.object({
  name: Yup.string().min(2).max(25).required("First Name is required"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  select_gender: Yup.string().required("Gender is required"),
  branch_id: Yup.number().required("Branch Name is required"),
  date: Yup.string().required("Date is required"),
  salary: Yup.string().required("Salary is required"),
  cnic: Yup.string()
    .min(15, "Please enter complete CNIC number")
    .max(15, "Please enter complete CNIC number")
    .required("CNIC is required"),
  phone: Yup.string()
    .min(17, "Please enter complete cell number")
    .max(17, "Please enter complete cell number")
    .required("Cell Number is required"),
  email: Yup.string().email(),
  commission: Yup.number()
    .min(0, "PTs Commission should be greater than 0")
    .max(100, "PTs Commission should be less than 100"),
  // select_type:Yup.string().required("Type is required"),
  // select_roll:Yup.string().required("Roll is required"),
  // card: Yup.string().required("Card number is required"),
  select_department: Yup.number().required("Department is required"),
  // address: Yup.string().required("Address is required"),
  // city: Yup.string().required("City is required"),
  designation: Yup.number().required("Designation is required"),
});
export const staffhome = Yup.object({
  email: Yup.string().email(),
  name: Yup.string().min(2).max(25).required("First Name is required"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  designation: Yup.number().required("Designation is required"),
  select_department: Yup.number().required("Department is required"),
  joining: Yup.string().required("Joining date is required"),

  staffSalary: Yup.number().min(0).required("Salary is required"),
  cnic: Yup.string()
    .min(15, "Please enter complete CNIC number")
    .max(15, "Please enter complete CNIC number")
    .required("CNIC is required"),
  phone: Yup.string()
    // .min(17, "Please enter complete cell number")
    // .max(17, "Please enter complete cell number")
    .required("Phone is required"),
  commission: Yup.number().min(0).max(100),
});

export const staffFines = Yup.object({
  Occurrence_date: Yup.string().required("Fine date is required"),
  user_id: Yup.string().required("Name is required"),
  reason: Yup.string().required("Reason is required"),
  amount: Yup.string().min(2).max(10).required("Amount is required"),
});
export const viewFines = Yup.object({
  startdate: Yup.string().required("Date is required"),
  enddate: Yup.string().required("Date is required"),
});
export const viewSettings = Yup.object({
  date: Yup.string().required("Date is required"),
});
export const staffAdvances = Yup.object({
  occurrence_date: Yup.string().required("Date is required"),
  return_month: Yup.string().required("Month is required"),
  user_id: Yup.string().required("Name is required"),
  reason: Yup.string().required("Reason is required"),
  transaction_type: Yup.string().required("Required"),
  payment_type_id: Yup.string().required("Required"),
  amount: Yup.string().min(2).max(10).required("Amount is required"),
});
export const staffLoan = Yup.object({
  staff_id: Yup.string().required("Name is required"),
  amount: Yup.string()
    .min(2, "Amount must be at least 2 characters")
    .max(7, "Amount must not be more than 7 characters")
    .required("Amount is required"),
  term: Yup.string()
    .max(10, "Term must not be more than 10 characters")
    .required("Term is required"),
  date: Yup.string().required("Date is required"),
  reason: Yup.string().required("Reason is required"),
  return_start_date: Yup.string().required("Return Start Date is required"),
});
export const clientReport = Yup.object({
  sdate: Yup.string().required("Month is required"),
  edate: Yup.string().required("Year is required"),
});
export const recievePymnts = Yup.object({
  date: Yup.string().required("Date is required"),
  amount: Yup.string().required("Amount is required"),
  payment_type_id: Yup.string().required("Payment Method is required"),
});
export const AmountSettings = Yup.object({
  branch_id: Yup.string().required("Branch is required"),
  fee: Yup.number()
    .min(0)
    .max(100, "Percentage must be less than or equal to 100")
    .required("Percentage is required"),
  type: Yup.string().required("Transaction Surcharges are required"),
});

export const salaryFilter = Yup.object({
  sdate: Yup.string().required("Start date is required"),
  edate: Yup.string()
    .min(4, "Invalid Year")
    .max(4, "Invalid Year")
    .required("End date  is required"),
});

export const addPackage = Yup.object({
  branch_id: Yup.number().required("Branch ID is required"),
  price: Yup.string().required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  duration: Yup.string().required("Duration is required"),
  membership_id: Yup.string().required("Membership is required"),
});
export const clientSearch = Yup.object({
  bySearch: Yup.string().required("Required"),
  searchVal: Yup.string().required("Required"),
});
export const searchPackage = Yup.object({
  bySearch: Yup.string().required("Required"),
  srchValue: Yup.string().required("Required"),
});

export const gymPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
  gymPkgType: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),
  sdate: Yup.string().required("Required"),
  discount_amount: Yup.string().required("Required"),
});
export const physiooPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),
  sdate: Yup.string().required("Required"),
  discount_amount: Yup.string().required("Required"),
});

export const registrationPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),

  discount_amount: Yup.string().required("Required"),
});
export const depositPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
});
export const genralPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),

  discount_amount: Yup.string().required("Required"),
});
export const sessionPkgSell = Yup.object({
  memType: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),

  discount_amount: Yup.string().required("Required"),
  sdate: Yup.string().required("Required"),
});

export const getPayment = Yup.object({
  payment_rec: Yup.number().required("Required"),

  cheque_no: Yup.string()
    .notRequired()
    .when("pay_met", {
      is: (val) => val === "40",
      then: Yup.string()
        .required("Required")
        .max(15, "Cheque Number should not exceed 15 characters"),
      otherwise: Yup.string().notRequired(),
    }),
});

export const updateDates = Yup.object({
  sDate: Yup.string().required("Required"),
  eDate: Yup.string().required("Required"),
});
export const balanceSheet = Yup.object({
  sdate: Yup.string().required("Required"),
  edate: Yup.string().required("Required"),
});

export const checkOut = Yup.object({
  payment_rec: Yup.number().required("Required"),

  cheque_no: Yup.string()
    .notRequired()
    .when("pay_met", {
      is: (val) => val === "40",
      then: Yup.string()
        .required("Required")
        .max(15, "Cheque Number should not exceed 15 characters"),
      otherwise: Yup.string().notRequired(),
    }),
  split_cheque_no: Yup.string()
    .notRequired()
    .when("split_pay", {
      is: (val) => val === "40",
      then: Yup.string()
        .required("Required")
        .max(15, "Cheque Number should not exceed 15 characters"),
      otherwise: Yup.string().notRequired(),
    }),
});

export const guestPkgSell = Yup.object({
  Validilty: Yup.number().min(0).required("Required"),
  discount_type: Yup.string().required("Required"),

  sdate: Yup.string().required("Required"),
  total_fee: Yup.number().min(0).required("Required"),
  discount_amount: Yup.string().required("Required"),
});

export const groupPkgSell = Yup.object({
  discount_type: Yup.string().required("Required"),
  ptPackages: Yup.string().required("Required"),

  Group: Yup.string().required("Required"),
  time_slot: Yup.string().required("Required"),
  sdate: Yup.string().required("Required"),
  total_fee: Yup.number().min(0).required("Required"),
  discount_amount: Yup.string().required("Required"),
});

export const NutritionistPkgSell = Yup.object({
  discount_type: Yup.string().required("Required"),
  discount_amount: Yup.string().required("Required"),
  Nutritionist: Yup.string().required("Required"),
  NutritionistPkgType: Yup.string().required("Required"),
  pkgType: Yup.string().required("Required"),

  sdate: Yup.string().required("Required"),
});

export const trainerPkgSell = Yup.object({
  trainer: Yup.string().required("Required"),
  pkgType: Yup.string().required("Required"),
  trainerPkgType: Yup.string().required("Required"),

  discount_type: Yup.string().required("Required"),

  sdate: Yup.string().required("Required"),
  discount_amount: Yup.string().required("Required"),
});
export const cafePkgSell = Yup.object({
  qunatity: Yup.number().min(1).required("Required"),
  price: Yup.number().min(1).required("Required"),
  cafe_category_id: Yup.string().required("Required"),

  cafeProductType: Yup.string().required("Required"),
});

export const addBranch = Yup.object({
  code: Yup.string().min(2).max(5).required("Branch code is required"),
  name: Yup.string().min(2).max(25).required("Name is required"),
});
export const manageExpens = Yup.object({
  occurrence_date: Yup.string().required("Date is required"),
  branch_id: Yup.string().required("Branch is required"),
  category_id: Yup.string().required("Category is required"),
  sub_category_id: Yup.string().required("Sub Category is required"),
  transaction_type: Yup.string().required("Transaction is required"),
  amount: Yup.string().required("Amount is required"),
  payment_type_id: Yup.string().required("Payment Method is required"),
});

export const addNutritionPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Nutritionist is required"),
  membership_id: Yup.string().required("Nutrition Type is required"),
  package_name: Yup.string().required("Package Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
  session_count: Yup.string().required("Required"),
});
export const addTrainerPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Trainer is required"),
  membership_id: Yup.string().required("Traning Type is required"),
  package_name: Yup.string().required("Package Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
  session_count: Yup.string().required("Session count is required"),
});
export const updateTrainerPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Trainer is required"),
  membership_id: Yup.string().required("Traning Type is required"),
  package_name: Yup.string().required("Package Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
});
export const addPtPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Trainer is required"),
  pt_group: Yup.string().required("PT Group is required"),
  package_name: Yup.string().required("Package Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
  session_count: Yup.string().required("Required"),
});
export const addMembershipPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  // nameError: Yup.string().required("Name already exists"),
});
export const addModules = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
});
export const addPhysioPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  session_count: Yup.string().required("Required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
});
export const assignTimeSlot = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Trainer is required"),
  // time_slot_id: Yup.string().required("Time Slot is required"),
});

export const timeSlot = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  end_date: Yup.string().min(1).max(10).required("End Time is required"),
  start_date: Yup.string().required("Start Time is required"),
  // nameError: Yup.string().required("Name already exists"),
});

export const updateDate = Yup.object({
  date: Yup.string().required("Date is required"),
});
export const updateType = Yup.object({
  payment_type: Yup.string().required("Date is required"),
});
export const addCFTpkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  duration: Yup.string().required("Duration is required"),
});
export const addOfficeCashFlow = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  amount: Yup.string().min(1).max(10).required("Amount is required"),
  resource: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
});
export const addKeeneCash = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  amount: Yup.string().min(1).max(10).required("Amount is required"),
  type: Yup.string().required("Type is required"),
});
export const addPettyCashFlow = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  amount: Yup.string().min(1).max(10).required("Amount is required"),
  transaction_type: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
});
export const addLiabilities = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  category_id: Yup.string().required("Category is required"),
  sub_category_id: Yup.string().required("Sub Category is required"),
  // creditor_name: Yup.string().required("Name is required"),
  amount_owned: Yup.string().required("Amount is required"),
});
export const payLiabilities = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
  resource: Yup.string().required("Resource is required"),
});
export const payAllLiabilities = Yup.object({
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
  resource: Yup.string().required("Resource is required"),
});
export const payLoans = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
});
export const addAssets = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
  purchase_cost: Yup.string().required("Purchase cost is required"),
  current_value: Yup.string().required("Required"),
  acquisition_date: Yup.string().required("Date is required"),
  // quantity:Yup.string().required("Quantity is required"),
  // total_cost:Yup.string().required("Total Cost is required"),
});
export const addCafePkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Product Name is required"),
  cafe_category_id: Yup.string().required("Required"),
});
export const categoriesAdd = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
});
export const CafeCategoryAdd = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
});
export const subCategoriesAdd = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
  category_id: Yup.string().required("Category is required"),
});
export const BankAccountDetails = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
  account_no: Yup.string().required("Account No is required"),
  account_title: Yup.string().required("Account Title is required"),
});
export const addGenralPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  // nameError: Yup.string().required("Name already exists"),
});
export const addSessionPkg = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  price: Yup.string().min(1).max(10).required("Price is required"),
  package_name: Yup.string().required("Package Name is required"),
  duration: Yup.string()
    .min(1)
    .max(4, "Duration must not be more than 4 characters")
    .required("Duration is required"),
});
export const viewCrads = Yup.object({
  bySearch: Yup.string().required("Required"),
  searchVal: Yup.string().required("Required"),
});
export const viewCrd = Yup.object({
  bySearch: Yup.string().required("Required"),
  searchVal: Yup.string().required("Required"),
});
export const addCrads = Yup.object({
  number: Yup.string().min(1).max(10).required("Card Number is required"),
  description: Yup.string().required("Required"),
});
export const freezingFee = Yup.object({
  // client_id: Yup.string().required("Name is required"),
  price: Yup.string()
    .min(0)
    .max(8, "Fee must not be more than 8 characters")
    .required("Freezing Fee is required"),
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
  duration: Yup.string().required("Duration is required"),
  discount_type: Yup.string().required("Required"),
  discount_amount: Yup.string().required("Required"),
});
export const managerResource = Yup.object({
  type: Yup.string().required("Category is required"),
  name: Yup.string().required("Name is required"),
});
export const manageTowels = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  name: Yup.string().required("Name is required"),
  quantity: Yup.string()
    .min(0)
    .max(4, "Quantity must not be more than 4 characters")
    .required("Quantity is required"),
  date: Yup.string().required("Date is required"),
});
export const manageSessionAttendance = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  trainer_id: Yup.string().required("Trainer is required"),
  order_id: Yup.string().required("Package is required"),
});
export const updtRol = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required("Role is required"),
  role_status: Yup.string().required("Role status is required"),
});
export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  password: Yup.string().min(6).required("Please enter your password"),
  Clientid: Yup.string().min(2).max(6).required("Please enter client Id"),
  last_name: Yup.string().min(2).max(25).required("Last Name is required"),
  select_gender: Yup.string().required("Please select the gender"),
  select_type: Yup.string().required("Please select the type"),
  cnic: Yup.string().required("CNIC is required"),
  phone: Yup.string().min(10).max(25).required("Phone is required"),
  select_category: Yup.string().required("Please select the category"),
  select_branch: Yup.string().required("Please select the branch"),
  country: Yup.string().required("Please select the Country"),
  city: Yup.string().required("Please select the City"),
  postal_code: Yup.string().required("Please select the postal code"),
  email: Yup.string().email().required("Please enter your email"),
  address: Yup.string().required("Address is required"),
  birthday: Yup.string().required("Birthday is required"),
  client_card: Yup.string().required("Client card is required"),
  date: Yup.string().required("Date is required"),
  report: Yup.string().required("Please fill the box"),
  designation: Yup.string().required("Designation is required"),
  department: Yup.string().required("Department is required"),
  card_number: Yup.string().required("Card number is required"),
  salary: Yup.string().required("Salary is required"),
  amount: Yup.string().required("Amount is required"),
  reason: Yup.string().required("Reason is required"),
  package_name: Yup.string().required("Package name is required"),
  price: Yup.string().required("Price is required"),
  duration: Yup.string().required("Duration is required"),
  pass_name: Yup.string().required("Pass name is required"),
  validity: Yup.string().required("Validity is required"),
  contact_no: Yup.string().required("Contact No is required"),
  branch_city: Yup.string().required("Branch city is required"),
  membership_name: Yup.string().required("Membership name is required"),
  fee: Yup.string().required("Fee is required"),
  description: Yup.string().required("Description is required"),
  enter_categories: Yup.string().required("Please enter the catagorie"),
  enter_name: Yup.string().required("Please enter the name"),
  session_name: Yup.string().required("Session name is required"),
  session_duration: Yup.string().required("Session duration is required"),
  session_price: Yup.string().required("Session price is required"),
  message: Yup.string().required("Message is required"),
  freezing_fee: Yup.string().required("Freezing fee is required"),
  days: Yup.string().required("Please enter number of days"),
  select_service: Yup.string().required("Please select the service"),
  month: Yup.string().required("Please select the month"),
  expense_name: Yup.string().required("Expense Name is required"),
  select_package: Yup.string().required("Please select the package"),
  product_name: Yup.string().required("Please select the Product Name"),
});

export const clientPreAssesment = Yup.object({
  Height: Yup.number().moreThan(0).required("Required"),
  Weight: Yup.number().moreThan(0).required("Required"),
  Waist: Yup.number().moreThan(0).required("Required"),
  Glutes: Yup.number().moreThan(0).required("Required"),
  Fat: Yup.number().moreThan(0).required("Required"),
  BMI: Yup.number().moreThan(0).required("Required"),
  Recovery: Yup.number().moreThan(0).required("Required"),
  Resting: Yup.number().moreThan(0).required("Required"),
});

export const postAssesment = Yup.object({
  Weight: Yup.number().moreThan(0).required("Required"),
  Calf: Yup.number().moreThan(0).required("Required"),
  Neck: Yup.number().moreThan(0).required("Required"),
  BMI: Yup.number().moreThan(0).required("Required"),
  Shoulder: Yup.number().moreThan(0).required("Required"),
  Fat: Yup.number().moreThan(0).required("Required"),
  Chest: Yup.number().moreThan(0).required("Required"),
  Rhr: Yup.number().moreThan(0).required("Required"),
  Wrist: Yup.number().moreThan(0).required("Required"),
  Mhr: Yup.number().moreThan(0).required("Required"),
  Abs: Yup.number().moreThan(0).required("Required"),
  Vfat: Yup.number().moreThan(0).required("Required"),
  Arm: Yup.number().moreThan(0).required("Required"),
  Bp: Yup.number().moreThan(0).required("Required"),
  Waist: Yup.number().moreThan(0).required("Required"),
  Systolic: Yup.number().moreThan(0).required("Required"),
  Glutes: Yup.number().moreThan(0).required("Required"),
  Diastolic: Yup.number().moreThan(0).required("Required"),
  Thigh: Yup.number().moreThan(0).required("Required"),
});
export const addMealPlan = Yup.object({
  meal_category_id: Yup.string().required("Required"),
  client_name: Yup.string().required("Required"),
  month: Yup.string().required("Required"),
  day: Yup.string().required("Required"),
  week: Yup.string().required("Required"),
  // week: Yup.number()
  //   .min(1, "invalid week")
  //   .max(53, "invalid week")
  //   .required("Required"),
  // day: Yup.number()
  //   .min(1, "invalid day")
  //   .max(7, "invalid day")
  //   .required("Required"),
  time: Yup.string().required("Required"),
  specification: Yup.string().required("Required"),
  branch_id: Yup.string().required("Required"),
});

export const nutrinistAssessment = Yup.object({
  client_name: Yup.string().required("Required"),
  daily_water_intake: Yup.string().required("Required"),
  disliked_foods: Yup.string().required("Required"),
  favorite_foods: Yup.string().required("Required"),
  diabetes: Yup.string().required("Required"),
  hypertension_cvd: Yup.string().required("Required"),
  polycystic_ovarian_syndrome: Yup.string().required("Required"),
  anemia: Yup.string().required("Required"),
  ibs: Yup.string().required("Required"),
  h_pylori: Yup.string().required("Required"),
  muscle_pain: Yup.string().required("Required"),
  take_stress: Yup.string().required("Required"),
  activity_level: Yup.string().required("Required"),
  allergic_foods: Yup.string().required("Required"),
});

export const manageSessionAttendanceForTrainer = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  trainer_id: Yup.string().required("Trainer is required"),
  order_id: Yup.string().required("Package is required"),
  trainer_status: Yup.string().required("Trainer Attendance is required"),
  client_status: Yup.string().required("Client Attendance is required"),
  trainer_schedule_id: Yup.string().required("Time is required"),
});

export const assignTimeSlotToClient = Yup.object({
  branch_id: Yup.string().required("Branch Name is required"),
  user_id: Yup.string().required("Client is required"),
  time_id: Yup.string().required("Time is required"),
  trainer_id: Yup.string().required("Trainer is required"),
  order_id: Yup.string().required("Package is required"),
  start: Yup.string().required("Start date is required"),
  end: Yup.string().required("End date is required"),
  // time_slot_id: Yup.string().required("Time Slot is required"),
});
