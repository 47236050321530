import React from "react";
import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";

export default function Login() {
  const { dispatch: ctxDispatch } = useContext(NoteState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const Login = async (values) => {
    try {
      setLoading(true);
      const response = await UserServices.Login(values);
      if (response.status === 200) {
        const branchId = response.data.user.branch_id || "";
        ctxDispatch({
          type: "LOGIN",
          accessToken: response.data.access_token,
          branchId: branchId,
          UId: response.data.user.id,
          role: response.data.user.role,
        });
        setLoading(false);
        if (response.data.user.role === "7") {
          navigate("/cafe-dashboard");
        } else navigate("/dashboard");
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 401) {
        toast.error("Wrong username or password!", { position: "top-center" });
      } else if (err.response.status === 422) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  //validation........................................................................................
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: login,
      onSubmit: (values) => {
        Login(values);
      },
    });
  return (
    <div className="login-page">
      <div className="pen-title">
        <img src="assets/images/logo-blue.png" alt="" />
        <br />
        <span>DASHBOARD LOGIN</span>
      </div>
      <div className="container">
        <div className="card" />
        <div className="card">
          <h1 className="title">Login</h1>
          <form onSubmit={handleSubmit} noValidate>
            <div className="input-container">
              <input
                placeholder=""
                type="email"
                id="#{email}"
                name="email"
                autoComplete="off"
                value={values.email || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="#{email}">Email</label>
              <div className="bar" />
              {errors.email && touched.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}
            </div>
            <div className="input-container">
              <input
                type="password"
                placeholder=""
                id="#{password}"
                name="password"
                autoComplete="off"
                value={values.password || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="#{password}">Password</label>
              <div className="bar" />
              {errors.password && touched.password ? (
                <p className="text-danger">{errors.password}</p>
              ) : null}
            </div>
            <div className="button-container">
              <button type="submit">
                <span>Login</span>
              </button>
            </div>
            <ToastContainer />
            <div className="footer">
              {/* <Link to="/">Forgot your password?</Link> */}
            </div>
          </form>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
