import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import MultiExpenseAdd from "./MultiExpenseAdd";
import Swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import { NoteState } from "../../context/notes/NoteState";

const ManageExpenses = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [expense, setExpens] = useState([
    { id: "lib0" },
    { id: "lib1" },
    { id: "lib2" },
    { id: "lib3" },
    { id: "lib4" },
    { id: "lib5" },
    { id: "lib6" },
    { id: "lib7" },
    { id: "lib8" },
    { id: "lib9" },
    { id: "lib10" },
    { id: "lib11" },
    { id: "lib12" },
    { id: "lib13" },
    { id: "lib14" },
  ]);
  const [expenseError, setExpenseError] = useState([]);
  const [expCount, setExpCount] = useState(15);
  const [catgries, setCatgries] = useState([]);
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);

  const addExpnc = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addExpenses(values);
      const updatedRecords = await expense.filter(
        (record) => record.id != values.id
      );

      if (updatedRecords.length) {
        await setExpens(updatedRecords);
      } else {
        action.resetForm();
        await setExpens([]);
        await setExpens([{ id: "lib0" }]);
      }

      setExpenseError([]);
      setLoading(false);
      toast.success("Added Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const handleSubmit = (values, action) => {
    addExpnc(values, action);
  };

  const add = async () => {
    setExpens([
      ...expense,
      {
        id: "lib" + expCount,
      },
    ]);
    setExpCount(expCount + 1);
  };

  const remove = async (id) => {
    const updatedRecords = await expense.filter((record) => record.id != id);

    await setExpens(updatedRecords);
  };
  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...expense];

    updatedFormDataw[formDataIndex][fieldName] = val;

    setExpens(updatedFormDataw);
  };

  const handleSubmitAllForms = async () => {
    function formatDate(dateString) {
      return moment(dateString).format("YYYY-MM-DD");
    }
    const filteredArray = expense
      .map((obj, index) => ({ ...obj, originalIndex: index }))
      .filter(
        (obj) =>
          obj.category_id ||
          obj.sub_category_id ||
          obj.transaction_type ||
          obj.amount ||
          obj.bank_account_id ||
          obj.description ||
          obj.cheque_number ||
          obj.payment_type_id
      );
    const formattedArray = filteredArray.map((obj) => ({
      ...obj,
      occurrence_date: formatDate(obj.occurrence_date),
    }));
    if (formattedArray.length) {
      try {
        setLoading(true);

        const responce = await UserServices.expenseAddMultiple(formattedArray);

        if (responce.status === 201) {
          setLoading(false);

          // setExpenseError([]);

          // setExpens([{}]);
          // setExpCount(1);
          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);

        if (err?.response?.status === 422) {
          let res = err?.response?.data?.errors;

          setExpenseError(res);
        } else if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Fill atleast one row!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };
  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Expense"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select option", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Option Found", id: "" }]);
    }
  };

  const getPaymentMethodinitial = async () => {
    try {
      const responce = await UserServices.paymentNames();
      const res = responce.data.data;
      const results = res.filter((value) => value.id !== 81 && value.id !== 82); // Filter out payment method with id 81

      setPaymentMethodsSplit([...results]);
    } catch (err) {
      console.log(err);

      setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
    }
  };
  useEffect(() => {
    categories();

    getPaymentMethodinitial(branch_id);
  }, []);

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  Expense Manager
                  <Link
                    to="/view-expenses"
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    View Expense
                  </Link>
                </h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  Mark the checkbox to add record in liability
                </p>
                {expense.length
                  ? expense.map((epenses, index) => (
                      <MultiExpenseAdd
                        onSubmit={handleSubmit}
                        formDataIndex={index}
                        key={epenses.id}
                        idUnit={epenses.id}
                        removeForm={remove}
                        onFormChange={handleFormChange}
                        catgries={catgries}
                        paymentMethodsSplit={paymentMethodsSplit}
                        expenseError={expenseError[index]}
                      />
                    ))
                  : null}
                <div className="row">
                  {/* <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-libility-primary w-100 reverse-bg mt-0 mb-3 mt-3"
                  onClick={add}
                >
                  Add New Expense
                </button>
                </div> */}
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary px-4 reverse-bg mt-0 mb-3 mt-3"
                      onClick={handleSubmitAllForms}
                    >
                      Submit All Expense
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ManageExpenses;
