/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";
import Swal from "sweetalert2";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CafeDashboard = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [names, setNames] = useState([]);
  const [managementNames, setManagementNames] = useState([]);
  const [clientName, setClientName] = useState([]);

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myCart, setMyCart] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [calculatedTax, setCalculatedTax] = useState(0);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [clientId, setClientId] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");

  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [staffTotal, setStaffTotal] = useState("");
  const [staffDis, setStaffDis] = useState("");
  const [staffPrice, setStaffPrice] = useState("");
  const [staffTax, setStaffTax] = useState("");
  const [staffTaxPer, setStaffTaxPer] = useState("");

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const handleDisButtonClick = (buttonId) => {
    setActiveButton((prevActiveButton) =>
      prevActiveButton === buttonId ? null : buttonId
    );
  };
  // eslint-disable-next-line no-unused-vars
  var clickEvent = $.Event("click");
  let navigate = useNavigate();

  const handleButtonClick = (paymentMethodId) => {
    setActivePaymentMethod(paymentMethodId);
    getTax(paymentMethodId);

    if (activeButton === "staff" || activeButton === "management") {
      getTaxForStaff(paymentMethodId);
    }
  };
  const getPkg = async () => {
    setLoading(true);
    await setMyData([]);
    $(".item").removeClass("actived");
    $(".all-item").addClass("actived");

    try {
      const responce = await UserServices.getActiveCafeProducts(branch_id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            name: values.name,
            id: values.id,
            branch_id: values.branch_id,
            branches_name: values.branches_name,
            package_name: values.package_name,
            price: values.price,
            image: values.image,
            cafe_category_name: values.cafe_category_name,
          });

          return null;
        });
        setMyData([...results]);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const CafeCtgry = async () => {
    try {
      const responce = await UserServices.CafeCategory(branch_id);
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
          available_products: value.available_products,
        });
        return null;
      });
      setCategories([...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getPaymentMethodinitial = async () => {
    try {
      const responce = await UserServices.paymentNames();
      const res = responce.data.data;
      const results = [];
      // eslint-disable-next-line array-callback-return
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
      });
      setPaymentMethods([...results]);
    } catch (err) {
      console.log(err);

      setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
    }
  };
  const getCart = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getCafeCart(branch_id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        await res.map((value) => {
          results.push({
            id: value.id,
            package_name: value.package_name,
            price: value.price,
            package_price: value.package_price,
            image: value.image,
            quantity: value.quantity,
          });
          return null;
        });

        await setMyCart([...results]);

        setTimeout(() => {
          if ($("#31").hasClass("active")) {
            $("#31").trigger(clickEvent);
          } else if ($("#33").hasClass("active")) {
            $("#33").trigger(clickEvent);
          } else if ($("#81").hasClass("active")) {
            $("#81-copy").trigger(clickEvent);
          } else if ($("#35").hasClass("active")) {
            $("#35").trigger(clickEvent);
          }
        }, 500);

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyCart([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getClient = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getCafeClient(branch_id, "1");
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          setClientId(values.id);
          return null;
        });

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const managementStaffNames = async () => {
    try {
      const responce = await UserServices.staffNameforCafe(branch_id, "yes");
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setManagementNames([{ name: "Select Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const staffNames = async () => {
    try {
      const responce = await UserServices.staffNameforCafe(branch_id, "no");
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const depositClientNames = async () => {
    try {
      const responce = await UserServices.getCafeClientDeposit(branch_id);
      const res = responce.data.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          client_id: values.client_id,
          client_name: values.client_name,
          available_balance: values.available_balance,
        });
        return null;
      });
      setClientName([
        { client_name: "Select Name", client_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg();
    CafeCtgry();
    getCart();
    getClient();
    managementStaffNames();
    staffNames();
    depositClientNames();
    if (!branch_id) {
      Swal.fire({
        title: "Branch has null?",
        text: "Hit logout and login again to enter in branch!",
        icon: "error",
        confirmButtonColor: "#ed1c24",
        confirmButtonText: "Login again",
      });
    } else {
      getPaymentMethodinitial(branch_id);
    }
    const slider = document.querySelector(".fitness-items");
    let isDown = false;
    let startX;
    let scrollLeft;
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }, []);
  const getCafeItemsOnCatagry = async (catagry_id) => {
    setLoading(true);
    $(".all-item").removeClass("actived");

    await setMyData([]);

    try {
      let responce;
      if ($("#" + catagry_id).hasClass("actived")) {
        $(".item").removeClass("actived");
        $(".all-item").addClass("actived");

        responce = await UserServices.getActiveCafeProducts(branch_id);
      } else {
        $(".item").removeClass("actived");
        $("#" + catagry_id).addClass("actived");
        responce = await UserServices.getActiveCafeByCatagryId(
          branch_id,
          catagry_id
        );
      }
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_id: values.branch_id,
            branches_name: values.branches_name,
            package_name: values.package_name,
            price: values.price,
            image: values.image,
            cafe_category_name: values.cafe_category_name,
          });
          return null;
        });

        setMyData([...results]);

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getTax = async (paymentMethodId) => {
    const subtotal = await myCart.reduce((a, v) => (a = a + v.price), 0);
    try {
      const responce = await UserServices.getCafeTax(
        branch_id,
        subtotal,
        paymentMethodId
      );
      if (responce.status === 200) {
        setCalculatedTax(responce.data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
      } else {
      }
    }
  };
  const getTaxForStaff = async (paymentMethodId) => {
    const subtotal = await myCart.reduce((a, v) => (a = a + v.price), 0);
    try {
      const responce = await UserServices.getStaffCafeTax(
        branch_id,
        subtotal,
        paymentMethodId
      );
      if (responce.status === 200) {
        setStaffTotal(responce.data.total);
        setStaffDis(responce.data.staff_discount_amount);
        setStaffPrice(responce.data.amount_receive);
        setStaffTax(responce.data.tax_amount);
        setStaffTaxPer(responce.data.tax_percentage);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
      } else {
      }
    }
  };
  const sellCafePkg = async (id, price) => {
    setLoading(true);
    const values = {
      cafeProductType: id,
      price: price,
      salesdate: dateSelected,
      quantity: 1,
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const responce = await UserServices.cafePkgSell(
        branch_id,
        clientId,
        "10",
        values
      );

      await getCart();
      handleMenu();

      setLoading(false);
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };
  const handleMenu = () => {
    $(".site-main-design").addClass("full-show");
    if ($(window).width() < 992) {
      $("#sidebar").addClass("show");
    } else {
      $(".site-main-design").addClass("site-main-design2");
      $("#cartbar").addClass("show");
      $("#sidebar").removeClass("show");
    }
  };
  const closeCart = () => {
    $(".site-main-design").removeClass("site-main-design2");

    $("#cartbar").removeClass("show");
  };
  const deleteRecord = async (record_id) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const responce = await UserServices.deleteClientCartItem(record_id);

      toast.success("Item removed successfully.", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getCart();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const handleQuantityChange = async (itemId, value, price) => {
    if (value < 1) {
      value = 1; // Set minimum quantity to 1
      deleteRecord(itemId);
    } else {
      // await setMyCart((prevCartProducts) =>
      //   prevCartProducts.map((product) =>
      //     product.id === itemId ? { ...product, quantity: value } : product
      //   )
      // );

      try {
        const responce = await UserServices.updateCafeCart(
          itemId,
          value,
          price
        );

        if (responce.status === 204) {
          getCart();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };
  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    const [name, id] = selectedValue.split(",");
    setSelectedName(selectedValue);
    setSelectedId(id);
    const modalCloseButton = document.querySelector(
      "#staticBackdrop [data-bs-dismiss='modal']"
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
    const modalCloseButton3 = document.querySelector(
      "#staticBackdropNew [data-bs-dismiss='modal']"
    );
    if (modalCloseButton3) {
      modalCloseButton3.click();
    }
  };
  const sellAllPkgs = async (buyerId) => {
    setLoading(true);

    // Check if activePaymentMethod is empty
    if (!activePaymentMethod) {
      toast.error("Please select a payment method!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }
    try {
      const responce = await UserServices.cafeCheckOut(
        branch_id,
        buyerId,
        total,
        "0",
        total + calculatedTax,
        activePaymentMethod,
        "new",
        calculatedTax,
        selectedName,
        selectedId
      );
      if (responce.status === 201) {
        navigate("/cafe-invoice/purchases/" + responce.data.order_id, {
          replace: true,
        });
      }
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };
  const sellPkgToManagement = async (buyerId) => {
    setLoading(true);
    // Check if activePaymentMethod is empty
    if (!activePaymentMethod) {
      toast.error("Please select a payment method!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }
    // Check if selectedId is not provided
    if (!selectedId) {
      toast.error("Please select management!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }

    try {
      const responce = await UserServices.cafeManagemnetCheckOut(
        branch_id,
        buyerId,
        staffPrice,
        staffDis,
        staffTotal,
        activePaymentMethod,
        "new",
        staffTax,
        selectedName,
        selectedId
      );
      if (responce.status === 201) {
        navigate("/cafe-slip/purchases/" + responce.data.order_id, {
          replace: true,
        });
      }
    } catch (err) {
      toast.error("Unprocessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };

  const sellPkgToStaff = async (buyerId) => {
    setLoading(true);
    // Check if activePaymentMethod is empty
    if (!activePaymentMethod) {
      toast.error("Please select a payment method!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }
    if (!selectedName) {
      toast.error("Please select staff!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }
    try {
      const responce = await UserServices.cafeStaffCheckOut(
        branch_id,
        buyerId,
        staffPrice,
        staffDis,
        staffTotal,
        activePaymentMethod,
        "new",
        staffTax,
        selectedName
      );
      if (responce.status === 201) {
        navigate("/cafe-slip/purchases/" + responce.data.order_id, {
          replace: true,
        });
      }
    } catch (err) {
      toast.error("Unproccessable content!", { position: "top-center" });
      console.log(err);
      setLoading(false);
    }
  };
  const handleClientBalance = (e) => {
    if (e.target.value === "") {
      setSelectedClient("");
    } else {
      const selectedClientId = e.target.value;
      const balance = clientName.filter(
        // eslint-disable-next-line
        (val) => val.client_id == e.target.value
      );
      if (balance.length) {
        var ourTotal = total + calculatedTax;
        if (ourTotal <= balance[0]?.available_balance) {
          setSelectedClient(selectedClientId);
        } else {
          setSelectedClient("");
          Swal.fire({
            icon: "error",
            title: `Insufficient Balance`,
            text: ` Available Balance: ${balance[0]?.available_balance}`,
          });
        }
      }
    }
    const modalCloseButton = document.querySelector(
      "#secondModal [data-bs-dismiss='modal']"
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };
  const creditBalance = async () => {
    const totalAmount = total + calculatedTax;
    if (selectedClient === "") {
      if (activePaymentMethod !== 81) {
        sellAllPkgs(clientId);
      } else {
        Swal.fire({
          icon: "error",
          title: `Error!`,
          text: `Please select a client`,
        });
      }
    } else {
      const balance = clientName.filter(
        // eslint-disable-next-line
        (val) => val.client_id == selectedClient
      );
      if (balance.length) {
        if (totalAmount <= balance[0]?.available_balance) {
          setLoading(true);
          try {
            const response = await UserServices.clientBalanceCredit(
              selectedClient,
              totalAmount
            );

            if (response.status === 201) {
              sellAllPkgs(selectedClient);
            }
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: `Insufficient Balance`,
            text: ` Available Balance: ${balance[0]?.available_balance}`,
          });
        }
      }
    }
  };
  const UpdateRecord = async () => {
    setLoading(true);
    try {
      const response = await UserServices.CafeRecordUpdate(
        branch_id,
        selectedClient || clientId
      );
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Unprocessable Content!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  let total = 0;
  return (
    <>
      <div className="wrapper">
        <CafeNav />
        <CafeSidebar />
        <div className="row">
          <main className="col-md-4 ">
            <div id="cartbar" className="collapse-horizontal border-end ">
              <div
                id="cartbar-nav"
                className="cartbar-wrapper list-group border-0 rounded-0 text-sm-start min-vh-100"
              >
                <i
                  className="bi bi-x-square-fill close-cart"
                  onClick={closeCart}
                ></i>
                <div className="cafe-cart-heading mb-3">Cafe Cart</div>
                {myCart.length ? (
                  <>
                    {myCart.map((el, index) => (
                      <div key={index}>
                        <div>
                          <div className="orderlist mt-2">
                            <div className="d-flex">
                              <div className="product-box mt-2">
                                <img src={el.image} alt="" />
                              </div>
                              <div className="cafe-prdct-pric">
                                <div className="d-flex cart-title mt-2">
                                  <p className="product-title ms-2">
                                    {el.package_name}
                                  </p>
                                  <span className="ms-auto product-price">
                                    <CurrencyFormat
                                      value={el.package_price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandSpacing={"3"}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      renderText={(value) => <>{value}</>}
                                    />
                                  </span>
                                  <div className="position-relative">
                                    <button
                                      className="remove-item-cafe"
                                      onClick={() => {
                                        deleteRecord(el.id);
                                      }}
                                    >
                                      <i className="bi bi-x"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="qty-number ms-2">
                                    <span
                                      className="minus"
                                      onClick={() =>
                                        handleQuantityChange(
                                          el.id,
                                          el.quantity - 1,
                                          el.package_price
                                        )
                                      }
                                    >
                                      -
                                    </span>
                                    <input
                                      type="number"
                                      value={el.quantity}
                                      readOnly={true}
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          el.id,
                                          parseInt(e.target.value),
                                          el.package_price
                                        )
                                      }
                                      className="quantity"
                                    />
                                    <span
                                      className="plus"
                                      onClick={() =>
                                        handleQuantityChange(
                                          el.id,
                                          el.quantity + 1,
                                          el.package_price
                                        )
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                  <div className="ms-auto text-danger qty-price">
                                    <span className="d-none">
                                      {
                                        (total =
                                          total +
                                          el.package_price * el.quantity)
                                      }
                                    </span>
                                    <CurrencyFormat
                                      value={el.package_price * el.quantity}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandSpacing={"3"}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      renderText={(value) => <>{value}</>}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div colSpan="3" align="center">
                    No product added in cart
                  </div>
                )}
                {myCart.length &&
                activeButton !== "management" &&
                activeButton !== "staff" ? (
                  <>
                    <div className="mt-2">
                      <div className=" cart-total">
                        <div className=" d-flex justify-content-between">
                          Subtotal
                          <span className="">
                            <CurrencyFormat
                              value={total}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-between">
                          <span>
                            GST
                            <span className="ms-2">
                              <CurrencyFormat
                                value={Math.floor(
                                  (calculatedTax / total) * 100
                                )}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                suffix={"%"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </span>
                          </span>
                          <span className="">
                            <CurrencyFormat
                              value={calculatedTax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-between fw-bold text-black">
                          Total
                          <span className="">
                            <CurrencyFormat
                              value={total + calculatedTax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {myCart.length &&
                (activeButton === "management" || activeButton === "staff") ? (
                  <>
                    <div className="mt-2">
                      <div className=" cart-total">
                        <div className=" d-flex justify-content-between">
                          Subtotal
                          <span className="">
                            <CurrencyFormat
                              value={staffPrice}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-between">
                           Discount
                          <span className="">
                            <CurrencyFormat
                              value={staffDis}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-between">
                          <span>
                            GST
                            <span className="ms-2">
                              <CurrencyFormat
                                value={staffTaxPer}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                suffix={"%"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </span>
                          </span>
                          <span className="">
                            <CurrencyFormat
                              value={staffTax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <hr />
                        <div className=" d-flex justify-content-between fw-bold text-black">
                          Total
                          <span className="">
                            <CurrencyFormat
                              value={staffTotal}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {myCart.length ? (
                  <>
                    <>
                      <div className="mt-2 fw-bold text-black">Discount</div>
                      <div className="mt-2 cafe-btn-group" role="group">
                        <button
                          className={`cafe-btn ms-1 ${
                            activeButton === "staff" ? "active" : ""
                          }`}
                          type="button"
                          data-bs-toggle={
                            activeButton === "staff" ? null : "modal"
                          }
                          data-bs-target={
                            activeButton === "staff"
                              ? null
                              : "#staticBackdropNew"
                          }
                          onClick={() => {
                            setActivePaymentMethod("");

                            if (activeButton === "staff") {
                              setSelectedId("");
                              setSelectedName("");
                              setStaffTotal("");
                              setStaffDis("");
                              setStaffPrice("");
                              setStaffTax("");
                              setStaffTaxPer("");
                            }
                            handleDisButtonClick("staff");
                          }}
                        >
                          Staff
                        </button>
                        <button
                          className={`cafe-btn ms-1 ${
                            activeButton === "management" ? "active" : ""
                          }`}
                          type="button"
                          data-bs-toggle={
                            activeButton === "management" ? null : "modal"
                          }
                          data-bs-target={
                            activeButton === "management"
                              ? null
                              : "#staticBackdrop"
                          }
                          onClick={() => {
                            setActivePaymentMethod("");
                            if (activeButton === "management") {
                              setSelectedId("");
                              setSelectedName("");
                              setStaffTotal("");
                              setStaffDis("");
                              setStaffPrice("");
                              setStaffTax("");
                              setStaffTaxPer("");
                            }
                            handleDisButtonClick("management");
                          }}
                        >
                          Management
                        </button>
                      </div>
                    </>
                  </>
                ) : null}
                {myCart.length ? (
                  <>
                    {paymentMethods.length ? (
                      <>
                        <div className="mt-2 fw-bold text-black">
                          Payment Method
                        </div>
                        <div className="mt-2 cafe-btn-group" role="group">
                          {paymentMethods.map((el, index) => {
                            const isActive = el.id === activePaymentMethod;
                            let isDisabled = false;

                            if (activeButton === "management") {
                              isDisabled =
                                el.id === 81 ||
                                el.id === 35 ||
                                el.id === 33 ||
                                el.id === 31;
                            } else if (activeButton === "staff") {
                              isDisabled = el.id === 81;
                            }

                            if (el.id !== 32 && el.id !== 83) {
                              if (
                                el.id === 82 &&
                                activeButton !== "management"
                              ) {
                                return null; // Hide payment method with id 82 when not in management mode
                              }
                              return isDisabled ? null : (
                                <button
                                  key={index}
                                  id={el.id}
                                  type="button"
                                  className={`cafe-btn ms-1 ${
                                    isActive ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    if (!isDisabled) {
                                      handleButtonClick(el.id);
                                      UpdateRecord();
                                    }
                                  }}
                                  data-bs-toggle={
                                    el.id === 81 ? "modal" : undefined
                                  }
                                  data-bs-target={
                                    el.id === 81 ? "#secondModal" : undefined
                                  }
                                  disabled={isDisabled}
                                >
                                  {el.name}
                                </button>
                              );
                            }
                            return null;
                          })}
                          <button
                            id="81-copy"
                            type="button"
                            className="d-none"
                            onClick={() => {
                              handleButtonClick(81);
                              UpdateRecord();
                            }}
                          ></button>
                        </div>
                      </>
                    ) : (
                      <button type="button" className="cafe-btn">
                        Payment Method Not Found
                      </button>
                    )}

                    <div className="cafe-pay-button mt-3">
                      <button
                        type="submit"
                        onClick={() => {
                          if (activeButton === "management") {
                            sellPkgToManagement(clientId);
                          } else if (activeButton === "staff") {
                            sellPkgToStaff(clientId);
                          } else {
                            creditBalance();
                          }
                        }}
                      >
                        Pay Bill
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </main>
        </div>
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design ">
            <CafeMenu />
            <div className="cafe-card">
              <div className="cafe-card-body">
                <h5 className="ms-3">Choose Category</h5>
                <div className="grid-container">
                  <div className="grid-item fitness-main">
                    <div className="fitness-items">
                      {categories.length ? (
                        <>
                          <div
                            className="item all-item actived"
                            onClick={getPkg}
                          >
                            <img src="/assets/images/all.png" alt="All"></img>
                            <span className="text-black cafe-slider-text mt-2">
                              All
                            </span>
                          </div>
                        </>
                      ) : null}
                      {categories.length ? (
                        <>
                          {categories.map((el, index) => (
                            <div
                              className="item"
                              onClick={() => {
                                getCafeItemsOnCatagry(el.id);
                              }}
                              key={index}
                              id={el.id}
                            >
                              <img
                                src={
                                  el.name === "Beverages"
                                    ? "/assets/images/cocktail.png"
                                    : el.name === "Breakfast"
                                    ? "/assets/images/Breakfast.png"
                                    : el.name === "Parfaits"
                                    ? "/assets/images/parfait.png"
                                    : el.name === "Shakes"
                                    ? "/assets/images/juices.png"
                                    : el.name === "Salads"
                                    ? "/assets/images/salad.png"
                                    : el.name === "Burgers"
                                    ? "/assets/images/burger.png"
                                    : el.name === "Smoothies"
                                    ? "/assets/images/smoothie.png"
                                    : el.name === "Steaks"
                                    ? "/assets/images/steak.png"
                                    : el.name === "Air Fryer"
                                    ? "/assets/images/fried.png"
                                    : el.name === "Extra"
                                    ? "/assets/images/cutlery.png"
                                    : "/assets/images/not-found.png"
                                }
                                className="catagry-icon"
                                alt="icon"
                              />
                              <span className="text-black cafe-slider-text mt-2">
                                {el.name}
                              </span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="item">
                          <img
                            src="/assets/images/not-found.png"
                            className="catagry-icon"
                            alt="icon"
                          />
                          <span className="text-black cafe-slider-text mt-2">
                            No Category
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {myData.length ? (
                  <>
                    {categories.map((cl) => (
                      <>
                        {$(".all-item").hasClass("actived") && (
                          <h5 className="d-block w-100 ms-3 mt-4">{cl.name}</h5>
                        )}
                        {myData.map((el, index) => (
                          <>
                            {el.cafe_category_name === cl.name ? (
                              <>
                                <div
                                  className="cafe-product"
                                  key={index}
                                  onClick={() => {
                                    sellCafePkg(el.id, el.price);
                                  }}
                                >
                                  <img
                                    src={
                                      el.image || "/assets/images/default.jpg"
                                    }
                                    alt="product..."
                                    className="img-fluid"
                                  />
                                  <div className="productcontent">
                                    <div className="text-secondary cafe-product-name ms-2">
                                      {el.package_name}
                                    </div>
                                    <div className="ms-2 cafe-product-price">
                                      <CurrencyFormat
                                        value={el.price}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                        <div className="clearfix"></div>
                      </>
                    ))}
                  </>
                ) : (
                  <SkeletonTheme height="50px">
                    <Skeleton count={1} />
                  </SkeletonTheme>
                )}
              </div>
            </div>
          </div>
        </main>
        {/* Modal 1*/}

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Select Management
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Management <span className="text-danger">*</span>
                    </label>
                    {managementNames ? (
                      <select
                        id="managementSelect"
                        className="form-select"
                        name="user_id"
                        value={selectedName}
                        onChange={handleNameChange}
                      >
                        {managementNames.map((el, index) => {
                          return (
                            <option key={index} value={`${el.name},${el.id}`}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal 2*/}

        <div
          className="modal fade"
          id="secondModal"
          tabIndex={-1}
          aria-labelledby="secondModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="secondModalLabel">
                  Select Client
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    UpdateRecord();
                  }}
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12 ">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Client <span className="text-danger">*</span>
                    </label>
                    {clientName ? (
                      <select
                        id="clientSelect"
                        className="form-select"
                        name="client_id"
                        value={selectedClient}
                        onChange={handleClientBalance}
                      >
                        {clientName.map((el, index) => {
                          return (
                            <option key={index} value={el.client_id}>
                              {el.client_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    UpdateRecord();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal 3*/}

        <div
          className="modal fade"
          id="staticBackdropNew"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Select Staff
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12 ">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Staff <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        id="staffSelect"
                        className="form-select"
                        name="user_id"
                        value={selectedName}
                        onChange={handleNameChange}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={`${el.name},${el.id}`}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
};

export default CafeDashboard;
