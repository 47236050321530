import React, { useEffect, useState, useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { manageTowels } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import moment from "moment";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import $ from "jquery";

const ManageTowels = () => {
  const navigate = useNavigate();
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(defaultValue);
  // update
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [recordId, setRecordId] = useState("");

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const getTowel = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getTowelsInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((values) => {
          setSelectedBranch(values.branch_id);
          setSelectedName(values.name);
          setSelectedSize(values.size);
          setSelectedQuantity(values.quantity);
          setSelectedDate(values.date);
          setRecordId(values.id);
          return null;
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getTowels = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getTowls(branch_id, newLimit, pageNo);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branches_name: values.branches_name,
            name: values.name,
            quantity: values.quantity,
            size: values.size,
            date: values.date,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
      if (err.response.status === 403) {
        navigate("/unauthorized");
      }
    }
  };
  useEffect(() => {
    branches();
    getTowels(currentPage, limit);
  }, []);
  const deleteRecord = async (towel_Id) => {
    try {
      const responce = await UserServices.deleteTowels(towel_Id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getTowels(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delTowels = (towel_Id) => {
    deleteRecord(towel_Id);
  };
  const initialValues = {
    branch_id: branch_id,
    name: "",
    size: "N/A",
    quantity: "",
    date: startDate,
  };

  const addTowels = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addTowls(values);

      if (response.status === 201) {
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getTowels(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: manageTowels,
      onSubmit: (values, action) => {
        addTowels(values, action);
      },
    });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("towels.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Towels Receiving Receipt"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("quantity")}
                className={getClassNamesFor("quantity")}
              >
                Quantity
              </th>
              <th scope="col" className="no_sort">
                Size
              </th>

              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branches_name}</td>
                  <td>{el.name}</td>
                  <td>{el.quantity}</td>
                  <td>{el.size}</td>
                  <td>{moment(el.date).format("MM-DD-YYYY")}</td>
                  <td>
                    <button
                      onClick={() => {
                        getTowel(el.id);
                      }}
                      className="text-warning border-0 bg-transparent"
                    >
                      <i className="bi bi-pencil-fill" />
                    </button>
                    <button
                      type="button"
                      onClick={() => delTowels(el.id)}
                      className="text-danger custom-button-image"
                    >
                      <i className="bi bi-trash-fill" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getTowels(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const updateTowels = async (updateValues) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateTowels(updateValues, recordId);
      console.log(response);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getTowels(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: selectedBranch,
    name: selectedName,
    size: selectedSize,
    quantity: selectedQuantity,
    date: selectedDate,
  };
  //....................................................................................................................................
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: manageTowels,
    onSubmit: (updateValues) => {
      updateTowels(updateValues);
    },
  });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-body">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Manage Daily Towels</h5>
              </div>
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3 trainee-add mt-3"
                onSubmit={handleSubmit}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={values.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="text-danger">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Size</label>
                    <select
                      className="form-select"
                      name="size"
                      value={values.size || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="N/A">N/A</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                    </select>
                    {errors.size && touched.size ? (
                      <p className="text-danger">{errors.size}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Quantity<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Quantity"
                      name="quantity"
                      value={values.quantity || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.quantity && touched.quantity ? (
                      <p className="text-danger">{errors.quantity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Start date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.date && touched.date ? (
                      <p className="text-danger">{errors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Towels Receiving Receipt</h5>
            </div>
            <div className="card-body">
              <div className="row mt-2 p-2">
                <div className="col-md-1 col-lg-1 ms-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getTowels("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
                <div className=" col-md-2 ms-auto text-end">
                  <button
                    className="btn btn-primary mx-2 file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Towels
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="row g-3 trainee-add mt-3"
                onSubmit={updateHandleSubmit}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={updateValues.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      value={updateValues.name}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.name && updateTouched.name ? (
                      <p className="text-danger">{updateErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Size</label>
                    <select
                      className="form-select"
                      name="size"
                      value={updateValues.size || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      <option value="N/A">N/A</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                    </select>
                    {updateErrors.size && updateTouched.size ? (
                      <p className="text-danger">{updateErrors.size}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Quantity<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Quantity"
                      name="quantity"
                      value={updateValues.quantity || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.quantity && updateTouched.quantity ? (
                      <p className="text-danger">{updateErrors.quantity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Start date"
                      name="date"
                      value={updateValues.date}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.date && updateTouched.date ? (
                      <p className="text-danger">{updateErrors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ManageTowels;
