import React, { useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import Swal from "sweetalert2/dist/sweetalert2.js";

const InactiveResource = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [resTime, setResTime] = useState("");

  const getBrnch = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getInactiveResources();
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            branches_name: values.branches_name,
            type: values.type,
            description: values.description,
          });
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getBrnch();
  }, []);
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getResourcesiltration(
          "0",
          limit,
          pageNo,
          resTime
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              branches_name: values.branches_name,
              type: values.type,
              description: values.description,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getResourcesiltration(
          "0",
          newLimit,
          1,
          resTime
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              branches_name: values.branches_name,
              type: values.type,
              description: values.description,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const makeActive = async (resource_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.activeResource(resource_Id);

      if (responce.status === 204) {
        setLoading(false);

        Swal.fire({
          title: "Success!",
          text: "Activated successfully.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeResource = (resource_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to active this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, active it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeActive(resource_Id);
      }
    });
  };
  const deleteRecord = async (resource_Id) => {
    try {
      const responce = await UserServices.deleteRescource(resource_Id);
      if (responce.status === 204) {
        Swal.fire({
          title: "Deleted !",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getBrnch();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delResource = (resource_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(resource_Id);
      }
    });
  };
  const changeResourceTime = (e) => {
    let resTime = e;
    setResTime(resTime);
    const getResourceTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getResourceTime(
           "0",
          limit,
          1,
          resTime
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              branches_name: values.branches_name,
              type: values.type,
              description: values.description,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResourceTypeData();
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                className="no_sort"
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>

              <th scope="col" 
              onClick={() => requestSort("type")}
              className={getClassNamesFor("type")}>
                Category
              </th>

              <th
                scope="col"
                className="no_sort"
              >
                Description
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branches_name}</td>
                  <td>{el.name}</td>
                  {/* <td>{el.type}</td> */}
                  <td>{el.type.replace(/([A-Z])/g, ' $1')}</td>
                  <td>{el.description}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => activeResource(el.id)}
                      className="renew-pkg custom-inactive-button ms-2 bg-success text-white"
                    >
                      Active
                    </button>
                    <button
                      type="button"
                      onClick={() => delResource(el.id)}
                      className="renew-pkg custom-delete-button bg-danger text-white ms-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <div className="wrapper">
        <div className="card-body">
          <div className="accordion accordionClient" id="accordionClient">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Inactive Resources:
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse hide"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionClient"
              >
                <div className="row">
                  <div className="col-md-3 mt-3 ms-3">
                    <select
                      className="form-select"
                      onChange={(e) => changeResourceTime(e.target.value)}
                    >
                      <option>Filter Category</option>
                      <option value="">All</option>
                      <option value="Department">Department</option>
                      <option value="Designations">Designations</option>
                      <option value="Expense">Expense</option>
                      <option value="PaymentMethod">Payment Method</option>
                      <option value="TrainerCategory">Trainer Category</option>
                      <option value="MembershipType">Membership Type</option>
                    </select>
                  </div>
                  {totalResults > limit ? (
                    <div className="col-md-4 col-lg-4 mt-3 ms-auto me-3">
                      <div className="input-group">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => changeLimit(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => window.location.reload(false)}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default InactiveResource;
