import React, { useEffect, useState, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { manageSessionAttendanceForTrainer } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";

const UpdateTrainerAttendance = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [name, setName] = useState([]);
  const [myPackage, setMyPackage] = useState([]);
  const [myTimes, setMyTimes] = useState([]);

  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const { id } = useParams();
  const [attendanceError, setAttendanceError] = useState("");

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const trainersNames = async (br_Id) => {
    try {
      const responce = await UserServices.getTrainerNutritionistName(br_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };
  const getAttendance = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getSessionAttendanceInfo(
        branch_id,
        id
      );

      if (responce.status === 200) {
        let data = responce.data.data.data;

        packageName(responce?.data?.data?.data[0]?.trainer_id);
        completeAttendance(
          responce?.data?.data?.data[0]?.order_id,
          responce?.data?.data?.data[0]?.trainer_id
        );

        await data.map((values) => {
          updateSetValues({
            branch_id: values.branch_id,
            trainer_id: values.trainer_id,
            date: values.date,
            order_id: values.order_id,
            trainer_schedule_id: values.trainer_schedule_id,
            trainer_status: values.staff_status,
            client_status: values.client_status,
            clientName: values.order?.client_name,
            clientId: values.client_id,
          });

          return null;
        });

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const packageName = async (e) => {
    var trainer_Id = e;
    try {
      const responce = await UserServices.getOrderName(trainer_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          order_id: value.order_id,
          package_name: value.package_name + " for " + value.client_name,
        });
        return null;
      });
      setMyPackage([
        { package_name: "Select Package", order_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
      setMyPackage([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    if (branch_id) {
      trainersNames(branch_id);
    } else {
    }

    getAttendance(id);
  }, []);

  const updateAttndnc = async (updateValues) => {
    setLoading(true);
    if (attendanceError === "") {
      try {
        const response = await UserServices.UpdateAttendance(updateValues, id);
        if (response.status === 204) {
          setLoading(false);
          Swal.fire({
            title: "Updated !",
            text: "Record updated successfully.",
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonColor: "#ed1c24",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        }
      } catch (err) {
        if (err?.response?.status === 400) {
          toast.error(err?.response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };

  const attendanceVerify = async (updateValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.checkAttendance(id, updateValues);
      if (response.status === 200) {
        updateAttndnc(updateValues, action);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 409) {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Already Exists.",
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        });
      }
    }
  };
  const completeAttendance = async (e, trainer_id) => {
    updateValues.trainer_schedule_id = "";

    var order_Id = e;
    const filteredData = await myPackage.filter(
      (item) => item.order_id === parseInt(e)
    );

    setFieldValue("clientEmail", filteredData[0]?.client_email);
    setFieldValue("clientName", filteredData[0]?.client_name);
    setFieldValue("clientId", filteredData[0]?.client_id);

    try {
      const response = await UserServices.checkCompleteAttendanceForTrainer(
        order_Id
      );
      if (response.status === 200) {
        setAttendanceError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Attendance is complete",
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        });
        setAttendanceError("Attendance is complete");
      }
    }
    try {
      const response = await UserServices.checkScheduleForTrainerPkg(
        order_Id,
        trainer_id
      );

      if (response.status === 200) {
        const res = response.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            trainer_schedules_id: value.trainer_schedules_id,
            time: value.start_time + " To " + value.end_time,
          });
          return null;
        });
        setMyTimes([
          { time: "Select Time", trainer_schedules_id: "" },
          ...results,
        ]);
      }
    } catch (err) {
      setMyTimes([]);
      setLoading(false);
    }
  };

  const updateinitialValues = {
    branch_id: "",
    trainer_id: "",
    date: "",
    order_id: "",
    trainer_status: "",
    client_status: "",
    clientName: "",
    clientId: "",
    trainer_schedule_id: "",

    clientEmail: "",
  };

  //....................................................................................................................................
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    setValues: updateSetValues,
    setFieldValue,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: manageSessionAttendanceForTrainer,
    onSubmit: (updateValues) => {
      attendanceVerify(updateValues);
    },
  });
  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Pt Attendance</h5>
            </div>
            <div className="card-body">
              <form
                className="row g-3 trainee-add"
                onSubmit={updateHandleSubmit}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={updateValues.branch_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                          trainersNames(e.target.value);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Trainer</label>
                    <span className="text-danger">*</span>
                    {name ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={updateValues.trainer_id}
                        onChange={(e) => {
                          updateHandleChange(e);
                          packageName(e.target.value);
                        }}
                        onBlur={updateHandleBlur}
                        disabled={role === "9" && UId ? true : false}
                      >
                        <option value="-1">Select Name</option>
                        {name.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.trainer_id && updateTouched.trainer_id ? (
                      <p className="text-danger">{updateErrors.trainer_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Package<span className="text-danger">*</span>
                    </label>
                    {myPackage.length ? (
                      <select
                        className="form-select"
                        name="order_id"
                        value={updateValues.order_id}
                        onChange={(e) => {
                          updateHandleChange(e);
                          completeAttendance(
                            e.target.value,
                            updateValues.trainer_id
                          );
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myPackage.map((el, index) => {
                          return (
                            <option key={index} value={el.order_id}>
                              {el.package_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="order_id"
                        value={updateValues.order_id}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      >
                        <option value="">Select Package</option>
                      </select>
                    )}
                    {attendanceError ? (
                      <div className="errorMsg text-danger">
                        {attendanceError}
                      </div>
                    ) : null}
                    {updateErrors.order_id && updateTouched.order_id ? (
                      <p className="text-danger">{updateErrors.order_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Time<span className="text-danger">*</span>
                    </label>
                    {myTimes.length ? (
                      <select
                        className="form-select"
                        name="trainer_schedule_id"
                        value={updateValues.trainer_schedule_id}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myTimes.map((el, index) => {
                          return (
                            <option key={index} value={el.trainer_schedules_id}>
                              {el.time}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="trainer_schedule_id"
                        value={updateValues.trainer_schedule_id}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      >
                        <option value="">Select Time</option>
                      </select>
                    )}

                    {updateErrors.trainer_schedule_id &&
                    updateTouched.trainer_schedule_id ? (
                      <p className="text-danger">
                        {updateErrors.trainer_schedule_id}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Trainer Attendance <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select"
                      autoComplete="off"
                      name="trainer_status"
                      value={updateValues.trainer_status}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    >
                      <option value="">Select Trainer Attendance</option>
                      <option value="Contacted">Contacted</option>
                      <option value="Canceled">Canceled</option>
                      <option value="No Show">No Show</option>
                    </select>

                    {updateErrors.trainer_status &&
                    updateTouched.trainer_status ? (
                      <p className="text-danger">
                        {updateErrors.trainer_status}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Attendance <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-select"
                      autoComplete="off"
                      name="client_status"
                      value={updateValues.client_status}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    >
                      <option value="">Select Client Attendance</option>
                      <option value="Contacted">Contacted</option>
                      <option value="Canceled">Canceled</option>
                      <option value="No Show">No Show</option>
                    </select>

                    {updateErrors.client_status &&
                    updateTouched.client_status ? (
                      <p className="text-danger">
                        {updateErrors.client_status}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="15-02-2022"
                      name="date"
                      value={updateValues.date}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.date && updateTouched.date ? (
                      <p className="text-danger">{updateErrors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default UpdateTrainerAttendance;
