import React, { useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import LiabilitiesMultiFormGenerator from "./LiabilitiesMultiFormGenerator";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { NoteState } from "../../context/notes/NoteState";

export default function LiabilitiesManagement() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [liability, setLiability] = useState([
    { id: "lib0" },
    { id: "lib1" },
    { id: "lib2" },
    { id: "lib3" },
    { id: "lib4" },
    { id: "lib5" },
    { id: "lib6" },
    { id: "lib7" },
    { id: "lib8" },
    { id: "lib9" },
  ]);
  const [liabilityError, setLiabilityError] = useState([]);
  const [libCount, setLibCount] = useState(10);
  const [catgries, setCatgries] = useState([]);

  const addLiabilitiesManagement = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.laibilitiesAdd(values);
      if (response.status === 201) {
        const updatedRecords = await liability.filter(
          (record) => record.id != values.id
        );

        if (updatedRecords.length) {
          await setLiability(updatedRecords);
        } else {
          action.resetForm();
          await setLiability([]);
          await setLiability([{ id: "lib0" }]);
        }

        setLiabilityError([]);
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const handleSubmit = (values, action) => {
    addLiabilitiesManagement(values, action);
  };

  const add = async () => {
    setLiability([
      ...liability,
      {
        id: "lib" + libCount,
      },
    ]);
    setLibCount(libCount + 1);
  };
  const remove = async (id) => {
    const updatedRecords = await liability.filter((record) => record.id != id);

    await setLiability(updatedRecords);

    setLiabilityError([]);
  };

  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...liability];

    updatedFormDataw[formDataIndex][fieldName] = val;

    setLiability(updatedFormDataw);
  };

  const handleSubmitAllForms = async () => {
    function formatDate(dateString) {
      return moment(dateString).format("YYYY-MM-DD");
    }

    const filteredArray = liability
      .map((obj, index) => ({ ...obj, originalIndex: index }))
      .filter(
        (obj) =>
          obj.category_id ||
          obj.sub_category_id ||
          obj.creditor_name ||
          obj.creditor_contact ||
          obj.amount_owned ||
          obj.description
      );

    const formattedArray = filteredArray.map((obj) => ({
      ...obj,
      maturity_date: formatDate(obj.maturity_date),
    }));
    if (formattedArray.length) {
      try {
        setLoading(true);

        const responce = await UserServices.laibilitiesAddMultiple(
          formattedArray
        );

        if (responce.status === 201) {
          setLoading(false);

          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);

        if (err?.response?.status === 422) {
          let res = err?.response?.data?.errors;

          setLiabilityError(res);
        } else if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Fill atleast one row!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Liability"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Option", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Option Found", id: "" }]);
    }
  };

  useEffect(() => {
    categories();
  }, []);

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Liabilities Management
                <Link
                  to="/view-liabilities"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  View Liabilities
                </Link>
              </h5>
            </div>
            <div className="card-body">
              {liability.length
                ? liability.map((liabilities, index) => (
                    <LiabilitiesMultiFormGenerator
                      onSubmit={handleSubmit}
                      formDataIndex={index}
                      key={liabilities.id}
                      idUnit={liabilities.id}
                      catgries={catgries}
                      removeForm={remove}
                      onFormChange={handleFormChange}
                      liabilityError={liabilityError[index]}
                    />
                  ))
                : null}
              <div className="row">
                {/* <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-libility-primary w-100 reverse-bg mt-0 mb-3 mt-3"
                    onClick={add}
                  >
                    Add New Liability
                  </button>
                </div> */}
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-primary px-4 reverse-bg mt-0 mb-3 mt-3"
                    onClick={handleSubmitAllForms}
                  >
                    Submit All Liabilities
                  </button>
                </div>
              </div>
              {/* <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Liabilities Management
                <Link
                  to="/view-liabilities"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  View Liabilities
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    {catgries ? (
                      <select
                        className="form-select"
                        name="category_id"
                        value={values.category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          getSubCategories(e);
                          values.sub_category_id = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {catgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.category_id && touched.category_id ? (
                      <p className="text-danger">{errors.category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Sub-Category<span className="text-danger">*</span>
                    </label>
                    {subCatgries ? (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={values.sub_category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {subCatgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={values.sub_category_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Sub Category</option>
                      </select>
                    )}
                    {errors.sub_category_id && touched.sub_category_id ? (
                      <p className="text-danger">{errors.sub_category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Creditor Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="creditor_name"
                      value={values.creditor_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.creditor_name && touched.creditor_name ? (
                      <p className="text-danger">{errors.creditor_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Creditor Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="(e.g 92xxxxxxxxxx)"
                      name="creditor_contact"
                      value={values.creditor_contact}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      ref={phone_number}
                    />
                    {errors.creditor_contact && touched.creditor_contact ? (
                      <p className="text-danger">{errors.creditor_contact}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Amount<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="amount_owned"
                      min={0}
                      value={values.amount_owned}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.amount_owned && touched.amount_owned ? (
                      <p className="text-danger">{errors.amount_owned}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <p className="text-danger">{errors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Due Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="maturity_date"
                      value={values.maturity_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.maturity_date && touched.maturity_date ? (
                      <p className="text-danger">{errors.maturity_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
