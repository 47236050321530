/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport, recievePymnts } from "../schemas";
import Swal from "sweetalert2";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";
import "jspdf-autotable";
import jsPDF from "jspdf";
import MenuButton from "../MenuButton/MenuButton";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";

export default function ManagementPendings() {
  const navigate = useNavigate();
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);
  const [show, setShow] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [exportLink, setExportLink] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  const [users, setUsers] = useState("");
  const [total, setTotal] = useState("");

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
  };
  useEffect(() => {
    // Check or uncheck all checkboxes based on the "Select All" checkbox
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
    });
  }, [selectAllChecked]);
  useEffect(() => {
    const getPaymentMethodinitial = async () => {
      try {
        const responce = await UserServices.paymentNames();
        const res = responce.data.data;
        const results = res.filter(
          (value) => value.id !== 81 && value.id !== 82
        ); // Filter out payment method with id 81
        setPaymentMethodsSplit([
          { name: "Select Payment Method", id: "" },
          ...results,
        ]);
      } catch (err) {
        console.log(err);

        setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
      }
    };

    getPaymentMethodinitial(branch_id);
  }, [])

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setMyData([]);
        setExportLink(
          process.env.REACT_APP_API_Link +
          "/orders/export-transaction?branch_id=" +
          branch_id +
          "&start_date=" +
          values.sdate +
          "&end_date=" +
          values.edate +
          "&category=10"
        );
        const responce = await UserServices.staffPendings(branch_id, values);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              branch_name: values.branch_name,
              staff_name: values.staff_name,
              price: values.price,
              discount: values.discount,
              tax: values.tax,
              net_price: values.net_price,
              user_id: values.user_id,
              related_orders: values.related_orders,
            });
            return null;
          });
          setMyData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setMyData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    searchVal: "",
  };

  //   try {
  //     setLoading(true);
  //     const response = await UserServices.getCafeBalance(
  //       selectedRecords,
  //       startDate,
  //       endDate
  //     );

  //     if (response.status === 200) {
  //       setLoading(false);
  //       setUsers(response.user_ids);
  //       console.log(response.user_ids);
  //       // toast.success("Added Successfully!", {
  //       //   position: "top-right",
  //       //   autoClose: 3000,
  //       //   hideProgressBar: true,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       //   theme: "colored",
  //       // });
  //       // navigate("/receive-pendings")
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     if (err.response.status === 422) {
  //       toast.error("Unproccessable content!", { position: "top-center" });
  //     }
  //   }
  // };
  const payPending = async (selectedRecords, startDate, endDate) => {
    setLoading(true);
    try {
      const response = await UserServices.getCafeBalance(selectedRecords,
        startDate,
        endDate);
      if (response && response.status === 200) {



        setUsers(response.data.user_ids);
        setTotal(response.data.pending_total);

        // $("#membership").trigger(clickEvent);
        setLoading(false);
        console.log(users);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save(" management_pending.pdf");
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const [limit, setLimit] = useState("25");

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">Filtered Result</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              <a
                href={exportLink}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a>
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-1">
                  <button
                    className="btn btn-primary mx-2 file-download mb-3"
                    onClick={() => {
                      var selectedRecords = $("input:checkbox:checked")
                        .filter(function () {
                          return this.value !== "all";
                        })
                        .map(function () {
                          return this.value;
                        })
                        .get()
                        .join(",");
                      if (selectedRecords.length) {
                        console.log(selectedRecords);
                        payPending(selectedRecords, startDate, endDate);
                        $("#open").trigger(clickEvent);
                      } else {
                        toast.error("Please Select Staff!", {
                          position: "top-center",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }
                    }}
                  >
                    <i className="bi bi-download"></i> Receive
                  </button>
                </div>
                <div className="col-md-1 ms-auto">
                  <select
                    className="form-select mb-3"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                    value={limit}
                  >
                    <option value="25">25</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={myData.length}>All</option>
                  </select>
                </div>
              </div>
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1  table-striped"
                  data-caption=" Management Pendings"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        <input
                          id="all"
                          name="all"
                          type="checkbox"
                          value="all"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                      </th>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("branch_name")}
                        className={getClassNamesFor("branch_name")}
                      >
                        Branch Name
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("staff_name")}
                        className={getClassNamesFor("staff_name")}
                      >
                        Staff
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("price")}
                        className={getClassNamesFor("price ")}
                      >
                        Amount
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("discount")}
                        className={getClassNamesFor("discount")}
                      >
                        Discount
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("tax")}
                        className={getClassNamesFor("tax")}
                      >
                        GST
                      </th>
                      <th scope="col" className="no_sort">
                        Receivable Amount
                      </th>
                      <th scope="col" className="no_sort">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkboxNoLabel"
                                  name={el.staff_name}
                                  value={el.user_id}
                                  aria-label="..."
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <span className="ms-2">
                                  {page === 1
                                    ? index + 1
                                    : (page - 1) * limit + index + 1}
                                </span>
                                {el.related_orders.length ? (
                                  <div
                                    className="ms-2"
                                    onClick={() => {
                                      showInstalDetails(el.user_id);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="bi bi-chevron-down fw-bold"></i>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td>{el.branch_name}</td>
                            <td>
                              <Link to={"/staff-home/" + el.user_id}>
                                {el.staff_name}
                              </Link>
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.discount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.tax}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.net_price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  payPending(el.user_id, startDate, endDate);
                                }}
                                className="updt-btn inactive-btn"
                                data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                              >
                                <i className="bi bi-download"></i>
                                <span className="text-success ms-1 updt-text">
                                  Receive
                                </span>
                              </button>
                            </td>
                          </tr>
                          {el.related_orders.length ? (
                            <tr
                              className="instalmnt-info table table-striped"
                              id={el.user_id}
                            >
                              <td colSpan="10">
                                <tr>
                                  <th
                                    style={{
                                      width: "20%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Order ID
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Price
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Discount
                                  </th>
                                  <th
                                    style={{
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                      width: "20%",
                                    }}
                                  >
                                    GST
                                  </th>
                                  <th
                                    style={{
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                      width: "20%",
                                    }}
                                  >
                                    Net Price
                                  </th>
                                  <th
                                    style={{
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                      width: "20%",
                                    }}
                                  >
                                    Date
                                  </th>
                                </tr>
                                {el.related_orders.map((inl, index) => (
                                  <tr key={index}>
                                    {/* <td style={{ width: "5%" }}></td> */}
                                    <td>{inl.id}</td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.price}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.discount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.tax}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={
                                          inl.price - inl.discount + inl.tax
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      {moment(el.date).format("DD-MM-YYYY")}
                                    </td>
                                  </tr>
                                ))}
                              </td>
                            </tr>
                          ) : null}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="14"
                          className="text-center text-capitalize"
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {data.length && data.length > limit ? (
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={data.length}
                    onChange={(e) => {
                      setPage(e);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First Page"
                    lastPageText="Last Page"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
      },
    });

  const payBalance = async (users, startDate, endDate, payValues) => {
    try {
      setLoading(true);
      const response = await UserServices.payCafeBalance(
        users,
        startDate,
        endDate, payValues
      );

      if (response.status === 204) {
        setLoading(false);
        toast.success("Payment Received Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // navigate("/receive-pendings")
        closeButtonRef.current.click();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const payinitialValues = {
    date: defaultValue,
    amount: total,
    payment_type_id: "",
    cheque_number: "",
  };

  const {
    values: payValues,
    errors: payErrors,
    touched: payTouched,
    handleBlur: payHandleBlur,
    handleChange: payHandleChange,
    handleSubmit: payHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: payinitialValues,
    validationSchema: recievePymnts,
    onSubmit: (payValues, action) => {
      payBalance(users, startDate, endDate, payValues, action);
    },
  });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Management Pending</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="col-12"></div>
                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {show ? <Table data={myData} /> : null}
        </div>
      </main>
      {/* modal 1*/}
      <div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Receive Pending Payments
                </h5>
                <button
                  type="button"
                  className="btn-close me-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeButtonRef}
                >
                  <i className="bi bi-x-lg fs-5"></i>
                </button>
              </div>

              <div className="modal-body">
                <div className="card-body">
                  <form className="row g-3" onSubmit={payHandleSubmit}>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="11-03-2022"
                          name="date"
                          value={payValues.date}
                          onChange={handleChange}
                          onBlur={payHandleBlur}
                        />
                        {payErrors.date && payTouched.date ? (
                          <p className="text-danger">{payErrors.date}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Payment Method</label>
                        <span className="text-danger">*</span>
                        {paymentMethodsSplit.length ? (
                          <select
                            className="form-select"
                            name="payment_type_id"
                            value={payValues.payment_type_id}
                            onChange={payHandleChange}
                            onBlur={payHandleBlur}
                          >
                            {paymentMethodsSplit.map((el, index) => {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  disabled={el.id === ""}
                                >
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            name="payment_type_id"
                            value={payValues.payment_type_id}
                            onChange={(e) => {
                              payHandleChange(e);
                            }}
                            onBlur={payHandleBlur}
                          >
                            <option>Select Payment Method</option>
                          </select>
                        )}
                        {payErrors.payment_type_id &&
                          payTouched.payment_type_id ? (
                          <p className="text-danger">
                            {payErrors.payment_type_id}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {payValues.payment_type_id === "32" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <div className="form-group custom-group">
                            <label htmlFor="form-label" className="form-label">
                              Cheque Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="cheque_number"
                              value={payValues.cheque_number}
                              onChange={(e) => {
                                payHandleChange(e);
                              }}
                              onBlur={payHandleBlur}
                            />

                            {payErrors.cheque_number &&
                              payTouched.cheque_number ? (
                              <p className="text-danger">
                                {payErrors.cheque_number}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Receivable Amount{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="amount"
                          value={payValues.amount}
                          onChange={payHandleChange}
                          onBlur={payHandleBlur}
                          disabled={true}
                        />
                        {payErrors.amount && payTouched.amount ? (
                          <p className="text-danger">{payErrors.amount}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 ">
                      <button
                        type="submit"
                        className="btn btn-primary px-5 rounded-0 mb-1"
                      >
                        Receive Payment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {Modal 2} */}

      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="open"
        data-bs-toggle="modal"
        data-bs-target="#newModalID"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="newModalID" // Change this to your desired new modal ID
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Receive Pending Payments
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={payHandleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="date"
                      value={payValues.date}
                      onChange={handleChange}
                      onBlur={payHandleBlur}
                    />
                    {payErrors.date && payTouched.date ? (
                      <p className="text-danger">{payErrors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Payment Method</label>
                    <span className="text-danger">*</span>
                    {paymentMethodsSplit.length ? (
                      <select
                        className="form-select"
                        name="payment_type_id"
                        value={payValues.payment_type_id}
                        onChange={payHandleChange}
                        onBlur={payHandleBlur}
                      >
                        {paymentMethodsSplit.map((el, index) => {
                          return (
                            <option
                              key={index}
                              value={el.id}
                              disabled={el.id === ""}
                            >
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="payment_type_id"
                        value={payValues.payment_type_id}
                        onChange={(e) => {
                          payHandleChange(e);
                        }}
                        onBlur={payHandleBlur}
                      >
                        <option>Select Payment Method</option>
                      </select>
                    )}
                    {payErrors.payment_type_id &&
                      payTouched.payment_type_id ? (
                      <p className="text-danger">
                        {payErrors.payment_type_id}
                      </p>
                    ) : null}
                  </div>
                </div>
                {payValues.payment_type_id === "32" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Cheque Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="cheque_number"
                          value={payValues.cheque_number}
                          onChange={(e) => {
                            payHandleChange(e);
                          }}
                          onBlur={payHandleBlur}
                        />

                        {payErrors.cheque_number &&
                          payTouched.cheque_number ? (
                          <p className="text-danger">
                            {payErrors.cheque_number}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Receivable Amount{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      value={payValues.amount}
                      onChange={payHandleChange}
                      onBlur={payHandleBlur}
                      disabled={true}
                    />
                    {payErrors.amount && payTouched.amount ? (
                      <p className="text-danger">{payErrors.amount}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Receive Payment
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
