import React, { useContext, useEffect, useState } from "react";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { manageExpens } from "../schemas";

export default function MultiExpenseAdd({
  onSubmit,
  formDataIndex,
  onFormChange,
  expenseError,
  idUnit,
  removeForm,
  catgries,
  paymentMethodsSplit,
}) {
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  const [myBranches, setMyBranches] = useState([]);
  const [bank, setBank] = useState([]);
  const [subCatgries, setSubCatgries] = useState([]);

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  // eslint-disable-next-line

  const handleCheckboxChange = (event) => {
    onFormChange(
      formDataIndex,
      event.target.checked ? "1" : "0",
      "is_liability"
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
        });
        setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };

    const bankDetails = async () => {
      try {
        const responce = await UserServices.bankList(branch_id);
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            bank_name: value.bank_name,
            account_no: value.account_no,
          });
          return null;
        });
        setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setBank([{ bank_name: "No Bank Found", id: "" }]);
      }
    };

    branches();

    bankDetails();

    onFormChange(formDataIndex, branch_id, "branch_id");
    onFormChange(formDataIndex, "0", "is_liability");
    onFormChange(formDataIndex, defaultValue, "occurrence_date");
  }, []);

  const getSubCategories = async (e) => {
    var sb_catgry = e.target.value;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([...results]);
    } catch (err) {
      setSubCatgries([{ name: "No record found", id: "" }]);
      console.log(err);
    }
  };

  const initialValues = {
    id: idUnit,
    branch_id: branch_id,
    occurrence_date: defaultValue,
    category_id: "",
    sub_category_id: "",
    transaction_type: "",
    amount: "",
    bank_account_id: "",
    payment_type_id: "",
    description: "",
    cheque_number: "",
  };
  //....................................................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: manageExpens,
      onSubmit: onSubmit,
    });

  return (
    <>
      {/* <div className="row"> */}
      <form className="row g-3" onSubmit={handleSubmit}>
        <table className="table-borderless table-striped add-expense-table">
          {formDataIndex === 0 && (
            <thead>
              <tr>
                <th scope="col" className="no_sort d-none">
                  Branch Name
                </th>
                <th scope="col" className="no_sort">
                  Category
                </th>
                <th scope="col" className="no_sort">
                  Subcategory
                </th>
                <th scope="col" className="no_sort">
                  Transaction
                </th>
                <th scope="col" className="no_sort">
                  Amount
                </th>
                <th scope="col" className="no_sort">
                  Payment Method
                </th>
                <th scope="col" className="no_sort">
                  Date
                </th>
                <th scope="col" className="no_sort">
                  Description
                </th>
                <th scope="col" className="no_sort">
                  Liability
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <td className="d-none">
                {myBranches ? (
                  <select
                    className="form-select"
                    name="branch_id"
                    disabled={branch_id ? true : false}
                    value={values.branch_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      // changeCategory(e);
                      onFormChange(formDataIndex, e.target.value, "branch_id");
                    }}
                    onBlur={handleBlur}
                  >
                    {myBranches.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.branch_id && touched.branch_id ? (
                  <p className="text-danger">{errors.branch_id}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["branch_id"] &&
                      expenseError["branch_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {catgries ? (
                  <select
                    className="form-select"
                    name="category_id"
                    value={values.category_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      getSubCategories(e);
                      values.sub_category_id = "";
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "category_id"
                      );
                      onFormChange(formDataIndex, "", "sub_category_id");
                    }}
                    onBlur={handleBlur}
                  >
                    {catgries.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.category_id && touched.category_id ? (
                  <p className="text-danger">{errors.category_id}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["category_id"] &&
                      expenseError["category_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {subCatgries ? (
                  <select
                    className="form-select"
                    name="sub_category_id"
                    value={values.sub_category_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "sub_category_id"
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="-1">Select Option</option>
                    {subCatgries.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select
                    className="form-select"
                    name="sub_category_id"
                    value={values.sub_category_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select Sub Category</option>
                  </select>
                )}
                {errors.sub_category_id && touched.sub_category_id ? (
                  <p className="text-danger">{errors.sub_category_id}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["sub_category_id"] &&
                      expenseError["sub_category_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <select
                  className="form-select"
                  name="transaction_type"
                  value={values.transaction_type || ""}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "transaction_type"
                    );
                  }}
                  onBlur={handleBlur}
                >
                  <option value="-1">Select Option</option>
                  <option value="Bank Account">Bank Account</option>
                  <option value="Sales Counter">Sales Counter</option>
                  <option value="Office Counter">Office Counter</option>
                  <option value="Personal">Personal</option>
                  <option value="Mr Waqas Personal (Account)">
                    Mr Waqas Personal (Account)
                  </option>
                  <option value="Mr Waqas Credit Card">
                    Mr Waqas Credit Card
                  </option>
                  <option value="Mr Arif">Mr Arif</option>
                  <option value="Mr Bilal">Mr Bilal</option>
                </select>
                {errors.transaction_type && touched.transaction_type ? (
                  <p className="text-danger">{errors.transaction_type}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["transaction_type"] &&
                      expenseError["transaction_type"].map((err) => err)}
                  </span>
                ) : null}
                {values.transaction_type === "Bank Account" ? (
                  <>
                    {bank ? (
                      <select
                        className="form-select mt-2"
                        name="bank_account_id"
                        value={values.bank_account_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          onFormChange(
                            formDataIndex,
                            e.target.value,
                            "bank_account_id"
                          );
                        }}
                        onBlur={handleBlur}
                      >
                        {bank.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.bank_name} {el.account_no}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.bank_account_id && touched.bank_account_id ? (
                      <p className="text-danger">{errors.bank_account_id}</p>
                    ) : expenseError ? (
                      <span className="text-danger">
                        {expenseError["bank_account_id"] &&
                          expenseError["bank_account_id"].map((err) => err)}
                      </span>
                    ) : null}
                  </>
                ) : null}
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Amount"
                  name="amount"
                  value={values.amount}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "amount");
                  }}
                  onBlur={handleBlur}
                />
                {errors.amount && touched.amount ? (
                  <p className="text-danger">{errors.amount}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["amount"] &&
                      expenseError["amount"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {paymentMethodsSplit.length ? (
                  <select
                    className="form-select"
                    name="payment_type_id"
                    value={values.payment_type_id}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "payment_type_id"
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option>Select Option</option>
                    {paymentMethodsSplit.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select
                    className="form-select"
                    name="payment_type_id"
                    value={values.payment_type_id}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    <option>Select Payment Method</option>
                  </select>
                )}
                {errors.payment_type_id && touched.payment_type_id ? (
                  <p className="text-danger">{errors.payment_type_id}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["payment_type_id"] &&
                      expenseError["payment_type_id"].map((err) => err)}
                  </span>
                ) : null}
                {values.payment_type_id === "32" ? (
                  <>
                    <input
                      className="form-control mt-2"
                      placeholder="Enter Cheque Number"
                      type="text"
                      name="cheque_number"
                      value={values.cheque_number}
                      onChange={(e) => {
                        handleChange(e);
                        onFormChange(
                          formDataIndex,
                          e.target.value,
                          "cheque_number"
                        );
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.cheque_number && touched.cheque_number ? (
                      <p className="text-danger">{errors.cheque_number}</p>
                    ) : expenseError ? (
                      <span className="text-danger">
                        {expenseError["cheque_number"] &&
                          expenseError["cheque_number"].map((err) => err)}
                      </span>
                    ) : null}
                  </>
                ) : null}
              </td>
              <td>
                <input
                  type="date"
                  className="form-control"
                  placeholder="11-03-2022"
                  name="occurrence_date"
                  value={values.occurrence_date}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "occurrence_date"
                    );
                  }}
                  onBlur={handleBlur}
                />
                {errors.occurrence_date && touched.occurrence_date ? (
                  <p className="text-danger">{errors.occurrence_date}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["occurrence_date"] &&
                      expenseError["occurrence_date"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  value={values.description}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "description");
                  }}
                  onBlur={handleBlur}
                />
                {errors.description && touched.description ? (
                  <p className="text-danger">{errors.description}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["description"] &&
                      expenseError["description"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td style={{ width: "80px" }}>
                <div className="form-check ms-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="is_liability"
                    value={values.isLiability === "1"}
                    onChange={handleCheckboxChange}
                    id="flexCheckDefault"
                    style={{ width: "17px", height: "17px" }}
                  />
                </div>
              </td>
              <td>
                {formDataIndex > 14 && (
                  <button
                    type="button"
                    onClick={() => {
                      removeForm(idUnit);
                    }}
                    className="remove-form-button"
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="custom-col-1 d-none">
            <div className="form-group custom-group">
              <label className="form-label">
                Branch Name<span className="text-danger">*</span>
              </label>
              {myBranches ? (
                <select
                  className="form-select"
                  name="branch_id"
                  disabled={branch_id ? true : false}
                  value={values.branch_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    // changeCategory(e);
                    onFormChange(formDataIndex, e.target.value, "branch_id");
                  }}
                  onBlur={handleBlur}
                >
                  {myBranches.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : null}
              {errors.branch_id && touched.branch_id ? (
                <p className="text-danger">{errors.branch_id}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["branch_id"] &&
                    expenseError["branch_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-del-col-1">
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_liability"
                value={values.isLiability === "1"}
                onChange={handleCheckboxChange}
                id="flexCheckDefault"
                style={{ width: "17px", height: "17px" }}
              />
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Category<span className="text-danger">*</span>
              </label>
              {catgries ? (
                <select
                  className="form-select"
                  name="category_id"
                  value={values.category_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    getSubCategories(e);
                    values.sub_category_id = "";
                    onFormChange(formDataIndex, e.target.value, "category_id");
                    onFormChange(formDataIndex, "", "sub_category_id");
                  }}
                  onBlur={handleBlur}
                >
                  {catgries.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : null}
              {errors.category_id && touched.category_id ? (
                <p className="text-danger">{errors.category_id}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["category_id"] &&
                    expenseError["category_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Sub-Category<span className="text-danger">*</span>
              </label>
              {subCatgries ? (
                <select
                  className="form-select"
                  name="sub_category_id"
                  value={values.sub_category_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "sub_category_id"
                    );
                  }}
                  onBlur={handleBlur}
                >
                  <option value="-1">Select Option</option>
                  {subCatgries.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className="form-select"
                  name="sub_category_id"
                  value={values.sub_category_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Sub Category</option>
                </select>
              )}
              {errors.sub_category_id && touched.sub_category_id ? (
                <p className="text-danger">{errors.sub_category_id}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["sub_category_id"] &&
                    expenseError["sub_category_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Transaction<span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                name="transaction_type"
                value={values.transaction_type || ""}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(
                    formDataIndex,
                    e.target.value,
                    "transaction_type"
                  );
                }}
                onBlur={handleBlur}
              >
                <option value="-1">Select Option</option>
                <option value="Bank Account">Bank Account</option>
                <option value="Sales Counter">Sales Counter</option>
                <option value="Office Counter">Office Counter</option>
                <option value="Personal">Personal</option>
                <option value="Mr Waqas Personal (Account)">
                  Mr Waqas Personal (Account)
                </option>
                <option value="Mr Waqas Credit Card">
                  Mr Waqas Credit Card
                </option>
                <option value="Mr Arif">Mr Arif</option>
                <option value="Mr Bilal">Mr Bilal</option>
              </select>
              {errors.transaction_type && touched.transaction_type ? (
                <p className="text-danger">{errors.transaction_type}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["transaction_type"] &&
                    expenseError["transaction_type"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* {values.transaction_type === "Bank Account" ? (
            <div className="custom-col-1">
              <div className="form-group custom-group">
                <label className="form-label">
                  Bank Details<span className="text-danger">*</span>
                </label>
                {bank ? (
                  <select
                    className="form-select"
                    name="bank_account_id"
                    value={values.bank_account_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "bank_account_id"
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    {bank.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.bank_name} {el.account_no}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.bank_account_id && touched.bank_account_id ? (
                  <p className="text-danger">{errors.bank_account_id}</p>
                ) : expenseError ? (
                  <span className="text-danger">
                    {expenseError["bank_account_id"] &&
                      expenseError["bank_account_id"].map((err) => err)}
                  </span>
                ) : null}
              </div>
            </div>
          ) : null} */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Amount <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="amount"
                value={values.amount}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "amount");
                }}
                onBlur={handleBlur}
              />
              {errors.amount && touched.amount ? (
                <p className="text-danger">{errors.amount}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["amount"] &&
                    expenseError["amount"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Payment Method</label>
              <span className="text-danger">*</span>
              {paymentMethodsSplit.length ? (
                <select
                  className="form-select"
                  name="payment_type_id"
                  value={values.payment_type_id}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "payment_type_id"
                    );
                  }}
                  onBlur={handleBlur}
                >
                  <option>Select Option</option>
                  {paymentMethodsSplit.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className="form-select"
                  name="payment_type_id"
                  value={values.payment_type_id}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  <option>Select Payment Method</option>
                </select>
              )}
              {errors.payment_type_id && touched.payment_type_id ? (
                <p className="text-danger">{errors.payment_type_id}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["payment_type_id"] &&
                    expenseError["payment_type_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* {values.payment_type_id === "32" ? (
            <div className="custom-col-1">
              <div className="form-group custom-group">
                <div className="form-group custom-group">
                  <label htmlFor="form-label" className="form-label">
                    Cheque Number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="cheque_number"
                    value={values.cheque_number}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "cheque_number"
                      );
                    }}
                    onBlur={handleBlur}
                  />

                  {errors.cheque_number && touched.cheque_number ? (
                    <p className="text-danger">{errors.cheque_number}</p>
                  ) : expenseError ? (
                    <span className="text-danger">
                      {expenseError["cheque_number"] &&
                        expenseError["cheque_number"].map((err) => err)}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null} */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Date <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="11-03-2022"
                name="occurrence_date"
                value={values.occurrence_date}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(
                    formDataIndex,
                    e.target.value,
                    "occurrence_date"
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.occurrence_date && touched.occurrence_date ? (
                <p className="text-danger">{errors.occurrence_date}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["occurrence_date"] &&
                    expenseError["occurrence_date"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Detail/Source"
                name="description"
                value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "description");
                }}
                onBlur={handleBlur}
              />
              {errors.description && touched.description ? (
                <p className="text-danger">{errors.description}</p>
              ) : expenseError ? (
                <span className="text-danger">
                  {expenseError["description"] &&
                    expenseError["description"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-del-col-1">
            {formDataIndex > 14 && (
              <button
                type="button"
                onClick={() => {
                  removeForm(idUnit);
                }}
                className="mt-4 remove-form-button"
              >
                <i className="bi bi-trash-fill"></i>
              </button>
            )}
          </div> */}
      </form>
      {/* </div> */}
    </>
  );
}
