/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NoteState } from "../../../context/notes/NoteState";
import UserServices from "../../../UserServices/UserServices";
import { useState } from "react";
import { updateUser } from "../../schemas";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../../App.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { UId } = state;
  const { branch_id } = state;
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setemail] = useState("");
  const [myData, setMyData] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const getNotifications = async () => {
    try {
      const responce = await UserServices.notificationGet(branch_id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            type: values.type,
            date: values.date,
            message: values.message,
            status: values.status,
          });
        });

        setMyData([...results]);
        const status0Count = results.filter(
          (notification) => notification.status === "0"
        ).length;
        setNotificationCount(status0Count);
      }
    } catch (err) {
      if (err.response?.status === 404) {
        setMyData([]);
        setNotificationCount(0);
      } else {
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getUsers = async () => {
      try {
        const responce = await UserServices.getProfInfo(UId);

        if (responce.status === 200) {
          let data = responce.data.data;
          data.map((value) => {
            setFname(value.first_name);
            setLname(value.last_name);
            setemail(value.email);
            return null;
          });
        }
      } catch (err) {}
    };

    getUsers();
    getNotifications();
  }, []);

  const getIconAndColor = (type) => {
    let icon = "";
    let iconColor = "";

    // Determine the icon and color based on the notification type
    switch (type) {
      case "renewal":
        icon = "bi bi-file-earmark-plus-fill";
        iconColor = "#f50d0d";
        break;
      case "pakages":
        icon = "bi bi-cart-fill";
        iconColor = "#103dd5";
        break;
      case "liabilities":
        icon = "bi bi-currency-exchange";
        iconColor = "#0a6823";
        break;
      // Add more cases for different notification types
      default:
        icon = "bi bi-bell-fill";
        iconColor = "gray";
        break;
    }

    return { icon, iconColor };
  };
  const readRecord = async (Record_Id) => {
    try {
      const responce = await UserServices.readMessage(Record_Id);
      if (responce.status === 204) {
        getNotifications();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const readNotification = (Record_Id) => {
    readRecord(Record_Id);
  };
  const deleteRecord = async (Record_Id) => {
    try {
      const responce = await UserServices.notificationDelete(Record_Id);
      if (responce.status === 204) {
        toast.success("Notification Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getNotifications();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delNotification = (Record_Id) => {
    deleteRecord(Record_Id);
  };
  const LogOut = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  const initialValues = {
    name: fname,
    last_name: lname,
    email: email,
    password: "",
  };

  const UpdateProfile = async (values) => {
    try {
      const response = await UserServices.updateProfInfo(values, UId);

      if (response.status === 204) {
        toast.success("Profile information updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload(false);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updateUser,
      onSubmit: (values) => {
        UpdateProfile(values);
      },
    });

  return (
    <>
      <ToastContainer />
      {/* start wrapper */}
      <div className="wrapper">
        <header className="top-header customer-header">
          {/* nav  */}
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <Link className="navbar-brand custom-logo" to="/dashboard">
                <img src="/assets/images/logo.png" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent5"
                aria-controls="navbarSupportedContent5"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
              <div
                className="collapse navbar-collapse top-head-nav"
                id="navbarSupportedContent5"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item active">
                    {" "}
                    <Link className="nav-link " to="/dashboard">
                      <i className="bi bi-house-fill me-1"></i>Home
                    </Link>
                  </li>
                  <li className="nav-item ">
                    {" "}
                    <Link className="nav-link " to="/report">
                      <i className="bi bi-file-pdf-fill me-1"></i>Reports
                    </Link>
                  </li>
                  <li className="nav-item ">
                    {" "}
                    <Link className="nav-link " to="/search-client">
                      <i className="bi bi-file-easel-fill me-1"></i>Retail
                    </Link>
                  </li>
                  <li className="nav-item dropdown dropdown-large">
                    <Link
                      to="/dashboard"
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      <div className="notifications">
                        <span className="notify-badge">
                          {notificationCount}
                        </span>
                        <i className="bi bi-bell-fill" />
                      </div>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end p-0">
                      <div className="p-2 border-bottom m-2 mb-0">
                        <h5 className="h5 mb-0">Notifications</h5>
                      </div>
                      <div className={`header-notifications-list ${myData.length === 0 ? 'height-200px' : ''}`}>
                        {myData.length === 0 ? (
                          <div className="dropdown-item mt-3">
                            <div className="d-flex align-items-center justify-content-center p-2">
                              <div className="flex-grow-1 notification-text text-center">
                                <h6 className="mb-0">
                                
                                  <span className="no-record-message">
                                    No Notification Found
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        ) : (
                          myData.map((notification) => {
                            const { icon, iconColor } = getIconAndColor(
                              notification.type
                            );

                            return (
                              <div
                                className="dropdown-item border-bottom"
                                key={notification.id}
                                onClick={() =>
                                  setSelectedNotification(notification)
                                }
                              >
                                <div
                                  className="float-end "
                                  onClick={() =>
                                    delNotification(notification.id)
                                  }
                                >
                                  <i className="bi bi-trash-fill fw-bolder text-danger "></i>
                                </div>
                                <div
                                  className="d-flex align-items-center p-2 "
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <div
                                    className="notification-box"
                                    style={{
                                      backgroundColor: "lightpurple",
                                      color: iconColor,
                                    }}
                                  >
                                    <i className={icon} />
                                  </div>
                                  <div className="flex-grow-1 notification-text mt-2">
                                    <h6 className="mb-0 dropdown-msg-user">
                                      <div className="text-capitalize">
                                        {notification.status === "0" ? (
                                          <div className="fw-bolder">
                                            {notification.type}
                                          </div>
                                        ) : (
                                          notification.type
                                        )}
                                        <span className="msg-time float-end text-secondary">
                                          {notification.status === "0" ? (
                                            <div className="fw-bolder text-danger">
                                              {moment(notification.date).format(
                                                "ll"
                                              )}
                                            </div>
                                          ) : (
                                            moment(notification.date).format(
                                              "ll"
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </h6>
                                    <small className="mb-0 mt-2 dropdown-msg-text text-secondary d-flex align-items-center">
                                      {notification.status === "0" ? (
                                        <div className="fw-bolder ">
                                          {notification.message}
                                        </div>
                                      ) : (
                                        notification.message
                                      )}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown ">
                    <Link
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-person-fill"></i>
                      User
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <button
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleExtraLargeModal"
                        >
                          Profile
                        </button>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/add-staff">
                          New User
                        </Link>
                      </li>
                      <li></li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(e) => {
                            LogOut(e);
                          }}
                        >
                          LogOut
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {/* <!--end top header--> */}
        <div
          className="modal fade"
          id="exampleExtraLargeModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Information</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="bi bi-x-lg text-dark fs-4 me-3"></i>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-4 ">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Name"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-4 ">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Name"
                        name="last_name"
                        value={values.last_name || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.last_name && touched.last_name ? (
                        <p className="text-danger">{errors.last_name}</p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p className="text-danger">{errors.email}</p>
                      ) : null}
                    </div>
                    {/* <div className="col-12 col-md-4">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : null}
                    </div> */}
                    <div className="col-12 col-md-4">
                      <label className="form-label">Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={values.password || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </button>
                      </div>
                      {errors.password && touched.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                      <div className="d-flex h-100 w-100 align-items-end">
                        <button className="btn btn-primary w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <br />
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}

        <div
          className="modal fade"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            {selectedNotification !== null && (
              <div className="modal-content">
                <div className="modal-header">
                  <h1
                    className="modal-title fs-5 text-danger text-capitalize"
                    id="staticBackdropLabel"
                  >
                    {selectedNotification.type}
                  </h1>
                  <div className="text-success">
                    {moment(selectedNotification.date).format("ll")}
                  </div>
                </div>
                <div className="modal-body">
                  <p>{selectedNotification.message}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => readNotification(selectedNotification.id)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* --end top header-- */}
      </div>
      {/* --end wrapper-- */}
    </>
  );
};

export default Navbar;
