import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import SellGymPkg from "./SellGymPkg";
import SellTrainerPkg from "./SellTrainerPkg";
import SellRegistrationPkg from "./SellRegistrationPkg";
import SellGuestPassPkg from "./SellGuestPassPkg";
import SellSmallPtGroup from "./SellSmallPtGroup";
import SellNutritionistPkg from "./SellNutritionistPkg";
import SellSessionPkg from "./SellSessionPkg";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import UserServices from "../../UserServices/UserServices";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import CurrencyFormat from "react-currency-format";
import { Tooltip } from "react-tooltip";
import SellFreezinfFee from "./SellFreezinfFee";
import SellGenralPkg from "./SellGenralPkg";
import SellCFTpkg from "./SellCFTpkg";
import SellLesMills from "./SellLesMills";
import { NoteState } from "../../context/notes/NoteState";
import SellPhysioPkg from "./SellPhysioPkg";

const AssingTrainer = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [selectedService, setSelectedService] = useState("");
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState(false);
  const { id, type } = useParams();
  const [myData, setMyData] = useState([]);
  const [catagrieAdded, setCatagrieAdded] = useState([]);

  var ourData;
  // eslint-disable-next-line
  const [limit, setLimit] = useState("10");

  const getCart = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getClientsCart(id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.client_fname + " " + value.client_lname,
            package_name: value.package_name,
            price: value.price,
            discount: value.discount,
            net_price: value.price_with_tax,
            note: value.note,
            tax: value.tax,
          });
          return null;
        });

        setCart(true);
        setSelectedService("");
        setMyData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setSelectedService("");
      if (err.response.status === 404) {
        setMyData([]);
        setLoading(false);
        setCart(false);
      } else {
        setLoading(false);
        setCart(false);
      }
    }
  };
  const getCatagriesAdded = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getclientCatagriesList(id);
      // console.log(responce);
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data;
        setCatagrieAdded(myDataarr);
        $("select").prop("selectedIndex", 0);
      }
    } catch (err) {
      console.log(err);
      $("select").prop("selectedIndex", 0);
      if (err.response.status === 404) {
        setLoading(false);
        setCatagrieAdded([]);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCart();

    getCatagriesAdded();
  }, [id]);
  const delEntry = (record_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(record_id);
      }
    });
  };

  const deleteRecord = async (record_id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deleteClientCartItem(record_id);

      if (responce.status === 204) {
        Swal.fire({
          title: "Success!",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getCart();
        getCatagriesAdded();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const destroyCart = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        allDestroy(id);
      }
    });
  };

  const allDestroy = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.cartDestroy(id);

      if (responce.status === 204) {
        Swal.fire({
          title: "Success!",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getCart();
        getCatagriesAdded();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1 mb-0">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>

              <th
                scope="col"
                onClick={() => requestSort("client_fname")}
                className={getClassNamesFor("client_fname")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Package Price
              </th>

              <th
                scope="col"
                onClick={() => requestSort("discount")}
                className={getClassNamesFor("discount")}
              >
                Discount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("tax")}
                className={getClassNamesFor("tax")}
              >
                GST
              </th>
              <th
                scope="col"
                onClick={() => requestSort("net_price")}
                className={getClassNamesFor("net_price")}
              >
                Total Price
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.name}</td>
                  <td>
                    {el.package_name !== null ? el.package_name : "Guest Pass"}

                    {el.note === "" ? null : (
                      <>
                        <i
                          className="bi bi-info-square note-here"
                          data-tooltip-id={el.id}
                          data-tooltip-content={el.note}
                        ></i>
                        <Tooltip
                          id={el.id}
                          place="right"
                          className="note-tooltip"
                          clickable
                        />
                      </>
                    )}
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.discount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.tax}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.net_price}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandSpacing={"2s"}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </td>

                  <td>
                    <button
                      className="text-danger custom-button-image"
                      type="button"
                      onClick={() => {
                        delEntry(el.id);
                      }}
                    >
                      <i className="bi bi-trash-fill"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            {cart ? (
              <>
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Invoice </h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                  <div className="row">
                    <div className="col-12 ">
                      <div className="grand-total-cart">
                        <div className="cart-entry">
                          Total Price{" "}
                          <CurrencyFormat
                            value={myData.reduce(
                              (a, v) => (a = a + v.price),
                              0
                            )}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>

                        <div className="cart-entry">
                          Total Discount{" "}
                          <CurrencyFormat
                            value={myData.reduce(
                              (a, v) => (a = a + v.discount),
                              0
                            )}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>

                        <div className="cart-entry">
                          GST{" "}
                          <CurrencyFormat
                            value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>

                        <div className="cart-entry">
                          Grand Total{" "}
                          <CurrencyFormat
                            value={myData.reduce(
                              (a, v) => (a = a + v.net_price),
                              0
                            )}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grand-totl-fine">
                    <div className="col-md-7"></div>
                    <button
                      type="button"
                      className="btn btn-primary cancel-order px-4 "
                      onClick={destroyCart}
                    >
                      Cancel
                    </button>
                    <Link
                      to={"/check_out/" + id + "/" + type}
                      className="btn btn-primary px-4 ms-2 addclient-button-custom"
                    >
                      Pay Now
                    </Link>
                  </div>
                </div>
              </>
            ) : null}

            <div className="card-header py-3">
              <div className="row">
                <div className="col-6">
                  <h5 className="mb-0 font-20">Sell Package </h5>
                </div>
                <div className="col-6">
                  <Link
                    to={-1}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg"
                  style={{ color: "red" }}
                  aria-hidden="true"
                />
                The Fields With <strong style={{ color: "red" }}>*</strong> Must
                Required Or Fill.
              </p>
              <div className="row g-3 trainee-add">
                <div className="col-12 col-md-12">
                  <div className="form-group custom-group">
                    <label className="form-label">Select Service</label>
                    <span className="d-none">
                      {" "}
                      {(ourData = Object.values(catagrieAdded))}
                    </span>
                    {ourData.length === 7 ? (
                      <select
                        className="form-select"
                        name="pay_met"
                        disabled={true}
                      >
                        <option value="Select">All Packages Sold</option>
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="pay_met"
                        defaultValue="Select"
                        onChange={(e) => {
                          setSelectedService(e.target.value);
                        }}
                      >
                        <option value="Select">Select Service</option>
                        {ourData.includes("6") ? null : (
                          <option value="6">Sell Registration Package</option>
                        )}

                        {ourData.includes("1") ? null : (
                          <option value="1">Sell Gym Package</option>
                        )}
                        {ourData.includes("2") ? null : (
                          <option value="2">Sell Trainer Package</option>
                        )}
                        {ourData.includes("3") ? null : (
                          <option value="3">Sell Guest Pass Package</option>
                        )}
                        {ourData.includes("4") ? null : (
                          <option value="4">Sell Small Group PT Package</option>
                        )}
                        {ourData.includes("5") ? null : (
                          <option value="5">Sell Nutrition Package</option>
                        )}
                        {ourData.includes("7") ? null : (
                          <option value="7">Sell Bootcamp Package</option>
                        )}
                        {ourData.includes("12") ? null : (
                          <option value="12">Sell LesMills</option>
                        )}
                        {ourData.includes("8") ? null : (
                          <option value="8">Sell Freezing</option>
                        )}
                        {ourData.includes("9") ? null : (
                          <option value="9">Sell General Package</option>
                        )}
                        {ourData.includes("11") ? null : (
                          <option value="11">Sell CFT Package</option>
                        )}
                        {ourData.includes("14") ? null : (
                          <option value="14">Sell Physiotherapy Package</option>
                        )}

                        {/* {ourData.includes("10") ? null : (
                          <option value="10">Sell Cafe Products</option>
                        )} */}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedService === "1" ? (
              <SellGymPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "2" ? (
              <SellTrainerPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "6" ? (
              <SellRegistrationPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "3" ? (
              <SellGuestPassPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "4" ? (
              <SellSmallPtGroup cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "5" ? (
              <SellNutritionistPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "7" ? (
              <SellSessionPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "8" ? (
              <SellFreezinfFee cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "9" ? (
              <SellGenralPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "11" ? (
              <SellCFTpkg cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "12" ? (
              <SellLesMills cart={getCart} catagry={getCatagriesAdded} />
            ) : selectedService === "14" ? (
              <SellPhysioPkg cart={getCart} catagry={getCatagriesAdded} />
            ) : null}
            {/* selectedService === "10" ? (
              <SellCafe cart={getCart} catagry={getCatagriesAdded} />
            ): */}
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default AssingTrainer;
