import React, { useContext } from "react";
import { useState } from "react";
import MenuButton from "../../MenuButton/MenuButton";

import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { NoteState } from "../../../context/notes/NoteState";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import ViewMultiMeal from "./ViewMultiMeal";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import moment from "moment";

export default function ViewMealPlans() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [myBranches, setMyBranches] = useState([]);

  const [selectedBranch, setselectedBranch] = useState(
    branch_id ? branch_id : ""
  );

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const [name, setName] = useState([]);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  const getRecord = async (bId, start, end, limit, page) => {
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;

    // hiddenInput.value = inputValue;

    for (var i = 0; i < options.length; i++) {
      var option = options[i];

      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }

    let client_id = await document.getElementById("answerInput-hidden").value;

    if (start !== "" && end === "") {
      toast.error("Please select end date!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (start === "" && end !== "") {
      toast.error("Please select start date!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        setLoading(true);

        const responce = await UserServices.getMealPlanList(
          bId,
          client_id,
          start,
          end,
          limit,
          page
        );

        var myDataarr = responce?.data?.data?.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.uuid,
            start_date: values.start_date,
            end_date: values.end_date,
            client_name: values.client_detail?.client_name,
            branch_name: values.branch_detail?.name,
          });
          return null;
        });

        setMyData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total);
        setTotalPages(
          Math.ceil(responce.data.data.total / responce.data.data.per_page)
        );

        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    branches();
    nameClients();
    getRecord(selectedBranch, StartDate, EndDate, limit, currentPage);
  }, []);
  //   const generatePdf = (table) => {
  //     const doc = new jsPDF("p", "mm", "letter");

  //     // Loop through all the tables in the section and add them to the PDF

  //     // Add a new page for each table (skip first table as it is already on the first page)
  //     const captionText = table.getAttribute("data-caption");
  //     // Add the table caption as regular text above the table
  //     doc.text(captionText, 10, 10);
  //     const tableOptions = {
  //       headStyles: { fillColor: [232, 0, 43], textColor: 255 },
  //       styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
  //     };
  //     doc.autoTable({
  //       html: table,
  //       ...tableOptions,
  //     });

  //     doc.save("expenses.pdf");
  //   };

  const delMeal = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responce = await UserServices.deleteMealPlanSpec(id);
          if (responce.status === 204) {
            Swal.fire({
              title: "Deleted !",
              text: "Record deleted successfully.",
              icon: "success",
              confirmButtonColor: "#ed1c24",
            });
            getRecord(selectedBranch, StartDate, EndDate, limit, currentPage);
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1 align-middle"
          data-caption="Assign Time Slots"
          id="report-POS"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("start_date")}
                className={getClassNamesFor("start_date")}
              >
                Start Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("end_date")}
                className={getClassNamesFor("end_date")}
              >
                End Date
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.client_name}</td>

                  <td>{moment(el.start_date).format("DD-MM-YYYY")}</td>

                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>

                  <td>
                    <Link
                      className="updt-btn inactive-btn"
                      to={`/detail-meal-plan/${el.id}`}
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">View</span>
                    </Link>
                    <button
                      type="button"
                      onClick={() => delMeal(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getRecord(selectedBranch, StartDate, EndDate, limit, e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                View Meals Plans{" "}
                <Link
                  to="/add-meal-plan"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  Add Meals Plan
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-lg-2">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={selectedBranch}
                      onChange={(e) => {
                        setselectedBranch(e.target.value);
                      }}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                </div>

                <div className="col-12 col-lg-3">
                  <label className="form-label">
                    Client Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Client Name"
                    name="client_name"
                    list="member_id"
                    id="answerInput"
                  />

                  <input type="hidden" name="answer" id="answerInput-hidden" />
                  <datalist id="member_id">
                    {name.map((el, index) => {
                      return (
                        <option key={index} data-value={el.id} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>

                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-1 mt-1">
                  <button
                    type="submit"
                    className="btn btn-primary  file-download mt-4"
                    onClick={() => {
                      getRecord(
                        selectedBranch,
                        StartDate,
                        EndDate,
                        limit,
                        currentPage
                      );
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="table-responsive mt-4">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
