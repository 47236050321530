/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { staffLoan } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { NoteState } from "../../context/notes/NoteState";
import { viewFines } from "../schemas";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { toast } from "react-toastify";
import $ from "jquery";

const ManageStaffLones = () => {
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
//get next month start date
  const currentDate = new Date();
  const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const nextMonthFirstDate = nextMonth.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
  let sDate = moment(nextMonthFirstDate).format("YYYY-MM-DD");
  let tableSDate = moment().startOf("month").format("YYYY-MM-DD");

  const [paymentMethodsSplit, setPaymentMethodsSplit] = useState([]);
  // eslint-disable-next-line
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [bank, setBank] = useState([]);

  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [advanceTime, setAdvanceTime] = useState();
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState(false);
  const month = date.getMonth();
    // eslint-disable-next-line
  const monthString = (parseInt(month) + 1).toString();
  const today = new Date();
  const year = today.getFullYear();
  // const [stDate, setstartDate] = useState(sDate);
  // const [enDate, setendDate] = useState(eDate);
  var CurrencyFormat = require("react-currency-format");
// update
const [fname, setFname] = useState("");
const [amount, setAmount] = useState("");
const [reason, setReason] = useState("");
const [selectedTerm, setSelectedTerm] = useState("");
const [selectedStartDate, setSelectedStartDate] = useState("");
const [selectedPaymentType, setSelectedPaymentType] = useState("");
const [selectedTransactionType, setSelectedTransactionType] = useState("");
const [bankAccount, setBankAccount] = useState("");
const [branch, setBranch] = useState("");
const [recordDate, setRecordDate] = useState("");
const [recordId, setRecordId] = useState("");

var clickEvent = $.Event("click");

const closeButtonRef = useRef(null);
const getUser = async (id) => {
  setLoading(true);
  try {
    const responce = await UserServices.getStaffLoansInfo(id);
    if (responce.status === 200) {
      let data = responce.data.data;
      data.map((values) => {
        setFname(values.staff_id);
        setAmount(values.amount);
        setSelectedTerm(values.term);
        setReason(values.reason);
        setRecordDate(values.date);
        setSelectedStartDate(values.return_start_date);
        setSelectedPaymentType(values.payment_type_id);
        setSelectedTransactionType(values.transaction_type);
        setBankAccount(values.bank_account_id);
        setBranch(values.branch_id);
        setRecordId(values.id);
        return null;
      });
      $("#membership").trigger(clickEvent);
      setLoading(false);
    }
  } catch (err) {
    if (err.response.status === 404) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
};
  const getUsers = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getStaffLoans(
        branch_id,
        "1",
        newLimit,
        pageNo,
        values
      );
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/staff-loans/export?branch_id=" +
            branch_id +
            "&start_date=" +
         values.startdate +
            "&end_date=" +
           values.enddate
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            staff_id: values.staff_id,
            staff_name: values.staff_name,
            amount: values.amount,
            reason: values.reason,
            date: values.date,
            status: values.status,
            return_start_date: values.return_start_date,
            received: values.received,
            term: values.term,
            installment: values.installment,
            transaction_type: values.transaction_type,
            payment_method: values.payment_method,
            installments_detail: values.installments_detail,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setExportLink();

        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setExportLink();
      }
    }
  };
  const staffNames = async () => {
    // setCurrentPage();
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
 
  //   try {
  //     const responce = await UserServices.paymentNames();
  //     const res = responce.data.data;
  //     const results = [];
  //     res.map((value) => {
  //       results.push({
  //         id: value.id,
  //         name: value.name,
  //       });
  //       return true;
  //     });
  //     setPaymentMethods([...results]);
  //     setPaymentMethodsSplit([...results]);
  //   } catch (err) {
  //     console.log(err);

  //     setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
  //     setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
  //   }
  // };
  const getPaymentMethodinitial = async () => {
    try {
      const responce = await UserServices.paymentNames();
      const res = responce.data.data;
      const results = res.filter((value) => value.id !== 81 && value.id !== 82); // Filter out payment method with id 81
      setPaymentMethods([...results]);
      setPaymentMethodsSplit([...results]);
    } catch (err) {
      console.log(err);

      setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
      setPaymentMethodsSplit([{ name: "No Payment Method Found", id: "" }]);
    }
  };
  const bankDetails = async () => {
    try {
      const responce = await UserServices.bankList(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          bank_name: value.bank_name,
          account_no: value.account_no,
        });
        return null;
      });
      setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setBank([{ bank_name: "No Bank Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit);
    staffNames();
    getPaymentMethodinitial(branch_id);
    bankDetails();
  }, []);
  const deleteRecord = async (stafffine_id) => {
    try {
      const responce = await UserServices.deleteStaffAdvance(stafffine_id);

      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (stafffine_id) => {
 
        deleteRecord(stafffine_id);
  };
  const changeLoanTime = (e) => {
    let monthCode = e;
    setAdvanceTime(monthCode);

    const getFineTimeData = async () => {
      setLoading(true);

      if (!monthCode) {
        setLoading(false);
        return;
      }
      const month = parseInt(monthCode, 10); // Parse the month code as an integer

      // Create a new Date object for the next month
      const endDate = new Date(year, month, 0);

      // Extract the day from the end date
      const endDay = endDate.getDate();

      setValues({
        startdate: year + "-" + monthCode + "-01",
        enddate: year + "-" + monthCode + "-" + endDay,
      });
      try {
        const responce = await UserServices.getStaffLoanDataByTime(
          branch_id,
          // "1",
          "10",
          1,
          year + "-" + monthCode + "-01",
          year + "-" + monthCode + "-" + endDay
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              staff_name: values.staff_name,
              amount: values.amount,
              reason: values.reason,
              date: values.date,
              status: values.status,
              return_start_date: values.return_start_date,
              received: values.received,
              term: values.term,
              installment: values.installment,
              transaction_type: values.transaction_type,
              payment_method: values.payment_method,
              installments_detail: values.installments_detail,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setLimit(responce.data.data.per_page);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          console.log(err);
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
          // setError("No record  found");
        } else {
          setLoading(false);
        }
      }
    };
    getFineTimeData();
  };
  const findLoan = async (values) => {
    if (Date.parse(values.startdate) <= Date.parse(values.enddate)) {
      if (Date.parse(values.enddate) >= Date.parse(values.startdate)) {
        setLoading(true);
        try {
          const response = await UserServices.getStaffLoanDataByYear(
            branch_id,
            // "1",
            limit,
            1,
            values
          );

          let myDataarr;

          if (response.status === 200) {
            setExportLink(
              process.env.REACT_APP_API_Link +
                "/staff-loans/export?branch_id=" +
                branch_id +
                "&start_date=" +
                values.startdate +
                "&end_date=" +
                values.enddate
            );
            myDataarr = response.data.data.data;
            const res = myDataarr;
            const results = [];

            res.map((values) => {
              results.push({
                id: values.id,
                staff_name: values.staff_name,
                amount: values.amount,
                reason: values.reason,
                date: values.date,
                status: values.status,
                return_start_date: values.return_start_date,
                received: values.received,
                term: values.term,
                installment: values.installment,
                transaction_type: values.transaction_type,
                payment_method: values.payment_method,
                installments_detail: values.installments_detail,
              });
              return null;
            });

            setMyData([...results]);
            setTotalResults(response.data.totalRecord);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setExportLink();
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Start date should be less then end date!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const deleteSubRecord = async (id) => {
    try {
      const responce = await UserServices.deleteLoanInstallmets(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delInstallment = (id) => {
    deleteSubRecord(id);
  };
  const addLoan = async (values, action) => {
    if (Date.parse(values.date) <= Date.parse(values.return_start_date)) {

    try {
      setLoading(true);
      const response = await UserServices.addStaffLoan(
        branch_id,
        values
      );
      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getUsers(currentPage, limit);
      }
    } catch (err) {
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        //  toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  } 
 else {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Return start date should be greater then start date!",
  });
}
  };

  const addLoaninitialValues = {
    staff_id: "",
    amount: "",
    transaction_type:"",
    payment_type_id:"",
    term:"",
    date: eDate,
    return_start_date: sDate,
    reason: "N/A",
    branch_id: "",
    cheque_no:"",
    bank_account_id:"",
  };
  const initialValues = {
    startdate:  tableSDate,
    enddate: eDate,
  };
    const {
      values: addLoanValues,
      errors: addLoanErrors,
      touched: addLoanTouched,
      handleBlur: addLoanHandleBlur,
      handleChange: addLoanHandleChange,
      handleSubmit: addLoanHandleSubmit,
    } = useFormik({
      initialValues: addLoaninitialValues,
      validationSchema: staffLoan,
      onSubmit: (addFineValues, action) => {
        addLoan(addFineValues, action);
      },
    });

  
    const {
      values,
      errors,
      touched,
      handleBlur,
      setValues,
      handleChange,
      handleSubmit,
    } = useFormik({
      initialValues: initialValues,
      validationSchema: viewFines,
      onSubmit: (values) => {
        findLoan(values);
      },
    });

    const showInstalDetails = (id) => {
      // $(".instalmnt-info").slideUp();
  
      $("#" + id).fadeToggle();
    };
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table className="table table-borderless table-style-1 mt-3">
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("staff_name")}
                  className={getClassNamesFor("staff_name")}
                >
                  Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("amount")}
                  className={getClassNamesFor("amount")}
                >
                  Amount
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("transaction_type")}
                  className={getClassNamesFor("transaction_type")}
                >
                  Transaction Type
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("payment_method")}
                  className={getClassNamesFor("payment_method")}
                >
                  Payment Method
                </th>
                {/* <th
                  scope="col"
                  onClick={() => requestSort("received")}
                  className={getClassNamesFor("received")}
                >
                  Pending Balance
                </th> */}
                <th
                  scope="col"
                  onClick={() => requestSort("term")}
                  className={getClassNamesFor("term")}
                >
                  Term
                </th>
                <th scope="col" className="no_sort">
                  Reason
                </th>
  
                <th
                  scope="col"
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Date
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("return_start_date")}
                  className={getClassNamesFor("return_start_date")}
                >
                  Return Start Date
                </th>
  
                <th
                  scope="col"
                  onClick={() => requestSort("status")}
                  className={getClassNamesFor("status")}
                >
                  Status
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <>
                  <tr key={index}>
                  <td className="d-flex">
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                      {el.installments_detail.length ? (
                        <div
                          className="ms-2"
                          onClick={() => {
                            showInstalDetails(el.id);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <i className="bi bi-chevron-down fw-bold"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Link to={"/staff-home/" + el.staff_id}>
                        {el.staff_name}
                      </Link>
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>
                      {el.transaction_type}
                    </td>
                    <td>{el.payment_method}</td>
                    {/* <td>
                      {" "}
                      <CurrencyFormat
                        value={el.installment}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3"}
                      />
                    </td> */}
                    <td>
                      {el.term} {el.term === 1 ? "Month" : "Months"}
                    </td>
                    <td>{el.reason}</td>
                    <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                    <td>{moment(el.return_start_date).format("DD-MM-YYYY")}</td>
                    <td>
                      {el.installments_detail.length ? (
                        <span>
                          {el.installments_detail.reduce(
                            (total, inl) => total + inl.amount,
                            0
                          ) === parseInt(el.amount) ? (
                            <span className="fw-bold text-success">
                              <i className="bi bi-check2-circle text-success me-2"></i>
                              Paid
                            </span>
                          ) : (
                            <span className="fw-bold text-secondary">
                              <i className="bi bi-hourglass-split me-2"></i>
                              In Progress
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="fw-bold text-danger">
                          {" "}
                          <i className="bi bi-exclamation-circle text-danger me-2"></i>
                          Pending
                        </span>
                      )}
                    </td>
                    <td>
                    {el.installments_detail.length ? (
                        el.installments_detail.reduce(
                          (total, inl) => total + inl.amount,
                          0
                        ) === parseInt(el.amount) ? (
                          <span
                            className="pay-btn disabled"
                            style={{
                              cursor: "disabled",
                            }}
                          >
                            <i className="bi bi-credit-card text-dark"></i>{" "}
                            <span className="text-dark">Receive</span>
                          </span>
                        ) : (
                          <Link
                            to={"/pay-loan/" + el.id}
                            className="print-btn"
                          >
                            <i className="bi bi-credit-card"></i>{" "}
                            <span className="inative-text">Receive</span>
                          </Link>
                        )
                      ) : (
                        <Link
                          to={"/pay-loan/" + el.id}
                          className="print-btn"
                        >
                          <i className="bi bi-credit-card"></i>{" "}
                          <span className="inative-text">Receive</span>
                        </Link>
                      )}
                      {el.installments_detail.length ? (
  el.installments_detail.reduce((total, inl) => total + inl.amount, 0) === parseInt(el.amount) ? (
    <span className="disabled  ms-2" style={{cursor: "disabled"}}>
      <span className="text-warning">
        <i className="bi bi-pencil-fill text-secondary" />
      </span>
    </span>
  ) : (
    <button
                        onClick={() => {
                        getUser(el.id);
                      }}
                        className="text-warning border-0 bg-transparent"
                      >
                        <i className="bi bi-pencil-fill" />
                      </button>
  )
) : (
  <button
                        onClick={() => {
                        getUser(el.id);
                      }}
                        className="text-warning border-0 bg-transparent"
                      >
                        <i className="bi bi-pencil-fill" />
                      </button>
)}


                      {/* <Link to={"/update-loan/" + el.id} className="text-warning">
                        {" "}
                        <i className="bi bi-pencil-fill" />
                      </Link> */}
                      
                      <button
                        type="button"
                        className="text-danger custom-button-image"
                        onClick={() => delFine(el.id)}
                      >
                        <i className="bi bi-trash-fill" />
                      </button>
                    </td>
                  </tr>
                  {el.installments_detail.length ? (
                    <tr
                      className="instalmnt-info table table-striped table-borderless table-style-1"
                      id={el.id}
                    >
                      <td colSpan="9">
                        <tr>
                          <th width="8%"></th>
                          <th>Amount</th>
                          <th>Bank Name</th>
                          <th>Account Number</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                        {el.installments_detail.map((inl, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>
                              <CurrencyFormat
                                value={inl.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                thousandSpacing={"3"}
                              />
                            </td>
                            <td>{inl.banking_detail?.name ?? "N/A"}</td>
                            <td>{inl.banking_detail?.account_no ?? "N/A"}</td>
                            <td>{moment(inl.date).format("DD-MM-YYYY")}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => delInstallment(inl.id)}
                                className="ms-2 inactive-btn"
                              >
                                <i className="bi bi-trash3 text-danger"></i>{" "}
                                <span className="text-danger inative-text ms-1">
                                  Delete
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
            
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              onChange={(e) => {
                getUsers(e, limit);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </>
      );
    };

    const UpdateLoan = async (updateValues) => {

      setLoading(true);
  
      try {
        const response = await UserServices.UpdateStaffLoan(branch_id, updateValues, recordId);
        if (response.status === 204) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          getUsers(currentPage, limit);
          // Trigger the close button click event
          closeButtonRef.current.click();
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
  
          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    };
    const updateinitialValues = {
      date: recordDate,
      staff_id: fname,
      term: selectedTerm,
      amount: amount,
      payment_type_id:selectedPaymentType,
      reason: reason,
      return_start_date: selectedStartDate,
      transaction_type: selectedTransactionType,
      bank_account_id: bankAccount,
      branch_id: branch,
    };

      //....................................................................................................................................
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: staffLoan,
    onSubmit: (updateValues) => {
      UpdateLoan(updateValues);
    },
  });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add Staff Loans</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>{" "}
                  Must Required Or Fill.
                </p>
                <form className="row g-3 trainee-add" onSubmit={addLoanHandleSubmit}>
                
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Staff Name<span className="text-danger">*</span>
                      </label>
                      {names ? (
                        <select
                          className="form-select"
                          name="staff_id"
                          value={addLoanValues.staff_id}
                          onChange={(e) => {
                            addLoanHandleChange(e);
                          }}
                          onBlur={addLoanHandleBlur}
                        >
                          {names.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addLoanErrors.staff_id && addLoanTouched.staff_id ? (
                        <p className="text-danger">{addLoanErrors.staff_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Amount "
                        name="amount"
                        value={addLoanValues.amount}
                        onChange={(e) => {
                            addLoanHandleChange(e);
                          }}
                        onBlur={addLoanHandleBlur}
                      />
                      {addLoanErrors.amount && addLoanTouched.amount ? (
                        <p className="text-danger">{addLoanErrors.amount}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Transaction<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="transaction_type"
                            value={addLoanValues.transaction_type || ""}
                            onChange={(e) => {
                              addLoanHandleChange(e);
                            }}
                            onBlur={addLoanHandleBlur}
                          >
                            <option value="-1">Select Transaction</option>
                            <option value="Bank Account">Bank Account</option>
                            <option value="Sales Counter">Sales Counter</option>
                            <option value="Office Counter">Office Counter</option>
                            <option value="Personal">Personal</option>
                          </select>
                          {addLoanErrors.transaction_type &&
                          addLoanTouched.transaction_type ? (
                            <p className="text-danger">
                              {addLoanErrors.transaction_type}
                            </p>
                          ) : null}
                        </div>
                  </div>
                  {addLoanValues.transaction_type === "Bank Account" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Bank Details<span className="text-danger">*</span>
                            </label>
                            {bank ? (
                              <select
                                className="form-select"
                                name="bank_account_id"
                                value={addLoanValues.bank_account_id || ""}
                                onChange={(e) => {
                                  addLoanHandleChange(e);
                                }}
                                onBlur={addLoanHandleBlur}
                              >
                                {bank.map((el, index) => {
                                  return (
                                    <option key={index} value={el.id}>
                                      {el.bank_name} {el.account_no}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : null}
                            {addLoanErrors.bank_account_id &&
                            addLoanTouched.bank_account_id ? (
                              <p className="text-danger">
                                {addLoanErrors.bank_account_id}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                  <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment Method</label>
                          <span className="text-danger">*</span>
                          {paymentMethodsSplit.length ? (
                            <select
                              className="form-select"
                              name="payment_type_id"
                              value={addLoanValues.payment_type_id}
                              onChange={addLoanHandleChange}
                              onBlur={addLoanHandleBlur}
                            >
                              <option>Select Payment Method</option>
                              {paymentMethodsSplit.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="payment_type_id"
                              value={addLoanValues.payment_type_id}
                              onChange={(e) => {
                                addLoanHandleChange(e);
                              }}
                              onBlur={addLoanHandleBlur}
                            >
                              <option>Select Payment Method</option>
                            </select>
                          )}
                          {addLoanErrors.payment_type_id && addLoanTouched.payment_type_id ? (
                            <p className="text-danger">
                              {addLoanErrors.payment_type_id}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {addLoanValues.payment_type_id === "32" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="cheque_no"
                                value={addLoanValues.cheque_no}
                                onChange={(e) => {
                                  addLoanHandleChange(e);
                                }}
                                onBlur={addLoanHandleBlur}
                              />

                              {addLoanErrors.cheque_no && addLoanTouched.cheque_no ? (
                                <p className="text-danger">
                                  {addLoanErrors.cheque_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Term<span className="text-danger">*</span>
                        <span className="text-black-50">
                          ( Please enter the term in form of month )
                        </span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Please enter the term in form of month"
                        name="term"
                        value={addLoanValues.term}
                        onChange={(e) => {
                            addLoanHandleChange(e);
                          }}
                        onBlur={addLoanHandleBlur}
                      />
                      {addLoanErrors.term && addLoanTouched.term ? (
                        <p className="text-danger">{addLoanErrors.term}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="15-02-2022"
                        name="date"
                        value={addLoanValues.date}
                        onChange={addLoanHandleChange}
                        onBlur={addLoanHandleBlur}
                      />
                      {addLoanErrors.date && addLoanTouched.date ? (
                        <p className="text-danger">{addLoanErrors.date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Return Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="15-02-2022"
                        name="return_start_date"
                        value={addLoanValues.return_start_date}
                        onChange={addLoanHandleChange}
                        onBlur={addLoanHandleBlur}
                      />
                      {addLoanErrors.return_start_date && addLoanTouched.return_start_date ? (
                        <p className="text-danger">{addLoanErrors.return_start_date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Reason"
                        name="reason"
                        value={addLoanValues.reason}
                        onChange={addLoanHandleChange}
                        onBlur={addLoanHandleBlur}
                      />
                      {addLoanErrors.reason && addLoanTouched.reason ? (
                        <p className="text-danger">{addLoanErrors.reason}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add Loan
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
            <div className="card-header py-3">
            <h5 className="mb-0 font-20">View Staff Loans</h5>
          </div>
            <div className="card-body">
            <form className="mb-3" onSubmit={handleSubmit}>
            <div className="row d-flex">
              <div className="col-md-2 mt-3">
                <label className="form-label">Filter By Month</label>
                <select
                  className="form-select"
                  onChange={(e) => changeLoanTime(e.target.value)}
                  defaultValue={advanceTime}
                >
                  <option value="">Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>Grand Total
                </select>
              </div>
              <div className="col-md-2 mt-3">
                <label className="form-label">From</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="date"
                  name="startdate"
                  value={values.startdate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.startdate && touched.startdate ? (
                  <p className="text-danger">{errors.startdate}</p>
                ) : null}
              </div>
              <div className="col-md-2 mt-3">
                <label className="form-label">To</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="date"
                  name="enddate"
                  value={values.enddate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.enddate && touched.enddate ? (
                  <p className="text-danger">{errors.enddate}</p>
                ) : null}
              </div>
              <div className="col-12 col-md-2">
                <button
                  type="submit"
                  className="btn btn-primary d-inline-block btn-sm mt-5"
                  onChange={() => findLoan(values)}
                >
                  Generate
                </button>
              </div>
              {/* {totalResults > limit ? ( */}
              <div className="col-md-1 col-lg-1 mt-5 ms-auto">
                <div className="input-group">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => getUsers("1", e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                  {/* <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </button> */}
                </div>
              </div>
              {/* ) : null} */}
              <div className="col-12 col-md-1">
                <a
                  href={exportLink}
                  rel="noreferrer"
                  // target="_blank"
                  className="btn btn-primary d-inline-block btn-sm mt-5"
                >
                  <i className="bi bi-download" /> Export
                </a>
              </div>
            </div>
            
          </form>
            <div className="table-responsive mt-3 ">
              <Table data={myData} rowsPerPage={limit} />
            </div>
          </div>
          </div>
          <div className="d-flex">
        <div className="ms-auto d-flex">
        <div className="mt-1">
              <h5>Grand Total:</h5>
              </div>
              <div className="ms-2  fs-5">
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.amount), 0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                thousandSpacing={"3"}
              />
              </div>
            </div>
            </div>
          </div>
        </main>
      </div>
            {/* ......Hidden Button....... */}
            <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Staff Loan
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
            <form className="row g-3 trainee-add" onSubmit={updateHandleSubmit}>
                <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Staff Name<span className="text-danger">*</span>
                      </label>
                      {names ? (
                        <select
                          className="form-select"
                          name="staff_id"
                          value={updateValues.staff_id}
                          onChange={(e) => {
                            updateHandleChange(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {names.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.staff_id && updateTouched.staff_id ? (
                        <p className="text-danger">{updateErrors.staff_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Amount "
                        name="amount"
                        value={updateValues.amount}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.amount && updateTouched.amount ? (
                        <p className="text-danger">{updateErrors.amount}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Transaction<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            name="transaction_type"
                            value={updateValues.transaction_type || ""}
                            onChange={(e) => {
                              updateHandleChange(e);
                            }}
                            onBlur={updateHandleBlur}
                          >
                            <option value="-1">Select Transaction</option>
                            <option value="Bank Account">Bank Account</option>
                            <option value="Sales Counter">Sales Counter</option>
                            <option value="Office Counter">Office Counter</option>
                            <option value="Personal">Personal</option>
                          </select>
                          {updateErrors.transaction_type &&
                          updateTouched.transaction_type ? (
                            <p className="text-danger">
                              {updateErrors.transaction_type}
                            </p>
                          ) : null}
                        </div>
                  </div>
                  {updateValues.transaction_type === "Bank Account" ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Bank Details<span className="text-danger">*</span>
                            </label>
                            {bank ? (
                              <select
                                className="form-select"
                                name="bank_account_id"
                                value={updateValues.bank_account_id || ""}
                                onChange={(e) => {
                                  updateHandleChange(e);
                                }}
                                onBlur={updateHandleBlur}
                              >
                                {bank.map((el, index) => {
                                  return (
                                    <option key={index} value={el.id}>
                                      {el.bank_name} {el.account_no}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : null}
                            {updateErrors.bank_account_id &&
                            updateTouched.bank_account_id ? (
                              <p className="text-danger">
                                {updateErrors.bank_account_id}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                  <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment Method</label>
                          <span className="text-danger">*</span>
                          {paymentMethodsSplit.length ? (
                            <select
                              className="form-select"
                              name="payment_type_id"
                              value={updateValues.payment_type_id}
                              onChange={updateHandleChange}
                              onBlur={updateHandleBlur}
                            >
                              <option>Select Payment Method</option>
                              {paymentMethodsSplit.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              name="payment_type_id"
                              value={updateValues.payment_type_id}
                              onChange={(e) => {
                                updateHandleChange(e);
                              }}
                              onBlur={updateHandleBlur}
                            >
                              <option>Select Payment Method</option>
                            </select>
                          )}
                          {updateErrors.payment_type_id && updateTouched.payment_type_id ? (
                            <p className="text-danger">
                              {updateErrors.payment_type_id}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {updateValues.payment_type_id === "32" ||
                      updateValues.payment_type_id === 32 ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group custom-group">
                            <div className="form-group custom-group">
                              <label
                                htmlFor="form-label"
                                className="form-label"
                              >
                                Cheque Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="cheque_no"
                                value={updateValues.cheque_no}
                                onChange={(e) => {
                                  updateHandleChange(e);
                                }}
                                onBlur={updateHandleBlur}
                              />

                              {updateErrors.cheque_no && updateTouched.cheque_no ? (
                                <p className="text-danger">
                                  {updateErrors.cheque_no}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Term<span className="text-danger">*</span>
                        <span className="text-black-50">
                          ( Please enter the term in form of days )
                        </span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Please enter the term in form of days"
                        name="term"
                        value={updateValues.term}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.term && updateTouched.term ? (
                        <p className="text-danger">{updateErrors.term}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="15-02-2022"
                        name="date"
                        value={updateValues.date}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.date && updateTouched.date ? (
                        <p className="text-danger">{updateErrors.date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Return Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="15-02-2022"
                        name="return_start_date"
                        value={updateValues.return_start_date}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.return_start_date && updateTouched.return_start_date ? (
                        <p className="text-danger">{updateErrors.return_start_date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Reason"
                        name="reason"
                        value={updateValues.reason}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.reason && updateTouched.reason ? (
                        <p className="text-danger">{updateErrors.reason}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Update
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ManageStaffLones;
