import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";

import { useMask } from "react-mask-field";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { addLiabilities } from "../schemas";

export default function LiabilitiesMultiFormGenerator({
  onSubmit,
  formDataIndex,
  onFormChange,
  liabilityError,
  idUnit,
  removeForm,
  catgries,
}) {
  const [myBranches, setMyBranches] = useState([]);

  const [subCatgries, setSubCatgries] = useState([]);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  const { state } = useContext(NoteState);
  const { branch_id } = state;

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const getSubCategories = async (e) => {
    var sb_catgry = e.target.value;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([...results]);
    } catch (err) {
      setSubCatgries([{ name: "No record found", id: "" }]);
      console.log(err);
    }
  };

  useEffect(() => {
    branches();

    onFormChange(formDataIndex, branch_id, "branch_id");
    onFormChange(formDataIndex, defaultValue, "maturity_date");
  }, []);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });

  const initialValues = {
    id: idUnit,
    branch_id: branch_id,
    category_id: "",
    sub_category_id: "",
    creditor_name: "",
    creditor_contact: "",
    amount_owned: "",
    description: "",
    maturity_date: defaultValue,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addLiabilities,
      onSubmit: onSubmit,
    });

  return (
    <>
      {formDataIndex === 0 && (
        <p>
          <i className="bi bi-exclamation-lg text-danger" aria-hidden="true" />
          The Fields With <strong className="text-danger">*</strong>
          Must Required Or Fill.
        </p>
      )}
      <form
        className="row g-3"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <table className="table-borderless table-striped add-expense-table">
          {formDataIndex === 0 && (
            <thead>
              <tr>
                <th scope="col" className="no_sort d-none">
                  Branch Name
                </th>
                <th scope="col" className="no_sort">
                  Category
                </th>
                <th scope="col" className="no_sort">
                  Subcategory
                </th>
                <th scope="col" className="no_sort">
                  Creditor Name
                </th>
                <th scope="col" className="no_sort">
                  Creditor Contact
                </th>
                <th scope="col" className="no_sort">
                  Amount
                </th>
                <th scope="col" className="no_sort">
                  Description
                </th>
                <th scope="col" className="no_sort">
                  Due Date
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <td className="d-none">
                {myBranches ? (
                  <select
                    className="form-select"
                    name="branch_id"
                    disabled={branch_id ? true : false}
                    value={values.branch_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(formDataIndex, e.target.value, "branch_id");
                    }}
                    onBlur={handleBlur}
                  >
                    {myBranches.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.branch_id && touched.branch_id ? (
                  <p className="text-danger">{errors.branch_id}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["branch_id"] &&
                      liabilityError["branch_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {catgries ? (
                  <select
                    className="form-select"
                    name="category_id"
                    value={values.category_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "category_id"
                      );

                      getSubCategories(e);
                      values.sub_category_id = "";
                      onFormChange(formDataIndex, "", "sub_category_id");
                    }}
                    onBlur={handleBlur}
                  >
                    {catgries.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
                {errors.category_id && touched.category_id ? (
                  <p className="text-danger">{errors.category_id}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["category_id"] &&
                      liabilityError["category_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {subCatgries ? (
                  <select
                    className="form-select"
                    name="sub_category_id"
                    value={values.sub_category_id || ""}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "sub_category_id"
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="-1">Select Option</option>
                    {subCatgries.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select
                    className="form-select"
                    name="sub_category_id"
                    value={values.sub_category_id}
                    onChange={(e) => {
                      handleChange(e);
                      onFormChange(
                        formDataIndex,
                        e.target.value,
                        "sub_category_id"
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option>Select Sub Category</option>
                  </select>
                )}
                {errors.sub_category_id && touched.sub_category_id ? (
                  <p className="text-danger">{errors.sub_category_id}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["sub_category_id"] &&
                      liabilityError["sub_category_id"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="creditor_name"
                  value={values.creditor_name}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "creditor_name"
                    );
                  }}
                  onBlur={handleBlur}
                />
                {errors.creditor_name && touched.creditor_name ? (
                  <p className="text-danger">{errors.creditor_name}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["creditor_name"] &&
                      liabilityError["creditor_name"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="(e.g 92xxxxxxxxxx)"
                  name="creditor_contact"
                  value={values.creditor_contact}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "creditor_contact"
                    );
                  }}
                  onBlur={handleBlur}
                  ref={phone_number}
                />
                {errors.creditor_contact && touched.creditor_contact ? (
                  <p className="text-danger">{errors.creditor_contact}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["creditor_contact"] &&
                      liabilityError["creditor_contact"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  className="form-control"
                  placeholder="Enter Amount"
                  name="amount_owned"
                  min={0}
                  value={values.amount_owned}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "amount_owned");
                  }}
                  onBlur={handleBlur}
                />
                {errors.amount_owned && touched.amount_owned ? (
                  <p className="text-danger">{errors.amount_owned}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["amount_owned"] &&
                      liabilityError["amount_owned"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  value={values.description}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "description");
                  }}
                  onBlur={handleBlur}
                />
                {errors.description && touched.description ? (
                  <p className="text-danger">{errors.description}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["description"] &&
                      liabilityError["description"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                <input
                  type="date"
                  className="form-control"
                  placeholder="11-03-2022"
                  name="maturity_date"
                  value={values.maturity_date}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "maturity_date"
                    );
                  }}
                  onBlur={handleBlur}
                />
                {errors.maturity_date && touched.maturity_date ? (
                  <p className="text-danger">{errors.maturity_date}</p>
                ) : liabilityError ? (
                  <span className="text-danger">
                    {liabilityError["maturity_date"] &&
                      liabilityError["maturity_date"].map((err) => err)}
                  </span>
                ) : null}
              </td>
              <td>
                {formDataIndex > 9 && (
                  <button
                    type="button"
                    onClick={() => {
                      removeForm(idUnit);
                    }}
                    className="remove-form-button"
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Branch Name<span className="text-danger">*</span>
              </label>
              {myBranches ? (
                <select
                  className="form-select"
                  name="branch_id"
                  disabled={branch_id ? true : false}
                  value={values.branch_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "branch_id");
                  }}
                  onBlur={handleBlur}
                >
                  {myBranches.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : null}
              {errors.branch_id && touched.branch_id ? (
                <p className="text-danger">{errors.branch_id}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["branch_id"] &&
                    liabilityError["branch_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Category<span className="text-danger">*</span>
              </label>
              {catgries ? (
                <select
                  className="form-select"
                  name="category_id"
                  value={values.category_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(formDataIndex, e.target.value, "category_id");

                    getSubCategories(e);
                    values.sub_category_id = "";
                    onFormChange(formDataIndex, "", "sub_category_id");
                  }}
                  onBlur={handleBlur}
                >
                  {catgries.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : null}
              {errors.category_id && touched.category_id ? (
                <p className="text-danger">{errors.category_id}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["category_id"] &&
                    liabilityError["category_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Sub-Category<span className="text-danger">*</span>
              </label>
              {subCatgries ? (
                <select
                  className="form-select"
                  name="sub_category_id"
                  value={values.sub_category_id || ""}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "sub_category_id"
                    );
                  }}
                  onBlur={handleBlur}
                >
                   <option value="-1">Select Option</option>
                  {subCatgries.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className="form-select"
                  name="sub_category_id"
                  value={values.sub_category_id}
                  onChange={(e) => {
                    handleChange(e);
                    onFormChange(
                      formDataIndex,
                      e.target.value,
                      "sub_category_id"
                    );
                  }}
                  onBlur={handleBlur}
                >
                  <option>Select Sub Category</option>
                </select>
              )}
              {errors.sub_category_id && touched.sub_category_id ? (
                <p className="text-danger">{errors.sub_category_id}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["sub_category_id"] &&
                    liabilityError["sub_category_id"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Creditor Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="creditor_name"
                value={values.creditor_name}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "creditor_name");
                }}
                onBlur={handleBlur}
              />
              {errors.creditor_name && touched.creditor_name ? (
                <p className="text-danger">{errors.creditor_name}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["creditor_name"] &&
                    liabilityError["creditor_name"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Creditor Contact</label>
              <input
                type="text"
                className="form-control"
                placeholder="(e.g 92xxxxxxxxxx)"
                name="creditor_contact"
                value={values.creditor_contact}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(
                    formDataIndex,
                    e.target.value,
                    "creditor_contact"
                  );
                }}
                onBlur={handleBlur}
                ref={phone_number}
              />
              {errors.creditor_contact && touched.creditor_contact ? (
                <p className="text-danger">{errors.creditor_contact}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["creditor_contact"] &&
                    liabilityError["creditor_contact"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">
                Amount<span className="text-danger">*</span>
              </label>
              <input
                onWheel={(e) => e.target.blur()}
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="amount_owned"
                min={0}
                value={values.amount_owned}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "amount_owned");
                }}
                onBlur={handleBlur}
              />
              {errors.amount_owned && touched.amount_owned ? (
                <p className="text-danger">{errors.amount_owned}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["amount_owned"] &&
                    liabilityError["amount_owned"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="description"
                value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "description");
                }}
                onBlur={handleBlur}
              />
              {errors.description && touched.description ? (
                <p className="text-danger">{errors.description}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["description"] &&
                    liabilityError["description"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-col-1">
            <div className="form-group custom-group">
              <label className="form-label">Due Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="11-03-2022"
                name="maturity_date"
                value={values.maturity_date}
                onChange={(e) => {
                  handleChange(e);
                  onFormChange(formDataIndex, e.target.value, "maturity_date");
                }}
                onBlur={handleBlur}
              />
              {errors.maturity_date && touched.maturity_date ? (
                <p className="text-danger">{errors.maturity_date}</p>
              ) : liabilityError ? (
                <span className="text-danger">
                  {liabilityError["maturity_date"] &&
                    liabilityError["maturity_date"].map((err) => err)}
                </span>
              ) : null}
            </div>
          </div> */}
        {/* <div className="custom-del-col-1">
            {formDataIndex !== 0 && (
                <button
                type="button"
                onClick={() => {
                  removeForm(idUnit);
                }}
                className="mt-4 remove-form-button"
              >
               <i className="bi bi-trash-fill"></i>
                
              </button>
            )}
          </div> */}
      </form>
    </>
  );
}
