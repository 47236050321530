/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import Pagination from "react-js-pagination";
import CurrencyFormat from "react-currency-format";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";

export default function ViewDepositBalance() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");

  const getDeposits = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getClientsDeposits(
        branch_id,
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/client-balance/export?branch_id=" +
            branch_id
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_id: values.branch_id,
            branch_name: values.branch_name,
            client_name: values.client_name,
            available_balance: values.available_balance,
            client_id: values.client_id,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getDeposits(currentPage, limit);
  }, []);

  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.ClientsDepositSearch(
          search_name,
          branch_id,
          limit,
          1
        );
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              branch_id: values.branch_id,
              branch_name: values.branch_name,
              client_name: values.client_name,
              available_balance: values.available_balance,
              client_id: values.client_id,
            });
            return null;
          });
          setMyData([...results]);
          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("clients_available_balance.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Clients Available Balance"
          id="report-POS"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("available_balance")}
                className={getClassNamesFor("available_balance")}
              >
                Available Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>
                    <Link to={"/client-home/" + el.client_id}>
                      {el.client_name}
                    </Link>
                  </td>
                  <td>
                    <CurrencyFormat
                      value={el.available_balance}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
            <tr className="">
              <td colSpan="3"></td>
              <td colSpan="1" className="expenses-num">
                Total Available Balance{" "}
                <CurrencyFormat
                  value={myData.reduce(
                    (a, v) => (a = a + v.available_balance),
                    0
                  )}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getDeposits(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Clients Available Balance</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2 col-lg-2 ms-2 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Search By Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) => {
                        //   addPkgHandleChange(e);
                        searchRecord(e);
                      }}
                      // onBlur={addPkgHandleBlur}
                    />
                  </div>
                </div>
                <div className="col-5"></div>
                <div className="col-md-3 text-end mt-3 ms-auto">
                  <a
                    href={exportLink}
                    rel="noreferrer"
                    className="btn btn-primary mx-2 file-download float-end mb-3"
                  >
                    <i className="bi bi-download"></i> Excel
                  </a>
                  <button
                    className="btn btn-primary mx-2 file-download float-end mb-3"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
                <div className="col-md-1 col-lg-1 mt-3 ">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getDeposits("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
