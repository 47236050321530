import React, { useEffect, useState, useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { staffFines } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { NoteState } from "../../context/notes/NoteState";
import { viewFines } from "../schemas";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import $ from "jquery";

const ManageStaffFines = () => {
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fineTime, setFineTime] = useState("");
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState("");
  const month = date.getMonth();
  const monthString = (parseInt(month) + 1).toString().padStart(2, "0");
  var CurrencyFormat = require("react-currency-format");
  const today = new Date();
  const year = today.getFullYear();
  let sDate = moment().startOf("month").format("YYYY-MM-DD");
  // update
  const [fname, setFname] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [recordDate, setRecordDate] = useState("");
  const [recordId, setRecordId] = useState("");
  const [branch, setBranch] = useState("");

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const getUser = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getStaffFinesInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          setFname(values.user_id);
          setAmount(values.amount);
          setReason(values.reason);
          setRecordDate(values.occurrence_date);
          setBranch(values.branch_id);
          setRecordId(values.id);
          return null;
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const staffNames = async () => {
    // setCurrentPage();
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Names", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getUsers = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    setLoading(true);
    try {
      const responce = await UserServices.getStaffFines(
        branch_id,
        "1",
        newLimit,
        pageNo,
        values
      );
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/users-finance/ExportUsersFinance?category=Fine&start_date=" +
            values.startdate +
            "&end_date=" +
            values.enddate +
            "&branch_id=" +
            branch_id
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            user_id: values.user_id,
            user_fname: values.user_fname,
            user_lname: values.user_lname,
            amount: values.amount,
            reason: values.reason,
            occurrence_date: values.occurrence_date,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setExportLink();
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setExportLink();
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    staffNames();
    getUsers(currentPage, limit);
  }, []);
  const deleteRecord = async (stafffine_id) => {
    try {
      const responce = await UserServices.deleteStaffFines(stafffine_id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (stafffine_id) => {
    deleteRecord(stafffine_id);
  };
  const changeFineTime = (e) => {
    let monthCode = e;
    setFineTime(monthCode);
    const getFineTimeData = async () => {
      setLoading(true);
      if (!monthCode) {
        setLoading(false);
        return;
      }
      const month = parseInt(monthCode, 10); // Parse the month code as an integer

      // Create a new Date object for the next month
      const endDate = new Date(year, month, 0);

      // Extract the day from the end date
      const endDay = endDate.getDate();

      setValues({
        startdate: year + "-" + monthCode + "-01",
        enddate: year + "-" + monthCode + "-" + endDay,
      });
      try {
        const responce = await UserServices.getStaffFinesDataByTime(
          branch_id,
          "1",
          limit,
          1,
          year + "-" + monthCode + "-01",
          year + "-" + monthCode + "-" + endDay
        );
        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/users-finance/ExportUsersFinance?&start_date=" +
              year +
              "-" +
              monthCode +
              "-01&end_date=" +
              year +
              "-" +
              monthCode +
              "-31&category=Fine&branch_id=" +
              branch_id
          );

          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              user_fname: values.user_fname,
              user_lname: values.user_lname,
              amount: values.amount,
              reason: values.reason,
              occurrence_date: values.occurrence_date,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setLimit(responce.data.data.per_page);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
          // setError("No record  found");
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getFineTimeData();
  };
  const findFine = async (values) => {
    if (Date.parse(values.startdate) <= Date.parse(values.enddate)) {
      if (Date.parse(values.enddate) >= Date.parse(values.startdate)) {
        setLoading(true);
        try {
          const response = await UserServices.getStaffFinesDataByYear(
            branch_id,
            "1",
            limit,
            1,
            values
          );

          let myDataarr;

          if (response.status === 200) {
            setExportLink(
              process.env.REACT_APP_API_Link +
                "/users-finance/ExportUsersFinance?&start_date=" +
                values.startdate +
                "&end_date=" +
                values.enddate +
                "&category=Fine&branch_id=" +
                branch_id
            );
            myDataarr = response.data.data.data;
            const res = myDataarr;
            const results = [];

            res.map((values) => {
              results.push({
                id: values.id,
                user_fname: values.user_fname,
                user_lname: values.user_lname,
                amount: values.amount,
                reason: values.reason,
                occurrence_date: values.occurrence_date,
              });
              return null;
            });

            setMyData([...results]);
            setTotalResults(response.data.totalRecord);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setExportLink();
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Start date should be less then end date!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const addFine = async (addFineValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addStaffFines(
        branch_id,
        addFineValues,
        "Fine"
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getUsers(currentPage, limit);
      }
    } catch (err) {
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const initialValues = {
    enddate: eDate,
    startdate: sDate,
  };
  //.......................................................................................................................

  const addFineinitialValues = {
    Occurrence_date: eDate,
    user_id: "",
    amount: "",
    reason: "N/A",
  };

  //..........................................................................................................

  const {
    values: addFineValues,
    errors: addFineErrors,
    touched: addFineTouched,
    handleBlur: addFineHandleBlur,
    handleChange: addFineHandleChange,
    handleSubmit: addFineHandleSubmit,
  } = useFormik({
    initialValues: addFineinitialValues,
    validationSchema: staffFines,
    onSubmit: (addFineValues, action) => {
      addFine(addFineValues, action);
    },
  });

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: viewFines,
    onSubmit: (values) => {
      findFine(values);
    },
  });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Fine_Report.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {/* {totalResults > limit ? ( */}

        {/* ) : null} */}
        <div className="table-responsive mt-3">
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-POS"
            data-caption="Fine Report"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("user_fname")}
                  className={getClassNamesFor("user_fname")}
                >
                  Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("amount")}
                  className={getClassNamesFor("amount")}
                >
                  Amount
                </th>

                <th scope="col" className="no_sort">
                  Reason
                </th>

                <th scope="col" className="no_sort">
                  Date
                </th>

                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td>
                      <Link to={"/staff-home/" + el.user_id}>
                        {el.user_fname} {el.user_lname}
                      </Link>
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"2s"}
                      />
                    </td>
                    <td>{el.reason}</td>
                    <td>{moment(el.occurrence_date).format("DD-MM-YYYY")}</td>

                    <td>
                      <button
                        onClick={() => {
                          getUser(el.id);
                        }}
                        className="text-warning border-0 bg-transparent"
                      >
                        <i className="bi bi-pencil-fill" />
                      </button>
                      <button
                        type="button"
                        className="text-danger custom-button-image"
                        onClick={() => delFine(el.id)}
                      >
                        <i className="bi bi-trash-fill" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const UpdateFine = async (updateValues) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateStaffFines(
        updateValues,
        recordId,
        "Fine"
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getUsers(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    Occurrence_date: recordDate,
    user_id: fname,
    amount: amount,
    reason: reason,
    branch_id: branch,
  };

  //....................................................................................................................................
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: staffFines,
    onSubmit: (updateValues) => {
      UpdateFine(updateValues);
    },
  });
  return (
    <>
      <div className="wrapper">
        {/* <ToastContainer /> */}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Manage Staff Fines</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>{" "}
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3 trainee-add"
                  onSubmit={addFineHandleSubmit}
                >
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Fine Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Start date"
                        name="Occurrence_date"
                        value={addFineValues.Occurrence_date}
                        onChange={addFineHandleChange}
                        onBlur={addFineHandleBlur}
                      />
                      {addFineErrors.Occurrence_date &&
                      addFineTouched.Occurrence_date ? (
                        <p className="text-danger">
                          {addFineErrors.Occurrence_date}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      {names ? (
                        <select
                          className="form-select"
                          name="user_id"
                          value={addFineValues.user_id}
                          onChange={(e) => {
                            addFineHandleChange(e);
                          }}
                          onBlur={addFineHandleBlur}
                        >
                          {names.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addFineErrors.user_id && addFineTouched.user_id ? (
                        <p className="text-danger">{addFineErrors.user_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Amount "
                        name="amount"
                        min={0}
                        value={addFineValues.amount}
                        onChange={addFineHandleChange}
                        onBlur={addFineHandleBlur}
                      />
                      {addFineErrors.amount && addFineTouched.amount ? (
                        <p className="text-danger">{addFineErrors.amount}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Reason"
                        name="reason"
                        value={addFineValues.reason}
                        onChange={addFineHandleChange}
                        onBlur={addFineHandleBlur}
                      />
                      {addFineErrors.reason && addFineTouched.reason ? (
                        <p className="text-danger">{addFineErrors.reason}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add Fine
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Fine Report</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row d-flex">
                    <div className="col-md-2 mt-3">
                      <label className="form-label">Filter By Month</label>
                      <select
                        className="form-select"
                        onChange={(e) => changeFineTime(e.target.value)}
                        defaultValue={fineTime}
                      >
                        <option value="">Select Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-2 mt-3">
                      <label className="form-label">From</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="date"
                        name="startdate"
                        value={values.startdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.startdate && touched.startdate ? (
                        <p className="text-danger">{errors.startdate}</p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-2 mt-3">
                      <label className="form-label">To</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="date"
                        name="enddate"
                        value={values.enddate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.enddate && touched.enddate ? (
                        <p className="text-danger">{errors.enddate}</p>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-1">
                      <button
                        type="submit"
                        className="btn btn-primary d-inline-block btn-sm mt-5"
                        onChange={() => findFine(values)}
                      >
                        Generate
                      </button>
                    </div>
                    <div className="col-md-1 mt-5">
                      <div className="input-group">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => getUsers("1", e.target.value)}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>

                    <div className=" col-md-4 ms-auto mt-5 text-end">
                      <button
                        className="btn btn-primary mx-2 file-download"
                        onClick={() => {
                          generatePdf(document.getElementById("report-POS"));
                        }}
                      >
                        <i className="bi bi-file-earmark-pdf"></i> PDF
                      </button>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        // target="_blank"
                        className="btn btn-primary mx-2 file-download"
                      >
                        <i className="bi bi-download" /> Excel
                      </a>
                    </div>
                  </div>
                </form>
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
            <div className="d-flex">
              <div className="ms-auto d-flex">
                <div className="mt-1">
                  <h5>Grand Total:</h5>
                </div>
                <div className="ms-2  fs-5">
                  <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.amount), 0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    thousandSpacing={"2s"}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <div
          className="modal fade"
          id="exampleVerticallycenteredModal"
          tabIndex={-1}
          style={{ display: "none" }}
          aria-hidden="true"
        ></div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Staff Fine
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="row g-3 trainee-add"
                onSubmit={updateHandleSubmit}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Fine Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="15-02-2022"
                      name="Occurrence_date"
                      value={updateValues.Occurrence_date}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.Occurrence_date &&
                    updateTouched.Occurrence_date ? (
                      <p className="text-danger">
                        {updateErrors.Occurrence_date}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={updateValues.user_id}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.user_id && updateTouched.user_id ? (
                      <p className="text-danger">{updateErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      placeholder="Amount "
                      name="amount"
                      value={updateValues.amount}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.amount && updateTouched.amount ? (
                      <p className="text-danger">{updateErrors.amount}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Reason"
                      name="reason"
                      value={updateValues.reason}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.reason && updateTouched.reason ? (
                      <p className="text-danger">{updateErrors.reason}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update Fine
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ManageStaffFines;
