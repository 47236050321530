import React, { useContext } from "react";
import { useState } from "react";
import MenuButton from "../../MenuButton/MenuButton";
import useSortableData from "../../../hooks/useSortableData";
import jsPDF from "jspdf";
import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { NoteState } from "../../../context/notes/NoteState";

import { nutrinistAssessment } from "../../schemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

export default function EditNutritionAssessments() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [myBranches, setMyBranches] = useState([]);
  const navigate = useNavigate();
  const [name, setName] = useState([]);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  const getRecord = async () => {
    try {
      setLoading(true);
      const response = await UserServices.getNutritionAssessmentsById(
        branch_id,
        id
      );

      await setValues({
        branch_id: response?.data?.data?.branch_id,
        client_name: response?.data?.data?.client_detail?.client_name,
        daily_water_intake: response?.data?.data?.daily_water_intake,
        disliked_foods: response?.data?.data?.disliked_foods,
        favorite_foods: response?.data?.data?.favorite_foods,
        diabetes: response?.data?.data?.diabetes,
        hypertension_cvd: response?.data?.data?.hypertension_cvd,
        polycystic_ovarian_syndrome:
          response?.data?.data?.polycystic_ovarian_syndrome,
        anemia: response?.data?.data?.anemia,
        ibs: response?.data?.data?.ibs,
        h_pylori: response?.data?.data?.h_pylori,
        muscle_pain: response?.data?.data?.muscle_pain,
        take_stress: response?.data?.data?.take_stress,
        activity_level: response?.data?.data?.activity_level,
        allergic_foods: response?.data?.data?.allergic_foods,
        comments: response?.data?.data?.comments,
        any_other_issue: response?.data?.data?.any_other_issue,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getRecord();

    branches();

    nameClients();
  }, []);

  const initialValues = {
    branch_id: branch_id,
    client_name: "",
    daily_water_intake: "",
    disliked_foods: "",
    favorite_foods: "",
    diabetes: "",
    hypertension_cvd: "",
    polycystic_ovarian_syndrome: "",
    anemia: "",
    ibs: "",
    h_pylori: "",
    muscle_pain: "",
    take_stress: "",
    activity_level: "",
    allergic_foods: "",
    comments: "",
    any_other_issue: "",
  };

  const updateAssessments = async (values) => {
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;

    // hiddenInput.value = inputValue;

    for (var i = 0; i < options.length; i++) {
      var option = options[i];

      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }

    let client_id = await document.getElementById("answerInput-hidden").value;
    try {
      setLoading(true);
      await UserServices.updateNutritionAssessments(values, client_id, id);

      setLoading(false);
      toast.success("Updated Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/view-nutrition-assessments");
    } catch (err) {
      setLoading(false);

      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: nutrinistAssessment,
    onSubmit: async (values) => {
      updateAssessments(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Nutrition Assessments{" "}
                <Link
                  to="/view-nutrition-assessments"
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  View Nutrition Assessments
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <form
                className="row g-3"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Client Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Client Name"
                      name="client_name"
                      value={values.client_name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      list="member_id"
                      id="answerInput"
                    />

                    <input
                      type="hidden"
                      name="answer"
                      id="answerInput-hidden"
                    />
                    <datalist id="member_id">
                      {name.map((el, index) => {
                        return (
                          <option
                            key={index}
                            data-value={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                    </datalist>

                    {errors.client_name && touched.client_name ? (
                      <p className="text-danger">{errors.client_name}</p>
                    ) : null}
                  </div>
                </div>

                <di className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Daily Water Intake<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Daily Water Intake"
                      name="daily_water_intake"
                      value={values.daily_water_intake}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.daily_water_intake && touched.daily_water_intake ? (
                      <p className="text-danger">{errors.daily_water_intake}</p>
                    ) : null}
                  </div>
                </di>
                <di className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Food you Don’t Prefer{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Food Don’t Prefer"
                      name="disliked_foods"
                      value={values.disliked_foods}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.disliked_foods && touched.disliked_foods ? (
                      <p className="text-danger">{errors.disliked_foods}</p>
                    ) : null}
                  </div>
                </di>
                <di className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Food Allergies if any{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Food Allergies"
                      name="allergic_foods"
                      value={values.allergic_foods}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.allergic_foods && touched.allergic_foods ? (
                      <p className="text-danger">{errors.allergic_foods}</p>
                    ) : null}
                  </div>
                </di>

                <di className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Foods you Prefer <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Foods Prefer"
                      name="favorite_foods"
                      value={values.favorite_foods}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.favorite_foods && touched.favorite_foods ? (
                      <p className="text-danger">{errors.favorite_foods}</p>
                    ) : null}
                  </div>
                </di>
                <div className="col-12 col-md-12">
                  <h5>
                    <span className="text-danger">P.M.H (if</span> any) only to
                    be filled by the consultant
                  </h5>
                </div>
                <div className="col-12  col-md-6">
                  {errors.diabetes ||
                  errors.hypertension_cvd ||
                  errors.polycystic_ovarian_syndrome ||
                  errors.anemia ||
                  errors.ibs ||
                  errors.h_pylori ? (
                    <p className="text-danger">Please Answer All Statements</p>
                  ) : null}
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Statement</th>
                        <th>Yes</th>
                        <th>No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Diabetes type 1 or 2 </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="smoke"
                              onClick={() => {
                                setFieldValue("diabetes", "Yes");
                              }}
                              checked={values.diabetes === "Yes" ? true : false}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="smoke"
                              onClick={() => {
                                setFieldValue("diabetes", "No");
                              }}
                              checked={values.diabetes === "No" ? true : false}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Hypertension or CVD</td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="health"
                              onClick={() => {
                                setFieldValue("hypertension_cvd", "Yes");
                              }}
                              checked={
                                values.hypertension_cvd === "Yes" ? true : false
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="health"
                              onClick={() => {
                                setFieldValue("hypertension_cvd", "No");
                              }}
                              checked={
                                values.hypertension_cvd === "No" ? true : false
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Polycystic ovarian syndrome</td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="trainer"
                              onClick={() => {
                                setFieldValue(
                                  "polycystic_ovarian_syndrome",
                                  "Yes"
                                );
                              }}
                              checked={
                                values.polycystic_ovarian_syndrome === "Yes"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="trainer"
                              onClick={() => {
                                setFieldValue(
                                  "polycystic_ovarian_syndrome",
                                  "No"
                                );
                              }}
                              checked={
                                values.polycystic_ovarian_syndrome === "No"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Anemia</td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typical"
                              onClick={() => {
                                setFieldValue("anemia", "Yes");
                              }}
                              checked={values.anemia === "Yes" ? true : false}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typical"
                              onClick={() => {
                                setFieldValue("anemia", "No");
                              }}
                              checked={values.anemia === "No" ? true : false}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>IBS</td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="IBS"
                              onClick={() => {
                                setFieldValue("ibs", "Yes");
                              }}
                              checked={values.ibs === "Yes" ? true : false}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="IBS"
                              onClick={() => {
                                setFieldValue("ibs", "No");
                              }}
                              checked={values.ibs === "No" ? true : false}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>H. Pylori </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Pylori"
                              onClick={() => {
                                setFieldValue("h_pylori", "Yes");
                              }}
                              checked={values.h_pylori === "Yes" ? true : false}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Pylori"
                              onClick={() => {
                                setFieldValue("h_pylori", "No");
                              }}
                              checked={values.h_pylori === "No" ? true : false}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12 col-lg-12 mb-2">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Joint mobility issue or muscle pain{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="muscle_pain"
                        value={values.muscle_pain}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.muscle_pain && touched.muscle_pain ? (
                        <p className="text-danger">{errors.muscle_pain}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-12 mb-2">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Any other issue (DISEASE OR SURGERY)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="any_other_issue"
                        value={values.any_other_issue}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.any_other_issue && touched.any_other_issue ? (
                        <p className="text-danger">{errors.any_other_issue}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <di className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label mb-2 d-block fw-bold">
                      Do you take stress (health-related, Job-related,
                      financial, marriage, family related, interpersonal, and
                      spiritual)? <span className="text-danger">*</span>
                    </label>
                    <div className="form-check d-inline-block">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="stress"
                          value="Activity"
                          onClick={() => {
                            setFieldValue("take_stress", "Minimal");
                          }}
                          checked={
                            values.take_stress === "Minimal" ? true : false
                          }
                        />
                        Minimal
                      </label>
                    </div>
                    <div className="form-check d-inline-block ms-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="stress"
                          value="Moderate"
                          onClick={() => {
                            setFieldValue("take_stress", "Moderate");
                          }}
                          checked={
                            values.take_stress === "Moderate" ? true : false
                          }
                        />
                        Moderate
                      </label>
                    </div>
                    <div className="form-check d-inline-block ms-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="stress"
                          value="Unbearable"
                          onClick={() => {
                            setFieldValue("take_stress", "Unbearable");
                          }}
                          checked={
                            values.take_stress === "Unbearable" ? true : false
                          }
                        />
                        Unbearable
                      </label>
                    </div>

                    {errors.take_stress && touched.take_stress ? (
                      <p className="text-danger">{errors.take_stress}</p>
                    ) : null}
                  </div>
                  <br />
                  <div className="form-group custom-group">
                    <label className="form-label mb-2 d-block fw-bold">
                      Activity Level
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-check d-inline-block me-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Activity"
                          value="Office"
                          onClick={() => {
                            setFieldValue(
                              "activity_level",
                              "Office Job (Sedentary)"
                            );
                          }}
                          checked={
                            values.activity_level === "Office Job (Sedentary)"
                              ? true
                              : false
                          }
                        />
                        Office Job (Sedentary)
                      </label>
                    </div>
                    <div className="form-check d-inline-block me-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Activity"
                          value="Light"
                          onClick={() => {
                            setFieldValue("activity_level", " Light exercise");
                          }}
                          checked={
                            values.activity_level === "Light exercise"
                              ? true
                              : false
                          }
                        />
                        Light exercise
                      </label>
                    </div>
                    <div className="form-check d-inline-block me-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Activity"
                          value="Moderate"
                          onClick={() => {
                            setFieldValue(
                              "activity_level",
                              "Moderate exercise"
                            );
                          }}
                          checked={
                            values.activity_level === "Moderate exercise"
                              ? true
                              : false
                          }
                        />
                        Moderate exercise
                      </label>
                    </div>
                    <div className="form-check d-inline-block me-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Activity"
                          value="Heavy"
                          onClick={() => {
                            setFieldValue("activity_level", "Heavy exercise");
                          }}
                          checked={
                            values.activity_level === "Heavy exercise"
                              ? true
                              : false
                          }
                        />
                        Heavy exercise
                      </label>
                    </div>
                    <div className="form-check d-inline-block me-4">
                      <label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Activity"
                          value="Athlete"
                          onClick={() => {
                            setFieldValue("activity_level", "Athlete");
                          }}
                          checked={
                            values.activity_level === "Athlete" ? true : false
                          }
                        />
                        Athlete
                      </label>
                    </div>

                    {errors.activity_level && touched.activity_level ? (
                      <p className="text-danger">{errors.activity_level}</p>
                    ) : null}
                  </div>

                  <br />
                  <div className="form-group custom-group">
                    <label className="form-label">Comments</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="comments"
                      value={values.comments}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.comments && touched.comments ? (
                      <p className="text-danger">{errors.comments}</p>
                    ) : null}
                  </div>
                </di>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
