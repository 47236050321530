import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addBranch } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import { useMask } from "react-mask-field";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const ManageBranches = () => {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [codeError, setCodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const getBrnch = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getActiveBranches();
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];
        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            phone: values.phone,
            country: values.country,
            city: values.city,
            code: values.code,
            address: values.address,
            ntn: values.ntn,
            strn: values.strn,
          });
          return null;
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getBrnch();
  }, []);
  const addbranch = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addBranches(values);
      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
            action.resetForm();
            getBrnch();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const verifyCode = async (e) => {
    var check_Code = e.target.value;
    try {
      const response = await UserServices.checkBranchCode(check_Code);
      if (response.status === 200) {
        setCodeError("");
      }
    } catch (err) {
      if (err.response.status === 404) {
        setCodeError("Code already exists");
      }
    }
  };
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getBranchesFiltration(
          "1",
          limit,
          pageNo
        );
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              phone: values.phone,
              country: values.country,
              city: values.city,
              code: values.code,
              address: values.address,
              ntn: values.ntn,
              strn: values.strn,
            });
            return null;
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getBranchesFiltration(
          "1",
          newLimit,
          1
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              phone: values.phone,
              country: values.country,
              city: values.city,
              code: values.code,
              address: values.address,
              ntn: values.ntn,
              strn: values.strn,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const deleteRecord = async (brnch_Id) => {
    try {
      const responce = await UserServices.deleteBrnch(brnch_Id);
      if (responce.status === 204) {
        Swal.fire({
          title: "Deleted !",
          text: "Record deleted successfully.",
          icon: "success",
          confirmButtonColor: "#ed1c24",
        });
        getBrnch();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delBranch = (brnch_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(brnch_Id);
      }
    });
  };
  const initialValues = {
    name: "",
    code: "",
    city: "",
    phone: "",
    street: "",
    ntn: "",
    country: "",
    strn:"",
  };
  //.............................................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addBranch,
      onSubmit: (values, action) => {
        addbranch(values, action);
      },
    });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Branches.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="All Branches"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
               Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
               Branch Code
              </th>

              <th scope="col" className="no_sort">
                Phone
              </th>

              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Addrerss
              </th>
              <th
                scope="col"
                onClick={() => requestSort("ntn")}
                className={getClassNamesFor("ntn")}
              >
                NTN
              </th>
              <th
                scope="col"
                onClick={() => requestSort("strn")}
                className={getClassNamesFor("strn")}
              >
                STRN
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{el.code}</td>
                  <td>{el.phone}</td>
                  <td>{el.city}</td>
                  <td>{el.address}</td>
                  <td>{el.ntn}</td>
                  <td>{el.strn}</td>
                  <td>
                    {el.id === branch_id ? (
                      <>
                        <Link
                          className="updt-btn"
                          to={"/update-branches/" + el.id}
                        >
                         <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </Link>
                        <button
                      type="button"
                      onClick={() => delBranch(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i class="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="updt-btn"
                          disabled={true}
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </button>
                        <button
                      type="button"
                      onClick={() => delBranch(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i class="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Add New Branch</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Code<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Branch Code"
                        name="code"
                        value={values.code}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCode(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {codeError ? (
                        <div className="errorMsg text-danger">{codeError}</div>
                      ) : null}
                      {errors.code && touched.code ? (
                        <p className="text-danger">{errors.code}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch City"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.city && touched.city ? (
                        <p className="text-danger">{errors.city}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Branch Address"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.street && touched.street ? (
                        <p className="text-danger">{errors.street}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">NTN</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Add NTN"
                        name="ntn"
                        value={values.ntn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.ntn && touched.ntn ? (
                        <p className="text-danger">{errors.ntn}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">STRN</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Add STRN"
                        name="strn"
                        value={values.strn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.strn && touched.strn ? (
                        <p className="text-danger">{errors.strn}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Phone"
                        name="phone"
                        value={values.phone}
                        ref={phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p className="text-danger">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mt-4"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">All Branches</h5>
              </div>
              <div className="card-body">
                      <div className="row mt-2 p-2">
                        <div className="col-md-1 col-lg-1 mb-2 ms-2">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              changeLimit(e.target.value);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                        <div className=" col-md-2 ms-auto text-end">
                          <button
                            className="btn btn-primary mx-2 file-download"
                            onClick={() => {
                              generatePdf(
                                document.getElementById("report-POS")
                              );
                            }}
                          >
                            <i className="bi bi-file-earmark-pdf"></i> PDF
                          </button>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="table-responsive ">
                          <Table data={myData} rowsPerPage={limit} />
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ManageBranches;
