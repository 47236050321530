import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addclient } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import Webcam from "react-webcam";
import { MaskField, useMask } from "react-mask-field";
import { useContext } from "react"; 
import { NoteState } from "../../context/notes/NoteState";
import Swal from "sweetalert2";

export default function AddClients() {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [picture, setPicture] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cardError, setCardError] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [file, setFile] = useState("");
  const [regNo, setRegNo] = useState("N/A");

  const navigate = useNavigate();
  const formData = new FormData();
  const videoConstraints = {
    width: 450,
    height: 400,
    facingMode: "user",
  };

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });

  const initialValues = {
    name: "",
    last_name: "",
    email: "",
    cnic: "",
    passport: "",
    license: "",
    phone: "",
    select_gender: "",
    birthday: "",
    country: "Pakistan",
    city: "",
    club_id: "",
    postal_code: "",
    address: "",
    branch_id: branch_id,
    card_number: "",
    select_category: "member",
    memType: "",
    membFee: "",
    discount_type: "Percentage",
    discount_amount: "",
    pay_after_dis: "",
    pay_met: "39",
    payment_rec: "",
    payment_pend: "",
    split_pay: "39",
    payment_split: "",
    payment_split_pend: "",
    image: [],
    uniques_entry_by: "Cnic",
  };

  const getRegNo = async (br_Id) => {
    try {
      const responce = await UserServices.dynamicRegNo(br_Id);

      setRegNo(responce.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyBranches([{ name: "Select Branch", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };
    branches();
    if (branch_id) {
      getRegNo(branch_id);
    } else {
    }
  }, []);

  const verifyPhone = async (e) => {
    var check_Phone = e.target.value;
    const phNum = check_Phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    try {
      const response = await UserServices.addCheckPhone(phNum);
      if (response.status === 200) {
        setPhoneError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setPhoneError("phone number already exists");
      } else if (err.response?.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const verifyCard = async (e) => {
    var check_Card = e.target.value;
    try {
      const response = await UserServices.checkCard(check_Card);
      if (response.status === 200) {
        setCardError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCardError("Card number already exists");
      }
    }
  };
  const verifyCnic = async (e) => {
    var check_Cnic = e.target.value;
    try {
      const response = await UserServices.addCheckCnic(check_Cnic);
      if (response.status === 200) {
        setCnicError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCnicError("CNIC number already exists");
      }
    }
  };
  const handleUpload = async (e) => {
    setFile(e.target.files[0]);
    values.image = e.target.files[0];
  };

  const addMember = async (values, action) => {
    if (phoneError === "" && cardError === "" && cnicError === "") {
      if (file !== "" && picture === "") {
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("image_upload_from", "gallery");
      } else if (picture !== "") {
        formData.append("file", picture);
        formData.append("image_upload_from", "webcam");
      }

      if (values.uniques_entry_by === "Cnic") {
        formData.append("identification_number", values.cnic);
      } else if (values.uniques_entry_by === "Passport Number") {
        formData.append("identification_number", values.passport);
      } else if (values.uniques_entry_by === "Driving License Number") {
        formData.append("identification_number", values.license);
      }
      formData.append("identification_type", values.uniques_entry_by);
      const phNum = values.phone
        .replace(/-/g, "")
        .replace(/[\])}[{(]/g, "")
        .replace(/ /g, "");

      formData.append("branch_id", values.branch_id);
      formData.append("first_name", values.name);
      formData.append("last_name", values.last_name);
      formData.append("phone", phNum);
      formData.append("gender", values.select_gender);
      formData.append("email", values.email);
      // formData.append("membership_id", values.memType);
      // formData.append("fee", membershipFee);
      formData.append("type", values.select_category);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("club_id", values.club_id);
      formData.append("country", values.country);
      formData.append("postal_code", values.postal_code);
      formData.append("birthday", values.birthday);
      formData.append("card_number", values.card_number);

      try {
        setLoading(true);

        const response = await UserServices.addClients(formData);
        if (response.status === 201) {
          setLoading(false);
          let data = response.data.id;
          // setSetId(data);
          toast.success("Member Add Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          setPicture("");
          setFile("");
          if (submitType === "save_add") {
            navigate("/view-clients");
          } else {
            navigate("/sell-package/" + data + "/new");
          }

          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 1000);
        }
      } catch (err) {
        setLoading(false);

        if (err.response.status === 422) {
        } else if (err.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 409) {
        } else {
        }
      }
    }
  };

  const webcamRef = React.useRef(null);
  const capture = () => {
    setPicture("");
    const pictureSrc = webcamRef.current.getScreenshot();

    setPicture(pictureSrc);
  };

  // Validation ...........................................................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addclient,
      onSubmit: (values, action) => {
        addMember(values, action);
      },
    });

  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  Add Clients
                  <Link
                    to="/view-clients"
                    className="btn custom-btn-danger d-inline-block float-end btn-sm"
                  >
                   View Clients
                  </Link>
                </h5>
              </div>
              <form className="" onSubmit={handleSubmit}>
                <div className="card-body">
                  <p>
                    <i
                      className="bi bi-exclamation-lg text-danger"
                      aria-hidden="true"
                    />
                    The Fields With <strong className="text-danger">*</strong>
                    Must Required Or Fill.
                  </p>
                  <div className="add-in-sch mb-3">
                    <label className="form-label fw-bold m-0">
                      Membership No:
                      <span>{regNo}</span>
                    </label>
                  </div>
                  <div className="row g-3">
                    {/* <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Registration Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={regNo}
                          disabled={true}
                        />
                      </div>
                    </div> */}
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          autoComplete="off"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p className="text-danger">{errors.last_name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Select{" "}
                          {values.uniques_entry_by === "Cnic"
                            ? "CNIC"
                            : values.uniques_entry_by}
                        </label>
                        <select
                          className="form-select"
                          name="uniques_entry_by"
                          value={values.uniques_entry_by}
                          onChange={(e) => {
                            handleChange(e);
                            values.cnic = "";
                            values.passport = "";
                            values.license = "";
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="Cnic">CNIC</option>
                          <option value="Passport Number">
                            Passport Number
                          </option>
                          <option value="Driving License Number">
                            Driving License Number
                          </option>
                        </select>
                        {errors.uniques_entry_by && touched.uniques_entry_by ? (
                          <p className="text-danger">
                            {errors.uniques_entry_by}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {values.uniques_entry_by === "Cnic" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            CNIC 
                          </label>

                          <MaskField
                            className="form-control cnic-mask"
                            placeholder="xxxxx-xxxxxxx-x"
                            name="cnic"
                            value={values.cnic}
                            onChange={(e) => {
                              handleChange(e);
                              verifyCnic(e);
                            }}
                            onBlur={handleBlur}
                            mask="_____-_______-_-"
                            replacement={{ _: /\d/ }}
                          />

                          {cnicError ? (
                            <div className="errorMsg text-danger">
                              {cnicError}
                            </div>
                          ) : null}
                          {errors.cnic && touched.cnic ? (
                            <p className="text-danger">{errors.cnic}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : values.uniques_entry_by === "Passport Number" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Passport Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Passport Number"
                            name="passport"
                            value={values.passport}
                            onChange={(e) => {
                              handleChange(e);
                              verifyCnic(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {cnicError ? (
                            <div className="errorMsg text-danger">
                              Passport number already exists
                            </div>
                          ) : null}

                          {errors.passport && touched.passport ? (
                            <p className="text-danger">{errors.passport}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : values.uniques_entry_by === "Driving License Number" ? (
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Driving License Number{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <MaskField
                            className="form-control"
                            placeholder="xx-xx-xxxx"
                            name="license"
                            value={values.license}
                            onChange={(e) => {
                              handleChange(e);
                              verifyCnic(e);
                            }}
                            onBlur={handleBlur}
                            mask="__-__-____-"
                            replacement={{ _: /^[A-Za-z0-9]*$/ }}
                          />
                          {cnicError ? (
                            <div className="errorMsg text-danger">
                              Driving license number already exists
                            </div>
                          ) : null}
                          {errors.license && touched.license ? (
                            <p className="text-danger">{errors.license}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Cell Number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="(e.g 92xxxxxxxxxx"
                          name="phone"
                          value={values.phone}
                          onChange={(e) => {
                            handleChange(e);
                            verifyPhone(e);
                          }}
                          onBlur={handleBlur}
                          ref={phone_number}
                        />
                        {phoneError ? (
                          <div className="errorMsg text-danger">
                            {phoneError}
                          </div>
                        ) : null}
                        {errors.phone && touched.phone ? (
                          <p className="text-danger">{errors.phone}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="select_gender"
                          value={values.select_gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Others</option>
                        </select>
                        {errors.select_gender && touched.select_gender ? (
                          <p className="text-danger">{errors.select_gender}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">DOB</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Birthday"
                          name="birthday"
                          value={values.birthday}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.birthday && touched.birthday ? (
                          <p className="text-danger">{errors.birthday}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Country</label>
                        <select
                          className="form-select"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Country</option>
                          <option>Pakistan</option>
                        </select>
                        {errors.country && touched.country ? (
                          <p className="text-danger">{errors.country}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Club ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Club ID"
                          name="club_id"
                          value={values.club_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.club_id && touched.club_id ? (
                          <p className="text-danger">{errors.club_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter City Name"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city ? (
                          <p className="text-danger">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Postal Code"
                          name="postal_code"
                          value={values.postal_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.postal_code && touched.postal_code ? (
                          <p className="text-danger">{errors.postal_code}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address && touched.address ? (
                          <p className="text-danger">{errors.address}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Branch Name<span className="text-danger">*</span>
                        </label>
                        {myBranches ? (
                          <select
                            className="form-select"
                            name="branch_id"
                            // disabled={!!values.branch_id}
                            disabled={branch_id ? true : false}
                            value={values.branch_id || ""}
                            onChange={(e) => {
                              handleChange(e);
                              getRegNo(e.target.value);
                            }}
                            onBlur={handleBlur}
                          >
                            {myBranches.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                        {errors.branch_id && touched.branch_id ? (
                          <p className="text-danger">{errors.branch_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label htmlFor="form-label" className="form-label">
                          Client Card Number
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          name="card_number"
                          value={values.card_number}
                          onChange={(e) => {
                            handleChange(e);
                            verifyCard(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {values.card_number && cardError ? (
                          <div className="errorMsg text-danger">
                            {cardError}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label
                          htmlFor="formFileMultiple"
                          className="form-label w-100"
                        >
                          Upload Image{" "}
                          <span
                            className={`text-danger cursor-pointer ${
                              picture === "" ? null : "img-link"
                            }`}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            Capture with webcam
                            {/* {picture === "" ? "Capture with webcam" : picture} */}
                          </span>
                          {/* {picture !== "" ? (
                            <img src={picture} alt="" className="w-100" />
                          ) : null} */}
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          onClick={(e) => {
                            e.target.value = null;
                            setFile("");
                            setPicture("");
                          }}
                          onChange={handleUpload}
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Category</label>
                        <select
                          className="form-select"
                          name="select_category"
                          value={values.select_category}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Category</option>
                          <option value="member">Member</option>
                          <option value="Guest">Guest</option>
                          <option value="Cafe">Cafe</option>
                        </select>
                        {errors.select_category && touched.select_category ? (
                          <p className="text-danger">
                            {errors.select_category}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 mb-1 me-2 addclient-button"
                    onClick={(e) => {
                      setSubmitType("save_add");
                    }}
                  >
                    Save and Add
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary px-5 mb-1 addclient-button"
                    onClick={(e) => {
                      setSubmitType("save_sell");
                    }}
                  >
                    Save and Sell a package
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Capture Image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={450}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="webcam"
                  />
                ) : (
                  <img src={picture} alt="" />
                )}
              </div>
              <div>
                {picture !== "" ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPicture("");
                      }}
                      className="btn btn-danger custom-butn3 mt-2"
                    >
                      Retake
                    </button>
                    <button
                      className="btn btn-danger custom-butn3 mt-2 ms-2"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger custom-butn3 "
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
