import React, { useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { managerResource } from "../schemas";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import MenuButton from "../MenuButton/MenuButton";
import Swal from "sweetalert2/dist/sweetalert2.js";

const UpdateResource = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [myBranches, setMyBranches] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");

    const { id } = useParams();

    const branches = async () => {
        try {
          const responce = await UserServices.branchName();
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
          });
          setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };
      const getRes = async (id) => {
        setLoading(true);
        try {
          const responce = await UserServices.getResourceInfo(id);
          if (responce.status === 200) {
            let data = responce.data.data.data;
            data.map((values) => {
                setSelectedBranches(values.branch_id);
                setSelectedName(values.name);
                setSelectedType(values.type);
                setSelectedDescription(values.description);
            });
  
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      useEffect(() => {
        branches();
        getRes(id)
      }, []);

      const updateResource = async (values) => {

        setLoading(true);
    
        try {
          const response = await UserServices.updateResource(values, id);
          console.log(response);
          if (response.status === 204) {
            setLoading(false);
            Swal.fire({
              title: "Updated !",
              text: "Record updated successfully.",
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#ed1c24",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/resource-manager");
              }
            });
          }
        } catch (err) {
          if (err.response.status === 422) {
            toast.error("Some data is missing", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
    
            setLoading(false);
          } else {
            toast.error("Some thing went wronge !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
          }
        }
      };
    const initialValues = {
        branch_id:selectedBranches,
        name: selectedName,
        type: selectedType,
        description:selectedDescription,
      };
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
          enableReinitialize: true,
          initialValues: initialValues,
          validationSchema: managerResource,
          onSubmit: (values, action) => {
          updateResource(values, action);
          },
        });
  return (
    <div className="wrapper">
      <ToastContainer />
      <Navbar />
        <Sidebar />
        <main className="page-content customer-contnent">
        <div className="site-main-design">
        <MenuButton />
        <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Update Resource Manager</h5>
              </div>
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      <select
                        type="text"
                        className="form-select"
                        placeholder="Select Type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Category</option>
                        <option value="Department">Department</option>
                        <option value="Designations">Designations</option>
                        <option value="Expense">Expense</option>
                        <option value="PaymentMethod">Payment Method</option>
                        <option value="TrainerCategory">
                          Trainer Category
                        </option>
                        <option value="MembershipType">Membership Type</option>
                      </select>
                      {errors.type && touched.type ? (
                        <p className="text-danger">{errors.type}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <p className="text-danger">{errors.description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mt-4"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
        </div>
        </main>
        <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
    </div>
  )
}

export default UpdateResource
