import {React, useEffect, useRef , useContext} from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { AmountSettings } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import { useState } from "react";
import { NoteState } from "../../context/notes/NoteState";
import moment from "moment";
import { toast } from "react-toastify";
import { viewSettings } from "../schemas";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";
import $ from "jquery";

export default function TaxSettings() {
  const [loading, setLoading] = useState(false);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  date.setDate(futureDate);
  // update
  const [selectedBranches, setSelectedBranches] = useState("");
  const [fee, setFee] = useState("");
  const [type, setType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [packageId, setPackageId] = useState("");

  var clickEvent = $.Event("click");
  var CurrencyFormat = require("react-currency-format");

  const closeButtonRef = useRef(null);
  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getSettingInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setFee(values.fee);
          setType(values.type);
          setSelectedDate(values.date);
          setPackageId(values.id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getSetting = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getTax(  
        branch_id,
        newLimit,
        pageNo);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_id: values.branch_id,
            branch_name: values.branch_name,
            fee: values.fee,
            type: values.type,
            date: values.date,
            status: values.status,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSetting(currentPage, limit);
    branches();
  }, []);
  const makeInActive = async (tax_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactiveSettings(tax_Id);
      if (responce.status === 204) {
        setLoading(false);

        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getSetting(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveTax = (tax_Id) => {
        makeInActive(tax_Id);
  };
  const makeActive = async (tax_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activeSettings(tax_Id);
      if (responce.status === 204) {
        setLoading(false);

        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getSetting(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeTax = (tax_Id) => {
        makeActive(tax_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteSettings(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getSetting(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delTax = (id) => {
        deleteRecord(id);
  };
  const filterSettings = (values) => {
    const getCategoryTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getSettingsFiltration(
          limit,
          1,
          branch_id,
          values
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              branch_id: values.branch_id,
              branch_name: values.branch_name,
              fee: values.fee,
              type: values.type,
              date: values.date,
              status: values.status,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getCategoryTypeData();
  };
  const addSetting = async (viewValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addTax(viewValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getSetting(currentPage, limit);
      
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const viewinitialValues = {
    date: defaultValue,
    type: "",
  };
  const initialValues = {
    branch_id: branch_id,
    fee: "",
    type:"",
    date: dateSelected,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AmountSettings,
      onSubmit: (values, action) => {
        addSetting(values, action);
      },
    });
    const {
      values: viewValues,
      errors: viewErrors,
      touched: viewTouched,
      handleBlur: viewHandleBlur,
      handleChange: viewHandleChange,
      handleSubmit: viewHandleSubmit,
    } = useFormik({
      initialValues: viewinitialValues,
      validationSchema: viewSettings,
      onSubmit: (viewValues, action) => {
        filterSettings(viewValues, action);
      },
    });

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");
      const captionText = table.getAttribute("data-caption");
      doc.text(  captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 },
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });
  
      doc.save("Settings.pdf");
    };
  
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-POS"
            data-caption="Settings"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("branch_id")}
                  className={getClassNamesFor("branch_id")}
                >
                  Branch Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("fee")}
                  className={getClassNamesFor("fee")}
                >
                  Percentage
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("type")}
                  className={getClassNamesFor("type")}
                >
                  Type
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Date
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("status")}
                  className={getClassNamesFor("status")}
                >
                  Status
                </th>
                <th scope="col" className="no_sort">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td>{el.branch_name}</td>
                    <td>
                      <CurrencyFormat
                        value={el.fee}
                        displayType={"text"}
                        thousandSeparator={true}
                        // prefix={"Rs "}
                        suffix={"%"}
                        thousandSpacing={"3"}
                      />
                    </td>
                    <td>{el.type}</td>
                    <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                    <td>{el.status === "0" ? "Inactive" : "Active"}</td>
                    <td>
                    <button
                      onClick={() => {
                        getRecord(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                      {el.status === "0" ? (
                        <button
                          type="button"
                          className="ms-2 active-btn"
                          onClick={() => activeTax(el.id)}
                        >
                          <i className="bi bi-check-circle text-success"></i>{" "}
                          <span className="text-success active-text ms-1">
                            Active
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="ms-2 inactive-btn"
                          onClick={() => inactiveTax(el.id)}
                        >
                          <i className="bi bi-x-circle text-danger"></i>{" "}
                          <span className="text-danger inactive-text ms-1">
                            Inactive
                          </span>
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => delTax(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-trash3 text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              onChange={(e) => {
              getSetting(e, limit);
            }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </>
      );
    };
    const UpdateTax = async (addSettingValues, action) => {
      setLoading(true);
      try {
        const response = await UserServices.UpdateSetting(addSettingValues, packageId);
      
        if (response.status === 204) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          getSetting(currentPage, limit);
          // Trigger the close button click event
          closeButtonRef.current.click();
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
  
          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    };
    const addSettinginitialValues = {
      branch_id: selectedBranches,
      fee: fee,
      type: type,
      date: selectedDate,
    };
  
      const {
        values: addSettingValues,
        errors: addSettingErrors,
        touched: addSettingTouched,
        handleBlur: addSettingHandleBlur,
        handleChange: addSettingHandleChange,
        handleSubmit: addSettingHandleSubmit,
      } = useFormik({
        enableReinitialize: true,
        initialValues: addSettinginitialValues,
        validationSchema: AmountSettings,
        onSubmit: (addSettingValues, action) => {
          UpdateTax(addSettingValues, action);
        },
      });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Settings</h5>
            </div>
            <div className="card-body">
              <form className="row g-3 trainee-add" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Transaction Surcharges
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="type"
                      value={values.type || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="-1">Select Transaction Surcharges</option>
                      <option value="Sales Tax">Sales Tax</option>
                      <option value="CC Fee">CC Fee</option>
                      <option value="Cafe Staff Discount">Cafe Staff Discount</option>
                    </select>
                    {errors.type && touched.type ? (
                      <p className="text-danger">{errors.type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Percentage <span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Percentage"
                      name="fee"
                      value={values.fee}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.fee && touched.fee ? (
                      <p className="text-danger">{errors.fee}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
              <div className="form-group custom-group">
                <label className="form-label">Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Start date"
                  name="date"
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.date && touched.date ? (
                  <p className="text-danger">{errors.date}</p>
                ) : null}
              </div>
            </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
        <div className="card-header py-3">
          <h5 className="mb-0 font-20">View Settings</h5>
        </div>
        <div className="card-body">
          <div className="row d-flex">
            <form className="row mt-3 mb-2" onSubmit={viewHandleSubmit}>
              <div className="col-md-2 col-lg-2 ms-3">
                <label className="form-label">
                  Filter By Category<span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  name="type"
                  value={viewValues.type || ""}
                  onChange={(e) => {
                    viewHandleChange(e);
                  }}
                  onBlur={viewHandleBlur}
                >
                  <option value="-1">Select Transaction Surcharges</option>
                  <option value="tax">Sales Tax</option>
                  <option value="ccfee">CC Fee</option>
                </select>
                {viewErrors.type && viewTouched.type ? (
                  <p className="text-danger">{viewErrors.type}</p>
                ) : null}
              </div>
              <div className="col-md-3">
                <label className="form-label">To</label>
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="date"
                    name="date"
                    value={viewValues.date}
                    onChange={viewHandleChange}
                    onBlur={viewHandleBlur}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onChange={() => filterSettings(viewValues)}
                  >
                    Generate
                  </button>
                </div>

                {viewErrors.date && viewTouched.date ? (
                  <p className="text-danger">{viewErrors.date}</p>
                ) : null}
              </div>
              <div className="col-md-1 col-lg-1 ms-auto me-3 mt-4">
                <div className="input-group">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => getSetting("1", e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <div className=" col-md-1 text-end mt-4">
                <button
                  className="btn btn-primary mx-2 file-download"
                  onClick={() => {
                    generatePdf(document.getElementById("report-POS"));
                  }}
                >
                  <i className="bi bi-file-earmark-pdf"></i> PDF
                </button>
              </div>
            </form>
          </div>
          <div className="table-responsive ">
            <Table data={myData} rowsPerPage={limit} />
          </div>
        </div>
      </div>
        </div>
      </main>
       {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Settings
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
            <form className="row g-3 trainee-add" onSubmit={addSettingHandleSubmit}>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addSettingValues.branch_id || ""}
                          onChange={(e) => {
                            addSettingHandleChange(e);
                          }}
                          onBlur={addSettingHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addSettingErrors.branch_id && addSettingTouched.branch_id ? (
                        <p className="text-danger">{addSettingErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Transaction Surcharges
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        value={addSettingValues.type || ""}
                        onChange={(e) => {
                          addSettingHandleChange(e);
                        }}
                        onBlur={addSettingHandleBlur}
                      >
                        <option value="-1">
                          Select Transaction Surcharges
                        </option>
                        <option value="Sales Tax">Sales Tax</option>
                        <option value="CC Fee">CC Fee</option>
                      </select>
                      {addSettingErrors.type && addSettingTouched.type ? (
                        <p className="text-danger">{addSettingErrors.type}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className="form-control"
                        placeholder="Amount"
                        name="fee"
                        value={addSettingValues.fee}
                        onChange={(e) => {
                          addSettingHandleChange(e);
                        }}
                        onBlur={addSettingHandleBlur}
                      />
                      {addSettingErrors.fee && addSettingTouched.fee ? (
                        <p className="text-danger">{addSettingErrors.fee}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Start date"
                        name="date"
                        value={addSettingValues.date}
                        onChange={addSettingHandleChange}
                        onBlur={addSettingHandleBlur}
                      />

                      {addSettingErrors.date && addSettingTouched.date ? (
                        <p className="text-danger">{addSettingErrors.date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Update
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
