import React, { useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
// import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import "./Card.css";
import moment from "moment";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";

export default function VostroCard() {
  const [date, setDate] = useState("");
  const [memberId, setMemberId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const { branch_id, id } = useParams();

  const cardData = async () => {
    try {
      const responce = await UserServices.getPrindCards(branch_id, id);
      const res = responce.data.data;
      res.map((value) => {
        setDate(moment(value.joining).format("MM-DD-YYYY"));
        const cleanedMemberId = value.uid.replace(/-/g, ""); // "abc123def456ghi789"
        setMemberId(cleanedMemberId);
        const uppercaseMemberId = cleanedMemberId.toUpperCase(); // "ABC123DEF456GHI789"
        setMemberId(uppercaseMemberId);
        setFirstName(value.first_name);
        setLastName(value.last_name);
        setImage(value.image);
        setMembershipType(value.membership_type);
        return null;
      });
    } catch (err) {
      console.log(err);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    cardData();
  }, []);

  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;padding:0; margin:0px;";

    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:-8px; padding:0; ";

    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }


    
    // const fakeLink = window.document.createElement("a");
    // fakeLink.style = "display:none;";
    // fakeLink.download = fileName;

    // fakeLink.href = blob;

    // document.body.appendChild(fakeLink);
    // fakeLink.click();
    // document.body.removeChild(fakeLink);

    // fakeLink.remove();
  };

  const printSlip = async (el, imageFileName) => {
    setLoading(true);
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);

    downloadImage(image, imageFileName);
  };

  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Print Card
                {/* <Link
                  to="/viewtrainer"
                  className="btn btn-danger d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-x" />
                  Done
                </Link> */}
                <button
                  className="btn btn-danger d-inline-block float-end btn-sm"
                  // onClick={() => window.print()}
                  onClick={() => {
                    printSlip(document.getElementById("card"), "card");
                  }}
                >
                  Print Card
                </button>
              </h5>
            </div>
            <div className="card-body">
              <div className="container">
                {/* <div
                  id="card"
                  style={{
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    width: "490px",
                    height: "309px",
                    margin: "0 auto",
                    // marginTop: "110px",
                  }}
                > */}
                  <div
                  id="card"
                    style={{
                      
                      width: "490px",
                      height: "306px",
                      // backgroundImage: "url(/assets/images/card.jpg)",
                      backgroundColor: "#FFFFF",
                      margin: "0 auto",
                      // marginTop: "110px",
                      display: "block",
                      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                      WebkitPrintColorAdjust: "exact",
                      WebkitFilter: "opacity(1)",
                    }}
                  >
                    <div
                      style={{
                        width: "27px",
                        height: "200px",
                        backgroundColor: "#E8002B",
                        position: "absolute",
                        top: "18%",
                        borderTopRightRadius: "30px",
                        borderBottomRightRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "200px",
                        height: "27px",
                        backgroundColor: "black",
                        position: "absolute",
                        right: "0",
                        bottom: "0",
                        borderTopLeftRadius: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        left: "8%",
                        top: "20%",
                        position: "absolute",
                      }}
                    >
                      <img
                        src="/assets/images/logo.png"
                        alt="logo"
                        style={{ width: "75%" }}
                      ></img>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "44%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Member ID:
                      </div>
                    </div>
                    <div
                      style={{
                        left: "37%",
                        top: "44%",
                        position: "absolute",
                        color: "black",
                      }}
                    >
                      <p>{memberId}</p>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "55%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Membership Type:
                      </div>
                      <div
                        style={{
                          left: "115%",
                          top: "0",
                          position: "absolute",
                          color: "black",
                          width: "135px",
                        }}
                      >
                        {membershipType.length > 0 ? (
                          <p>{membershipType[0].get_package_name.name}</p>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        left: "8%",
                        top: "66%",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        Membership Date:
                      </div>
                    </div>
                    <div
                      style={{
                        left: "37%",
                        top: "66%",
                        position: "absolute",
                        color: "black",
                      }}
                    >
                      <p>{date}</p>
                    </div>
                    <div
                      style={{
                        width: "173px",
                        height: "173px",
                        backgroundColor: "#E8002B",
                        position: "absolute",
                        borderRadius: "50%",
                        top: "8%",
                        left: "61%",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "170px",
                        height: "170px",
                        position: "absolute",
                        borderRadius: "50%",
                        top: "10%",
                        left: "61%",
                        overflow: "hidden",
                        backgroundImage: `url("${
                          image ? image : "/assets/images/placeholder2.png"
                        }")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        top: "71%",
                        position: "absolute",
                        left: "58%",
                        width: "200px",
                      }}
                    >
                      <div
                        style={{
                          color: "#E8002B",
                          fontWeight: "500",
                          fontSize: "21px",
                          textAlign: "center",
                        }}
                      >
                        {firstName} {lastName}
                      </div>
                    </div>
                  </div>
                {/* </div> */}
              </div>
              {/* <button  className="btn btn-danger d-inline-block btn-sm" onClick={() => window.print()}>Print Card</button> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
<script src="https://kit.fontawesome.com/b99e675b6e.js"></script>;
