/* eslint-disable no-dupe-keys */
import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import { NoteState } from "../../context/notes/NoteState";
import { useMask } from "react-mask-field/dist";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { staffhome } from "../schemas";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import $ from "jquery";
import { Link } from "react-router-dom";
const StaffHome = () => {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;

  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState("");
  const [picture, setPicture] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pkgUp, setPkgUp] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [salary, setSalary] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [cnic, setCnic] = useState("");
  const [commission, setCommission] = useState("");
  const [uid, setUid] = useState("0");
  // eslint-disable-next-line
  const [branchId, setBranchId] = useState("");
  const [prfoImg, setPrfoImg] = useState("");
  const [file, setFile] = useState("");
  const [staffCatagry, setStaffCatagry] = useState([]);
  const [trainingCat, setTrainingCat] = useState([]);
  const [myDepartment, setMyDepartment] = useState([]);
  const [myDesignation, setMyDesignation] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");

  const { id } = useParams();
  const formData = new FormData();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getStaffProfileInfo(id);
        console.log(responce);
        if (responce.status === 200) {
          let data = responce.data.data;
          data.map((value) => {
            setFname(value.first_name);
            setLname(value.last_name);
            setPhone(value.phone);
            setemail(value.email);
            setDate(value.joining);
            setSalary(value.salary);

            setAddress(value.address);
            setTrainingCat(value.training_categories);
            setSelectedDepartment(value.department_id);
            setSelectedDesignation(value.designation_id);
            setCommission(value.commission);
            setCity(value.city);
            setGender(value.gender);
            setStartTime(value.start_time);
            setEndTime(value.end_time);
            setCnic(value.cnic);
            setUid(value.uid);
            setBranchId(value.branch_id);
            setPrfoImg(value.image);
            return null;
          });

          department(data[0].branch_id);
          designation(data[0].branch_id);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.message === "Network Error") {
        } else if (err.response.status === 404) {
          setLoading(false);
          // navigate("/dashboard");
        } else {
          setLoading(false);
        }
      }
    };

    const department = async () => {
      try {
        const responce = await UserServices.departmentName();
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setMyDepartment([]);
      }
    };

    const designation = async () => {
      try {
        const responce = await UserServices.designationName();
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyDesignation([{ name: "Select Designation", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setMyDesignation([]);
      }
    };

    getUsers();
  }, []);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const cnic_format = useMask({
    mask: "_____-_______-_-",
    replacement: { _: /\d/ },
  });
  const videoConstraints = {
    width: 450,
    height: 400,
    facingMode: "user",
  };
  const verifyPhone = async (e) => {
    var check_Phone = e.target.value;
    const phNum = check_Phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    try {
      const response = await UserServices.checkStaffPhone(phNum, id);
      if (response.status === 200) {
        setPhoneError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setPhoneError("Phone number already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const verifyCnic = async (e) => {
    var check_Cnic = e.target.value;
    try {
      const response = await UserServices.checkStaffCnic(check_Cnic, id);
      if (response.status === 200) {
        setCnicError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setCnicError("CNIC already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const UpdateProfile = async (values, catType) => {
    if (file !== "") {
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("image_upload_from", "gallery");
    } else if (picture !== "") {
      formData.append("file", picture);
      formData.append("image_upload_from", "webcam");
    }

    const phNum = values.phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");

    formData.append("first_name", values.name);
    formData.append("last_name", values.last_name);
    formData.append("cnic", values.cnic);
    formData.append("email", values.email);
    formData.append("phone", phNum);
    formData.append("address", values.address);
    formData.append("city", values.city);

    formData.append("gender", values.gender);
    formData.append("joining", values.joining);
    formData.append("salary", values.staffSalary);
    formData.append("designation_id", values.designation);
    formData.append("department_id", values.select_department);
    formData.append("commission", values.commission);
    formData.append("type", catType);
    formData.append("start_time", values.start);
    formData.append("end_time", values.end);

    setLoading(true);

    try {
      const response = await UserServices.UpdateStaffProfile(formData, id);
      if (response.status === 204) {
        setLoading(false);

        toast.success("Profile information updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };
  const webcamRef = React.useRef(null);
  const capture = () => {
    setPicture("");
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    var image = document.getElementById("output");
    image.src = pictureSrc;
  };

  const Table = ({ data }) => {
    return (
      <>
        <div className="selected_staffpkg mt-2">
          <ul>
            {data.length ? (
              data.map((el, index) => (
                <li key={index}>
                  <span>{el.category_info.name}</span>
                </li>
              ))
            ) : (
              <li>
                <span className="text-center text-capitalize">
                  No Package Avaliable
                </span>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  };

  const initialValues = {
    name: fname,
    last_name: lname,
    cnic: cnic,
    email: email,
    phone: phone,
    address: address,
    city: city,
    gender: gender,
    joining: date,
    staffSalary: salary,
    commission: commission,
    select_department: selectedDepartment,
    designation: selectedDesignation,
    start: startTime ? startTime : "",
    end: endTime ? endTime : "",
  };

  const staffCatagryGet = async (e) => {
    if (e.target.value === "12" || e.target.value === "13") {
      try {
        const responce = await UserServices.staffcatagries(branch_id);
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });

        setStaffCatagry([...results]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const showPkg = async () => {
    try {
      const responce = await UserServices.staffcatagries(branch_id);
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });

      setStaffCatagry([...results]);
      setPkgUp(true);
    } catch (err) {
      setPkgUp(false);

      console.log(err);
    }
  };

  //validation........................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: staffhome,
      onSubmit: (values) => {
        var selectedCat = $("input:checkbox:checked")
          .map(function () {
            return this.value;
          })
          .get()
          .join(",");
        UpdateProfile(values, selectedCat);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20 text-capitalize">
                Staff: {values.name + " " + values.last_name}
                <button
                  className="btn btn-primary d-inline-block btn-sm float-end"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-tools" /> Tools
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/vostro-card" className="dropdown-item">
                      Print Card
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/new-card/" + id + "/2"}
                      className="dropdown-item"
                    >
                      Assign Card
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/staff-attendance-report/" + id}
                      className="dropdown-item"
                    >
                      View Attendance
                    </Link>
                  </li>
                </ul>
              </h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="add-in-sch">
                  <label className="form-label fw-bold m-0">
                    Staff ID:<span>{uid}</span>
                  </label>
                </div>
                <div className="col-12">
                  <h5 className="mb-0">Staff Image</h5>
                </div>
                <div className="col-12">
                  <div className="clint-pic-upload">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          onClick={(e) => {
                            e.target.value = null;
                            setFile("");
                            setPicture("");
                          }}
                          onChange={handleUpload}
                          accept=".png, .jpg, .jpeg"
                        />

                        <button
                          className="btn btn-primary "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-camera-fill" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              Capture with webcam
                            </button>
                          </li>
                          <li>
                            <label
                              className="dropdown-item"
                              htmlFor="imageUpload"
                            >
                              Upload Image
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={
                            file === "" && picture === ""
                              ? prfoImg === ""
                                ? {
                                    backgroundImage:
                                      'url("http://isl.vostroworld.com/admin/uploads/templete.png")',
                                    overflow: "hidden",
                                  }
                                : {
                                    backgroundImage:
                                      'url("http://isl.vostroworld.com/admin/uploads/templete.png")',
                                    overflow: "hidden",
                                    backgroundImage: 'url("' + prfoImg + '")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }
                              : {
                                  backgroundImage: "none",
                                  overflow: "hidden",
                                }
                          }
                        >
                          <img
                            alt=""
                            id="output"
                            style={
                              file === "" && picture === ""
                                ? {
                                    display: "none",
                                  }
                                : {
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="mb-0">Staff Name</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter First Name"
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.name && touched.name ? (
                    <p className="text-danger">{errors.name}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={values.last_name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.last_name && touched.last_name ? (
                    <p className="text-danger">{errors.last_name}</p>
                  ) : null}
                </div>
                {/* <div className="col-12 col-md-6 col-lg-4">
                        <div className="col-md-6 col-lg-4 ps-lg-5 ">
                          <label className="form-label">Management</label>
                          <div className="custom-raio">
                            <ul className="cat-type">
                                <li>
                                  <input
                                    type="checkbox"
                                    id="yes"
                                    value="yes"
                                    className="option[17]"
                                    // defaultChecked={true}
                                  />
                                  <label htmlFor="yes">Yes</label>
                                  <div className="check" />
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="no"
                                    value="no"
                                    className="option[17]"
                                    // defaultChecked={true}
                                  />
                                  <label htmlFor="no">No</label>
                                  <div className="check" />
                                </li>
                            </ul>
                          </div>
                        </div>
                </div> */}
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Staff Contact Detail</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <p className="text-danger">{errors.email}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="3000000000"
                      name="phone"
                      value={values.phone}
                      onChange={(e) => {
                        handleChange(e);
                        verifyPhone(e);
                      }}
                      onBlur={handleBlur}
                      ref={phone_number}
                    />
                    {phoneError !== "" ? (
                      <p className="text-danger">{phoneError}</p>
                    ) : null}

                    {errors.phone && touched.phone ? (
                      <p className="text-danger">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      CNIC <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" xxxxx-xxxxxxx-x"
                      name="cnic"
                      value={values.cnic}
                      onChange={(e) => {
                        handleChange(e);
                        verifyCnic(e);
                      }}
                      onBlur={handleBlur}
                      ref={cnic_format}
                    />
                    {cnicError !== "" ? (
                      <p className="text-danger">{cnicError}</p>
                    ) : null}
                    {errors.cnic && touched.cnic ? (
                      <p className="text-danger">{errors.cnic}</p>
                    ) : null}
                  </div>
                </div>
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Staff Address Detail</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.address && touched.address ? (
                    <p className="text-danger">{errors.address}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.city && touched.city ? (
                    <p className="text-danger">{errors.city}</p>
                  ) : null}
                </div>
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Staff Duty Time</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start Time</label>
                    <input
                      type="time"
                      className="form-control "
                      placeholder="Enter Time"
                      name="start"
                      value={values.start}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.start && touched.start ? (
                    <p className="text-danger">{errors.start}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End Time</label>
                    <input
                      type="time"
                      className="form-control "
                      placeholder="Enter Time"
                      name="end"
                      value={values.end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.end && touched.end ? (
                    <p className="text-danger">{errors.end}</p>
                  ) : null}
                </div>
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Staff Other Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="gender"
                      multiple={false}
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  {errors.gender && touched.gender ? (
                    <p className="text-danger">{errors.gender}</p>
                  ) : null}
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    {myDepartment.length ? (
                      <select
                        className="form-select"
                        name="select_department"
                        multiple={false}
                        value={values.select_department}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {myDepartment.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="select_department"
                        value={values.select_department}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Department</option>
                      </select>
                    )}
                    {errors.select_department && touched.select_department ? (
                      <p className="text-danger">{errors.select_department}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Designation <span className="text-danger">*</span>
                    </label>
                    {myDesignation.length ? (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="designation"
                        value={values.designation}
                        multiple={false}
                        onChange={(e) => {
                          handleChange(e);
                          staffCatagryGet(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myDesignation.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="designation"
                        value={values.designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Designation</option>
                      </select>
                    )}
                    {errors.designation && touched.designation ? (
                      <p className="text-danger">{errors.designation}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Salary <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control "
                      placeholder="Enter Gender"
                      name="staffSalary"
                      value={values.staffSalary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.staffSalary && touched.staffSalary ? (
                    <p className="text-danger">{errors.staffSalary}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Commission</label>
                    <input
                      type="number"
                      className="form-control "
                      placeholder="Commission"
                      name="commission"
                      value={values.commission}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.commission && touched.commission ? (
                    <p className="text-danger">{errors.commission}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Joining Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control "
                      placeholder="Enter joining"
                      name="joining"
                      value={values.joining}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.joining && touched.joining ? (
                    <p className="text-danger">{errors.joining}</p>
                  ) : null}
                </div>
                {values.designation === "1" ||
                values.designation === "2" ||
                values.designation === "76" ||
                values.designation === 1 ||
                values.designation === 2 ||
                values.designation === 76 ? (
                  <>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="custom-group">
                        <label className="form-label fw-bold">
                          Package Category :
                        </label>
                        {pkgUp ? null : (
                          <button
                            className="btn btn-link p-0 ms-2 font-13 align-bottom custom-update-btn"
                            type="button"
                            onClick={showPkg}
                          >
                            Update
                          </button>
                        )}
                      </div>

                      {pkgUp ? (
                        staffCatagry.length ? (
                          <div className="custom-raio">
                            <ul className="cat-type">
                              {staffCatagry.map((el, index) => (
                                <li key={index}>
                                  <input
                                    type="checkbox"
                                    id={el.id}
                                    value={el.id}
                                    className="option[17]"
                                  />
                                  <label htmlFor={el.id}>{el.name}</label>
                                  <div className="check" />
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null
                      ) : (
                        <Table data={trainingCat} />
                      )}
                    </div>
                  </>
                ) : null}

                <div className="clearfix" />
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 "
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Capture Image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={450}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="webcam"
                  />
                ) : (
                  <img src={picture} alt="" />
                )}
              </div>
              <div>
                {picture !== "" ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPicture("");
                      }}
                      className="btn btn-danger custom-butn3 mt-2"
                    >
                      Retake
                    </button>
                    <button
                      className="btn btn-danger custom-butn3 mt-2 ms-2"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger custom-butn3 "
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default StaffHome;
