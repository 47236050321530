import React, { useState, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { useFormik } from "formik";
import { managerResource } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import InactiveResource from "./InactiveResource";


const ResourceManager = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [resTime, setResTime] = useState("");
  const [nameError, setNameError] = useState("");


  const addResource = async (values, action) => {
   if ( nameError === "") {
    
   
    try {
      setLoading(true);
      const response = await UserServices.addManagerResources(values);
      if (response.status === 201) {
        setLoading(false);

        Swal.fire({
          title: "Success!",
          text: "Resource added successfully.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            action.resetForm();
            window.location.reload(false);
          }
        });
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  }
  };
  const verifyName = async (e) => {
    var check_Name = e.target.value;
    try {
      const response = await UserServices.checkName(check_Name);
      if (response.status === 200) {
        setNameError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setNameError("Name already exists");
      }
    }
  };
  const getResource = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getActiveResources();
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: values.type,
            description: values.description,
          });
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getResource();
  }, []);
  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getResourcesiltration(
          "1",
          limit,
          pageNo,
          resTime
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
                id: values.id,
                name: values.name,
                type: values.type,
                description: values.description,
            });
          });

          setMyData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getResourcesiltration(
          "1",
          newLimit,
          1,
          resTime
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
                id: values.id,
                name: values.name,
                type: values.type,
                description: values.description,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const makeInActive = async (resource_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.inactiveResource(resource_Id);

      if (responce.status === 204) {
        setLoading(false);

        Swal.fire({
          title: "Success!",
          text: "Inactivated successfully.",
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: "#ed1c24",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveRc = (resource_Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to inactive this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, inactive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        makeInActive(resource_Id);
      }
    });
  };

  const initialValues = {
    name: "",
    type: "",
    description:"N/A",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: managerResource,
      onSubmit: (values, action) => {
         addResource(values, action);
      },
    });
    const changeResourceTime = (e) => {
      let resTime = e;
      setResTime(resTime);
      const getResourceTypeData = async () => {
        setLoading(true);
        try {
          const responce = await UserServices.getResourceTime(
             "1",
            limit,
            1,
            resTime
          );
          let myDataarr;
  
          if (responce.status === 200) {
            myDataarr = responce.data.data.data;
            const res = myDataarr;
            const results = [];
  
            res.map((values) => {
              results.push({
                id: values.id,
                name: values.name,
                type: values.type,
                description: values.description,
              });
            });
  
            setMyData([...results]);
            setTotalResults(responce.data.totalRecord);
            setTotalPages(responce.data.totalPages);
            setCurrentPage(responce.data.data.current_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setTotalResults("0");
            setTotalPages("1");
            setMyData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      getResourceTypeData();
    };
    const Table = ({ data, rowsPerPage }) => {
        const { slice } = useTable(data, 1, rowsPerPage);
        const { items, requestSort, sortConfig } = useSortableData(slice);
    
        const getClassNamesFor = (name) => {
          if (!sortConfig) {
            return;
          }
          return sortConfig.key === name ? sortConfig.direction : undefined;
        };
    
        return (
          <>
            <table className="table table-striped table-borderless table-style-1">
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    className="no_sort"
                  >
                   Id
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("type")}
                    className={getClassNamesFor("type")}
                  >
                   Category
                  </th>
    
                  <th scope="col" className="no_sort">
                    Description
                  </th>
                  <th scope="col" className="no_sort">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length
                  ? items.map((el, index) => (
                      <tr key={index}>
                        <td>
                          {currentPage === 1
                            ? index + 1
                            : (currentPage - 1) * limit + index + 1}
                        </td>
                        <td>{el.id}</td>
                        <td>{el.name}</td>
                        <td>{el.type.replace(/([A-Z])/g, ' $1')}</td>
                        <td>{el.description}</td>
                        {/* <td>
                          <Link
                            className="renew-pkg custom-update-button bg-warning text-white "
                            to={"/update-resource/" + el.id}
                          >
                            {" "}
                            Edit
                          </Link>
                          <button
                            type="button"
                            onClick={() => inactiveRc(el.id)}
                            className="renew-pkg custom-block-button bg-danger text-white ms-2"
                          >
                            Inactive
                          </button>
                        </td> */}
                        {el.id === 2 || el.id === 1 || el.id === 32 ? <button className="disable-button1">N/A</button> : (
  <td>
    <Link
      className="renew-pkg custom-update-button bg-warning text-white"
      to={"/update-resource/" + el.id}
    >
      Edit
    </Link>
    <button
      type="button"
      onClick={() => inactiveRc(el.id)}
      className="renew-pkg custom-block-button bg-danger text-white ms-2"
    >
      Inactive
    </button>
  </td>
)}
                      </tr>
                    ))
                  : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
              </tbody>
            </table>
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  changePagination(e);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </>
        );
      };
  return (
    <>
      <div className="wrapper">
        <Navbar />
        <Sidebar />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Resource Manager</h5>
              </div>
              <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className='text-danger'>*</strong>
                Must Required Or Fill.
              </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Name"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                            handleChange(e);
                            verifyName(e);
                          }}
                        onBlur={handleBlur}
                      />
                      {nameError ? (
                          <div className="errorMsg text-danger">
                            {nameError}
                          </div>
                        ) : null}
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      <select
                        type="text"
                        className="form-select"
                        placeholder="Select Type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Category</option>
                        <option value="Department">Department</option>
                        <option value="Designations">Designations</option>
                        <option value="Expense">Expense</option>
                        <option value="PaymentMethod">Payment Method</option>
                        <option value="TrainerPackageType">Trainer Package Type</option>
                      </select>
                      {errors.type && touched.type ? (
                        <p className="text-danger">{errors.type}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <p className="text-danger">{errors.description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mt-4"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">View Resource Manager</h5>
              </div>
              <div className="card-body">
                <div className="accordion accordionClient" id="accordionClient">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Active Resource:
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionClient"
                    >
                    <div className="row">
                      
                      <div className="col-md-3 mt-3 ms-3">
                          <select
                            className="form-select"
                            onChange={(e) => changeResourceTime(e.target.value)}
                          >
                            <option>Filter Category</option>
                            <option value="">All</option>
                            <option value="Department">Department</option>
                        <option value="Designations">Designations</option>
                        <option value="Expense">Expense</option>
                        <option value="PaymentMethod">Payment Method</option>
                        <option value="TrainerCategory">
                          Trainer Category
                        </option>
                        <option value="MembershipType">Membership Type</option>
                          </select>
                        </div>
                        {totalResults > limit ? (
                        <div className="col-md-4 col-lg-4 mt-3  ms-auto me-3">
                          <div className="input-group">
                            <select
                              className="form-select"
                              value={limit}
                              onChange={(e) => changeLimit(e.target.value)}
                            >
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="250">250</option>
                              <option value="500">500</option>
                            </select>
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => window.location.reload(false)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      ) : null}
                        </div>
                      <div className="accordion-body">
                        <div className="table-responsive ">
                          <Table data={myData} rowsPerPage={limit} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <InactiveResource/>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ResourceManager;
