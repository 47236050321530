import React, { useState } from "react";
import MenuButton from "../MenuButton/MenuButton";

export default function FitnessPlan() {
  const [detailsVisible, setDetailsVisible] = useState("");

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Fitness Plan</h5>
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-secondary fitness-btn active"
                    >
                      1 Week
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fitness-btn ms-3 "
                    >
                      2 Week
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fitness-btn ms-3 "
                    >
                      3 Week
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fitness-btn ms-3 "
                    >
                      4 Week
                    </button>
                  </div>
                  <div className="col-4 mt-4">
                    <span className="fs-6 fw-bold">Client Name:</span>{" "}
                    <span className="fs-6">Saad Ahmed</span>
                  </div>
                  <div className="col-1 mt-4 ms-auto">
                    <span className="">First Week</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <div className="fitness-card day1">
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 1- Legs</h5>
                          <div
                            className="ms-auto fitness-info"
                            onClick={() => {
                              if (detailsVisible !== "day1") {
                                setDetailsVisible("day1");
                              } else {
                                setDetailsVisible("");
                              }
                            }}
                          >
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-clock "></i> 11:00 AM - 12:00 AM
                        </p>
                        <span className="card-link">Monday</span>
                      </div>
                      {detailsVisible === "day1" && (
                        <i className="bi bi-caret-down-fill arrow-at-top"></i>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="fitness-card day2">
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 2- Rest</h5>
                          <div
                            className="ms-auto fitness-info"
                            onClick={() => {
                              if (detailsVisible !== "day2") {
                                setDetailsVisible("day2");
                              } else {
                                setDetailsVisible("");
                              }
                            }}
                          >
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-moon-stars"></i> Rest
                        </p>

                        <span className="card-link">Tuesday</span>
                      </div>
                      {detailsVisible === "day2" && (
                        <i className="bi bi-caret-down-fill arrow-at-top"></i>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="fitness-card day3"
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 3- Abs</h5>
                          <div className="ms-auto fitness-info"
                           onClick={() => {
                              if (detailsVisible !== "day3") {
                                setDetailsVisible("day3");
                              } else {
                                setDetailsVisible("");
                              }
                            }}>
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-clock "></i> 11:00 AM - 12:00 AM
                        </p>
                        <span className="card-link">Wednesday</span>
                      </div>
                      {detailsVisible === "day3" && (
                        <i className="bi bi-caret-down-fill arrow-at-top"></i>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="fitness-card day4"
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 4- Arms</h5>
                          <div className="ms-auto fitness-info"
                           onClick={() => {
                              if (detailsVisible !== "day4") {
                                setDetailsVisible("day4");
                              } else {
                                setDetailsVisible("");
                              }
                            }}>
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-clock "></i> 11:00 AM - 12:00 AM
                        </p>

                        <span className="card-link">Thursday</span>
                      </div>
                      {detailsVisible === "day4" && (
                        <i className="bi bi-caret-down-fill arrow-at-top"></i>
                      )}
                    </div>
                  </div>
                  {detailsVisible === "day1" ? (
                    <div className="col-md-12 mt-4 ">
                      <div className="fitness-card mt-0 day1">
                        <div className="manage-task">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">Squats</div>
                                <div className="mt-2">Lunges</div>
                              </div>
                              {/* ......... */}
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">Leg Press</div>
                                <div className="mt-2">Calf Raises</div>
                              </div>
                              {/* .... */}
                              <div className="col-lg-4">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">Leg Extenstion</div>
                                <div className="mt-2">Sit Ups</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : detailsVisible === "day2" ? (
                    <div className="col-md-12 mt-4 ">
                      <div className="fitness-card mt-0 day2">
                        <div className="manage-task">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* ......... */}
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* .... */}
                              <div className="col-lg-4">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) :  detailsVisible === "day3" ? (
                    <div className="col-md-12 mt-4 ">
                      <div className="fitness-card mt-0 day3">
                        <div className="manage-task">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* ......... */}
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* .... */}
                              <div className="col-lg-4">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : detailsVisible === "day4" ? (
                    <div className="col-md-12 mt-4 ">
                      <div className="fitness-card mt-0 day4">
                        <div className="manage-task">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* ......... */}
                              <div className="col-lg-4 custom-fitness-col">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                              {/* .... */}
                              <div className="col-lg-4">
                                <div className="d-flex card-heading-1">
                                  <h4 className="text-black">Routine x2</h4>
                                </div>
                                <div className="mt-2">50 jumping jacks</div>
                                <div className="mt-2">50 jumping jacks</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ): null}
                  <div className="col-md-3 mt-4">
                    <div
                      className="fitness-card"
                      style={{ background: "#d7e1e2", borderRadius: "10px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 5- Rest</h5>
                          <div className="ms-auto fitness-info">
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-moon-stars"></i> Rest
                        </p>

                        <span className="card-link">Friday</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    <div
                      className="fitness-card"
                      style={{ background: "#f2edd9", borderRadius: "10px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 6- Chest</h5>
                          <div className="ms-auto fitness-info">
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-clock "></i> 11:00 AM - 12:00 AM
                        </p>

                        <span className="card-link">Saturday</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    <div
                      className="fitness-card"
                      style={{ background: "#edf5f8", borderRadius: "10px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <h5 className="card-title">Day 7- Back</h5>
                          <div className="ms-auto fitness-info">
                            <i className="bi bi-filter fs-4"></i>
                          </div>
                        </div>
                        <p className="card-text mt-2">
                          <i className="bi bi-clock "></i> 11:00 AM - 12:00 AM
                        </p>

                        <span className="card-link">Sunday</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
