import React, { useContext, useEffect, useState,useRef } from 'react';
import MenuButton from '../MenuButton/MenuButton';
import { useFormik } from 'formik';
import { BankAccountDetails } from "../schemas";
import { NoteState } from '../../context/notes/NoteState';
import UserServices from '../../UserServices/UserServices';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import useSortableData from '../../hooks/useSortableData';
import useTable from '../../hooks/useTable';
import jsPDF from 'jspdf';
import $ from "jquery";

export default function BankDetails() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [loading, setLoading] = useState(false);
    const [myBranches, setMyBranches] = useState([]);
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString("en-CA");
    const [limit, setLimit] = useState("25");
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
    const [myData, setMyData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // update
    const [selectedBranches, setSelectedBranches] = useState("");
    const [selectedBankName, setSelectedBankName] = useState("");
    const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
    const [selectedAccountTitle, setSelectedAccountTitle] = useState("");
    const [recordId, setRecordId] = useState("");

    var clickEvent = $.Event("click");
    const closeButtonRef = useRef(null);
    const getPkg = async (id) => {
      setLoading(true);
      try {
        const responce = await UserServices.getBankDetailsInfo(id);
        if (responce.status === 200) {
          let data = responce.data.data;
          data.map((values) => {
            setSelectedBranches(values.branch_id);
            setSelectedBankName(values.bank_name);
            setSelectedAccountNumber(values.account_no);
            setSelectedAccountTitle(values.account_title);
            setRecordId(values.id);
          });
          $("#membership").trigger(clickEvent);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getDetails = async (page, limit) => {
      setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
      try {
        const responce = await UserServices.getAccountDetails( 
          branch_id,
          newLimit,
          pageNo);
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              branch_name: values.branch_name,
              bank_name: values.bank_name,
              account_no: values.account_no,
              account_title: values.account_title,
              status: values.status,
            });
            return null;
          });
  
          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err?.response?.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const branches = async () => {
        try {
          const responce = await UserServices.branchName();
          const res = responce.data.data;
          const results = [];
    
          res.map((value) => {
            results.push({
              id: value.id,
              name: value.name,
            });
            return null;
          });
          setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
        } catch (err) {
          console.log(err);
        }
      };

      useEffect(() => {
        getDetails(currentPage, limit);
        branches();
      }, []);
      const makeInActive = async (account_Id) => {
        try {
          setLoading(true);
          const responce = await UserServices.inactiveAccountDetails(account_Id);
          if (responce.status === 204) {
            setLoading(false);
    
            toast.error("Inactivated Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
        getDetails(currentPage, limit);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const inactiveDetails = (account_Id) => {
        makeInActive(account_Id);
      };
      const makeActive = async (account_Id) => {
        try {
          setLoading(true);
          const responce = await UserServices.activeAccountDetails(account_Id);
          if (responce.status === 204) {
            setLoading(false);
    
            toast.success("Activated Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getDetails(currentPage, limit);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const activeDetails = (account_Id) => {
        makeActive(account_Id);
      };
      const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteDetails(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getDetails(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
      };
      const delDetails = (id) => {
      deleteRecord(id);
      };
      const addDetails = async (values, action) => {
          try {
            setLoading(true);
            const response = await UserServices.addAccountdetails(values);
    
            if (response.status === 201) {
              setLoading(false);
              toast.success("Added Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
             
              getDetails(currentPage, limit);
            }
          } catch (err) {
            setLoading(false);
            if (err.response.status === 422) {
            } else if (err.response.status === 500) {
              // toast.error("Unproccessable content!", { position: "top-center" });
            } else if (err.response.status === 400) {
              // toast.error("Unproccessable content!", { position: "top-center" });
            }
          }
      };

    const initialValues = {
        branch_id: branch_id,
        name: "",
        account_no:"",
        account_title:"",
        date: defaultValue,
      };
    
      const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
          initialValues: initialValues,
          validationSchema: BankAccountDetails,
          onSubmit: (values, action) => {
            addDetails(values, action);
          },
        });
        const generatePdf = (table) => {
          const doc = new jsPDF("p", "mm", "letter");
      
          // Loop through all the tables in the section and add them to the PDF
      
          // Add a new page for each table (skip first table as it is already on the first page)
          const captionText = table.getAttribute("data-caption");
          // Add the table caption as regular text above the table
          doc.text(  captionText, 10, 10);
          const tableOptions = {
            headStyles: { fillColor: [232, 0, 43], textColor: 255 },
            styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
          };
          doc.autoTable({
            html: table,
            ...tableOptions,
          });
      
          doc.save("Bank_Details.pdf");
        };
        const Table = ({ data, rowsPerPage }) => {
          const { slice } = useTable(data, 1, rowsPerPage);
          const { items, requestSort, sortConfig } = useSortableData(slice);
      
          const getClassNamesFor = (name) => {
            if (!sortConfig) {
              return;
            }
            return sortConfig.key === name ? sortConfig.direction : undefined;
          };
      
          return (
            <>
              <table
                className="table table-striped table-borderless table-style-1"
                id="report-POS"
                data-caption="Bank Account Details"
              >
                <thead>
                  <tr>
                    <th scope="col" className="no_sort">
                      Sr#
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("branch_id")}
                      className={getClassNamesFor("branch_id")}
                    >
                      Branch Name
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("bank_name")}
                      className={getClassNamesFor("bank_name")}
                    >
                      Bank Name
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("account_no")}
                      className={getClassNamesFor("account_no")}
                    >
                      Account Number
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("account_title")}
                      className={getClassNamesFor("account_title")}
                    >
                      Account Title
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                    >
                      Status
                    </th>
                    <th scope="col" className="no_sort">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.length ? (
                    items.map((el, index) => (
                      <tr key={index}>
                        <td>
                          {currentPage === 1
                            ? index + 1
                            : (currentPage - 1) * limit + index + 1}
                        </td>
                        <td>{el.branch_name}</td>
                        <td>{el.bank_name}</td>
                        <td>{el.account_no}</td>
                        <td>{el.account_title}</td>
                        <td>{el.status === "0" ? "Inactive" : "Active"}</td>
                        <td>
                        <button
                      onClick={() => {
                        getPkg(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                          {el.status === "0" ? (
                            <button
                              type="button"
                              className="ms-2 active-btn"
                              onClick={() => activeDetails(el.id)}
                            >
                              <i className="bi bi-check-circle text-success"></i>{" "}
                              <span className="text-success active-text ms-1">
                                Active
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="ms-2 inactive-btn"
                              onClick={() => inactiveDetails(el.id)}
                            >
                              <i className="bi bi-x-circle text-danger"></i>{" "}
                              <span className="text-danger inactive-text ms-1">
                                Inactive
                              </span>
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() => delDetails(el.id)}
                            className="ms-2 inactive-btn"
                          >
                            <i className="bi bi-trash3 text-danger"></i>{" "}
                            <span className="text-danger inative-text ms-1">
                              Delete
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14" className="text-center text-capitalize">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {totalResults > limit && totalPages > 1 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={parseInt(limit)}
                  totalItemsCount={totalResults}
                  onChange={(e) => {getDetails(e, limit);}}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Page"
                />
              ) : (
                ""
              )}
            </>
          );
        };

        const bankDetailsUpdate = async (updateValues, action) => {
          setLoading(true);
          try {
            const response = await UserServices.bankDetailsUpdate(updateValues, recordId);
            if (response.status === 204) {
              setLoading(false);
              toast.success("Updated Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
              getDetails(currentPage, limit);
          // Trigger the close button click event
              closeButtonRef.current.click();
              
            }
          } catch (err) {
            if (err.response.status === 422) {
              toast.error("Some data is missing", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
      
              setLoading(false);
            } else {
              toast.error("Some thing went wronge !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
            }
          }
        };
        const updateinitialValues = {
          branch_id: selectedBranches,
          name: selectedBankName,
          account_no: selectedAccountNumber,
          account_title: selectedAccountTitle,
        };
      
          const {
            values: updateValues,
            errors: updateErrors,
            touched: updateTouched,
            handleBlur: updateHandleBlur,
            handleChange: updateHandleChange,
            handleSubmit: updateHandleSubmit,
          } = useFormik({
            enableReinitialize: true,
            initialValues: updateinitialValues,
            validationSchema: BankAccountDetails,
            onSubmit: (updateValues, action) => {
              bankDetailsUpdate(updateValues, action);
            },
          });
  return (
    <div className="wrapper">
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Add Bank Details</h5>
          </div>
          <div className="card-body">
            <p>
              <i
                className="bi bi-exclamation-lg text-danger"
                aria-hidden="true"
              />
              The Fields With <strong className="text-danger">*</strong>
              Must Required Or Fill.
            </p>
            <form
              className="row g-3"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={values.branch_id || ""}
                      onChange={(e) => {
                        handleChange(e);
                        // changeCategory(e);
                      }}
                      onBlur={handleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {errors.branch_id && touched.branch_id ? (
                    <p className="text-danger">{errors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Bank Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Bank Name"
                      name="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="text-danger">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Account Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Account Title"
                      name="account_title"
                      autoComplete="off"
                      value={values.account_title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.account_title && touched.account_title ? (
                      <p className="text-danger">{errors.account_title}</p>
                    ) : null}
                  </div>
                </div>
              <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Account Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Account Number"
                      name="account_no"
                      autoComplete="off"
                      value={values.account_no}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.account_no && touched.account_no ? (
                      <p className="text-danger">{errors.account_no}</p>
                    ) : null}
                  </div>
              </div>
              <div className="col-12 col-md-6 d-none">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Date <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="11-03-2022"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.date && touched.date ? (
                            <p className="text-danger">
                              {errors.date}
                            </p>
                          ) : null}
                        </div>
                      </div>
              <div className="clearfix" />
              <div className="col-12 ">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="card">
        <div className="card-header py-3">
          <h5 className="mb-0 font-20">View Account Details</h5>
        </div>
        <div className="card-body">
          <div className="row d-flex">
            <div className="col-md-1 col-lg-1  me-3 mb-2">
              <div className="input-group">
                <select
                  className="form-select"
                  value={limit}
                   onChange={(e) => getDetails("1", e.target.value)}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
            <div className=" col-md-2 text-end ms-auto">
              <button
                className="btn btn-primary mx-2 file-download"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <div className="table-responsive ">
            <Table data={myData} rowsPerPage={limit} />
          </div>
        </div>
      </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
          {/* ......Hidden Button....... */}
          <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Bank Details
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
            <form
              className="row g-3"
              onSubmit={(e) => {
                updateHandleSubmit(e);
              }}
            >
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={updateValues.branch_id || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                        // changeCategory(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {updateErrors.branch_id && updateTouched.branch_id ? (
                    <p className="text-danger">{updateErrors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Bank Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Account Name"
                      name="name"
                      autoComplete="off"
                      value={updateValues.name}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.name && updateTouched.name ? (
                      <p className="text-danger">{updateErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Account Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Account Title"
                      name="account_title"
                      autoComplete="off"
                      value={updateValues.account_title}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.account_title && updateTouched.account_title ? (
                      <p className="text-danger">{updateErrors.account_title}</p>
                    ) : null}
                  </div>
                </div>
              <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Account Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Account Number"
                      name="account_no"
                      autoComplete="off"
                      value={updateValues.account_no}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.account_no && updateTouched.account_no ? (
                      <p className="text-danger">{updateErrors.account_no}</p>
                    ) : null}
                  </div>
                </div>
               
                <div className="col-12 col-md-6 d-none">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Date <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="11-03-2022"
                            name="date"
                            value={updateValues.date}
                            onChange={updateHandleChange}
                            onBlur={updateHandleBlur}
                          />
                          {updateErrors.date && updateTouched.date ? (
                            <p className="text-danger">
                              {updateErrors.date}
                            </p>
                          ) : null}
                        </div>
                      </div>
              <div className="clearfix" />
              <div className="col-12 ">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Update
                </button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}
