/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const ViewClients = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);
  const [clientType, setClientType] = useState("");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id, role } = state;
  const [selectedOption, setSelectedOption] = useState("");
  // for inactive table
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);

  const getUsers = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getClients(
        branch_id,
        "1",
        newLimit,
        pageNo
      );
      let myDataarr;
      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/clients/exportclients?branch_id=" +
            branch_id +
            "status=1"
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            id: value.id,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            desigination: value.desigination,
            phone: value.phone,
            gender: value.gender,
            address: value.address,
            city: value.city,
            uid: value.uid,
            membership: value.membership_type,
            number: value.number,
            package: value.active_packages,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
        setExportLink();
      }
    }
  };
  const getusersData = async (page, limit) => {
    setLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getClients(
        branch_id,
        "0",
        newLimit,
        pageNo
      );
      let myInactivedataarr;
      if (responce.status === 200) {
        myInactivedataarr = responce.data.data.data;

        const res = myInactivedataarr;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            uid: value.uid,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            desigination: value.desigination,
            phone: value.phone,
            gender: value.gender,
            address: value.address,
            city: value.city,
            membership: value.membership_type,
          });
          return null;
        });
        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      setInactiveTotalResults("0");
      setInactiveTotalPages("1");
      setInactiveData([]);
      setLoading(false);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit);
    getusersData(inactiveCurrentPage, inactiveLimit);
  }, []);

  const makeInActive = async (user_id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactiveClients(user_id);

      if (responce.status === 204) {
        setLoading(false);

        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
        getusersData(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const inactiveClient = (user_id) => {
    makeInActive(user_id);
  };
  const makeActive = async (user_id) => {
    try {
      setLoading(true);

      const responce = await UserServices.activeClients(user_id);

      if (responce.status === 204) {
        setLoading(false);

        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
        getusersData(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeClient = (user_id) => {
    makeActive(user_id);
  };
  const deleteRecord = async (user_id) => {
    try {
      const responce = await UserServices.deleteClient(user_id);

      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delClient = (user_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(user_id);
      }
    });
  };

  //..........................................................................................................

  const changeClientType = (e) => {
    setSelectedOption(e);
    let clinetType = e;
    setClientType(clinetType);
    const getClientTypeData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getClientDataByType(
          "1",
          limit,
          1,
          clinetType,
          branch_id
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              desigination: value.desigination,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              city: value.city,
              uid: value.uid,
              membership: value.membership_type,
              number: value.number,
              package: value.active_packages,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
          setExportLink();
        }
      }
    };
    getClientTypeData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");

      // Loop through all the tables in the section and add them to the PDF

      // Add a new page for each table (skip first table as it is already on the first page)
      const captionText = table.getAttribute("data-caption");
      // Add the table caption as regular text above the table
      doc.text(captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("clients.pdf");
    };

    return (
      <>
        <div className="row mb-2">
          <div className="col-md-2">
            <div className="input-group">
              <select
                className="form-select"
                onChange={(e) => changeClientType(e.target.value)}
                value={selectedOption}
              >
                <option value="">Select Client Type</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Others</option>
              </select>
            </div>
          </div>

          <div className="col-md-1 col-lg-1 ms-auto">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => getUsers("1", e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className=" col-md-2 text-end">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <a
              href={exportLink}
              rel="noreferrer"
              // target="_blank"
              className="btn btn-primary mx-2 file-download"
            >
              <i className="bi bi-download" /> Excel
            </a>
          </div>
        </div>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="All Active Clients"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("uid")}
                className={getClassNamesFor("uid")}
              >
                Membership ID
              </th>
              <th scope="col" className="no_sort">
                Email
              </th>
              <th scope="col" className="no_sort">
                Phone
              </th>
              <th
                scope="col"
                onClick={() => requestSort("gender")}
                className={getClassNamesFor("gender")}
              >
                Gender
              </th>
              <th
                scope="col"
                onClick={() => requestSort("membership")}
                className={getClassNamesFor("membership")}
              >
                Membership Type
              </th>
              <th scope="col" className="no_sort">
                Address
              </th>
              <th scope="col" className="no_sort">
                City
              </th>
              <th scope="col" className="no_sort">
                More
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link
                      to={
                        role !== "9"
                          ? "/client-home/" + el.id
                          : "/trainer-client/" + el.id
                      }
                    >
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{el.uid}</td>
                  <td>{el.email}</td>
                  <td>{el.phone}</td>
                  <td className="text-capitalize">{el.gender}</td>
                  <td>
                    {el.membership[0]?.get_package_name?.name
                      ? el.membership[0]?.get_package_name?.name
                      : "N/A"}
                  </td>

                  <td>{el.address}</td>
                  <td>{el.city}</td>

                  <td>
                    <button
                      className="btn  d-inline-block btn-sm custom-action-button"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="assets/images/dots.png"
                        className="action-icon"
                        alt=""
                      />
                    </button>
                    <ul className="dropdown-menu custom-dropdown">
                      {role !== "9" ? (
                        <>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => inactiveClient(el.id)}
                            >
                              Inactive
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => delClient(el.id)}
                            >
                              Delete
                            </button>
                          </li>
                        </>
                      ) : null}
                      <li>
                        <Link
                          className="dropdown-item"
                          to={"/preAssessment/" + el.id}
                        >
                          View Assessment
                        </Link>
                      </li>
                      {el?.package?.length ? (
                        <>
                          {role !== "9" ? (
                            <li>
                              <Link
                                to={"/client-purchases/" + el.id}
                                className="dropdown-item"
                              >
                                View Purchases
                              </Link>
                            </li>
                          ) : null}

                          <li>
                            <Link
                              to={"/account-details/" + el.id}
                              className="dropdown-item"
                            >
                              Account Details
                            </Link>
                          </li>
                        </>
                      ) : null}
                      {role !== "9" ? (
                        <>
                          {el?.package?.length ? (
                            <li>
                              <Link
                                to={"/sell-package/" + el.id + "/renew"}
                                className="dropdown-item"
                              >
                                Renew Package
                              </Link>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={"/sell-package/" + el.id + "/new"}
                                className="dropdown-item"
                              >
                                Sell Package
                              </Link>
                            </li>
                          )}
                          {el.number !== null ? (
                            <li>
                              <Link
                                to={"/new-card/" + el.id + "/1"}
                                className="dropdown-item"
                              >
                                Assign Card
                              </Link>
                            </li>
                          ) : (
                            <li>
                              <Link
                                to={"/new-card/" + el.id + "/1"}
                                className="dropdown-item"
                              >
                                Assign New Card
                              </Link>
                            </li>
                          )}
                        </>
                      ) : null}
                    </ul>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("uid")}
                className={getClassNamesFor("uid")}
              >
                Membership ID
              </th>
              <th scope="col" className="no_sort">
                Email
              </th>
              <th scope="col" className="no_sort">
                Phone
              </th>
              <th
                scope="col"
                onClick={() => requestSort("gender")}
                className={getClassNamesFor("gender")}
              >
                Gender
              </th>
              <th
                scope="col"
                onClick={() => requestSort("membership")}
                className={getClassNamesFor("membership")}
              >
                Membership Type
              </th>

              <th scope="col" className="no_sort">
                Address
              </th>

              <th scope="col" className="no_sort">
                City
              </th>

              <th scope="col" className="no_sort">
                More
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link
                      to={
                        role !== "9"
                          ? "/client-home/" + el.id
                          : "/trainer-client/" + el.id
                      }
                    >
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{el.uid}</td>
                  <td>{el.email}</td>
                  <td>{el.phone}</td>
                  <td className="text-capitalize">{el.gender}</td>
                  <td>
                    {el.membership[0]?.get_package_name?.name
                      ? el.membership[0]?.get_package_name?.name
                      : "N/A"}
                  </td>
                  <td>{el.address}</td>
                  <td>{el.city}</td>
                  <td>
                    {role !== "9" ? (
                      <>
                        <button
                          className="btn  d-inline-block btn-sm custom-action-button"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="assets/images/dots.png"
                            className="action-icon"
                            alt=""
                          />
                        </button>
                        <ul className="dropdown-menu custom-dropdown">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => activeClient(el.id)}
                            >
                              Restore
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => delClient(el.id)}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getusersData(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  Active Clients
                  {role !== "9" ? (
                    <Link
                      to="/add-clients"
                      className="btn btn-primary d-inline-block float-end btn-sm"
                    >
                      <i className="bi bi-plus" /> Add New Client
                    </Link>
                  ) : null}
                </h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Inactive Clients</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 ms-3"></div>
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) => getusersData("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ViewClients;
